<template>
	<div class="p-inputgroup">
		<p-calendar
			v-model="localValue"
			selection-mode="range"
			:date-format="dateFormat"
			:manual-input="true"
			:show-time="showTime"
			:min-date="minDate"
			:max-date="maxDate"
			show-seconds
			@input="inputHandler"
			@keypress="keypressHandler"
			@blur="blurHandler"
			@show="setTimeTo(time, 'start')"
			@hide="updateTimeValues"
			panel-class="daterangepicker"
		>
			<template v-if="showTime" #footer>
				<div class="p-datepicker-footer">
					<div class="timepicker start">
						<div v-tooltip.top="'Start Time'">
							<icon type="clock-start" size="24px" />
						</div>
						<gutter size="20px" />
						<div class="time-segment hours">
							<p-text
								v-model="time.start.hours"
								class="p-inputtext-sm"
								@keydown="updateTimeValue($event, time.start, 'hours', 23)"
								@input="limitTimeLength($event, time.start, 'hours')"
								@blur="sanitizeValue($event, time.start, 'hours', 23)"
							/>
						</div>
						<div class="separator">:</div>
						<div class="time-segment minutes">
							<p-text
								v-model="time.start.minutes"
								class="p-inputtext-sm"
								@keydown="updateTimeValue($event, time.start, 'minutes')"
								@input="limitTimeLength($event, time.start, 'minutes')"
								@blur="sanitizeValue($event, time.start, 'minutes')"
							/>
						</div>
						<div class="separator">:</div>
						<div class="time-segment seconds">
							<p-text
								v-model="time.start.seconds"
								class="p-inputtext-sm"
								@keydown="updateTimeValue($event, time.start, 'seconds')"
								@input="limitTimeLength($event, time.start, 'seconds')"
								@blur="sanitizeValue($event, time.start, 'seconds')"
							/>
						</div>
						<gutter size="20px" />
						<div class="p-buttonset">
							<p-button
								v-tooltip.top="'Start of Day'"
								icon="pi pi-step-backward"
								@click="setTimeTo(time.start, 'start')"
							/>
							<p-button v-tooltip.top="'Current Time'" icon="pi pi-stopwatch" @click="setTimeTo(time.start, 'now')" />
							<p-button v-tooltip.top="'End of Day'" icon="pi pi-step-forward" @click="setTimeTo(time.start, 'end')" />
						</div>
					</div>
					<div class="timepicker end">
						<div v-tooltip.top="'End Time'">
							<icon type="clock-end" size="24px" />
						</div>
						<gutter size="20px" />
						<div class="time-segment hours">
							<p-text
								v-model="time.end.hours"
								class="p-inputtext-sm"
								@keydown="updateTimeValue($event, time.end, 'hours', 23)"
								@input="limitTimeLength($event, time.end, 'hours')"
								@blur="sanitizeValue($event, time.end, 'hours', 23)"
							/>
						</div>
						<div class="separator">:</div>
						<div class="time-segment minutes">
							<p-text
								v-model="time.end.minutes"
								class="p-inputtext-sm"
								@keydown="updateTimeValue($event, time.end, 'minutes')"
								@input="limitTimeLength($event, time.end, 'minutes')"
								@blur="sanitizeValue($event, time.end, 'minutes')"
							/>
						</div>
						<div class="separator">:</div>
						<div class="time-segment seconds">
							<p-text
								v-model="time.end.seconds"
								class="p-inputtext-sm"
								@keydown="updateTimeValue($event, time.end, 'seconds')"
								@input="limitTimeLength($event, time.end, 'seconds')"
								@blur="sanitizeValue($event, time.end, 'seconds')"
							/>
						</div>
						<gutter size="20px" />
						<div class="p-buttonset">
							<p-button
								v-tooltip.top="'Start of Day'"
								icon="pi pi-step-backward"
								@click="setTimeTo(time.end, 'start')"
							/>
							<p-button v-tooltip.top="'Current Time'" icon="pi pi-stopwatch" @click="setTimeTo(time.end, 'now')" />
							<p-button v-tooltip.top="'End of Day'" icon="pi pi-step-forward" @click="setTimeTo(time.end, 'end')" />
						</div>
					</div>
				</div>
			</template>
		</p-calendar>
		<div class="p-inputgroup-addon" @click="toggleQuickDates">
			<alt-icon type="edit-calendar" size="20px" />
		</div>
		<p-overlay-panel ref="quick_dates">
			<div class="quick-date-options">
				<div
					v-for="option in dateOptions"
					:class="['quick-date-option', { active: isEqual(modelValue, option.value) }]"
					@click="setDate(option.value)"
				>
					<div class="flex align-items-center gap-10">
						<alt-icon :type="option.icon" size="18px" />
						<div>{{ option.label }}</div>
					</div>
				</div>
			</div>
		</p-overlay-panel>
	</div>
</template>

<script lang="ts">
import { isEqual, padStart } from 'lodash-es';
import pCalendar from 'primevue/calendar';
import pText from 'primevue/inputtext';
import pOverlayPanel from 'primevue/overlaypanel';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

export default {
	name: 'DateRangePicker',
	components: {
		pCalendar,
		pOverlayPanel,
		pText,
	},
	props: {
		label: {
			type: String,
			default: 'Form Field',
		},
		modelValue: {
			type: [Array, null],
			required: true,
		},
		dateFormat: {
			type: String,
			default: 'D, M d, yy',
		},
		showTime: Boolean,
		maxRange: Number,
		minDate: Date,
		maxDate: Date,
		dateOptions: {
			type: Array,
			default() {
				return [
					{
						icon: 'calendar-today',
						label: 'Today',
						value: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
					},
					{
						icon: 'calendar-today',
						label: 'Yesterday',
						value: [
							dayjs().subtract(1, 'day').startOf('day').toDate(),
							dayjs().subtract(1, 'day').endOf('day').toDate(),
						],
					},
					{
						icon: 'calendar-today',
						label: 'This Week',
						value: [dayjs().startOf('isoWeek').toDate(), dayjs().endOf('isoWeek').toDate()],
					},
					{
						icon: 'calendar-today',
						label: 'Last Week',
						value: [
							dayjs().subtract(1, 'week').startOf('isoWeek').toDate(),
							dayjs().subtract(1, 'week').endOf('isoWeek').toDate(),
						],
					},
					{
						icon: 'calendar-today',
						label: 'This Month',
						value: [dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()],
					},
					{
						icon: 'calendar-today',
						label: 'Last Month',
						value: [
							dayjs().subtract(1, 'month').startOf('month').toDate(),
							dayjs().subtract(1, 'month').endOf('month').toDate(),
						],
					},
					{
						icon: 'restart-alt',
						label: 'Reset',
						value: 'RESET',
					},
				];
			},
		},
		defaultTime: {
			type: Object,
			default() {
				return {
					start: {
						hours: '00',
						minutes: '00',
						seconds: '00',
					},
					end: {
						hours: '23',
						minutes: '59',
						seconds: '59',
					},
				};
			},
		},
	},
	data() {
		return {
			time: {
				start: {
					hours: this.defaultTime.start.hours,
					minutes: this.defaultTime.start.minutes,
					seconds: this.defaultTime.start.seconds,
				},
				end: {
					hours: this.defaultTime.end.hours,
					minutes: this.defaultTime.end.minutes,
					seconds: this.defaultTime.end.seconds,
				},
			},
			default_value: this.modelValue,
			input_value: null,
		};
	},
	computed: {
		localValue: {
			get() {
				let parsed_value = this.modelValue || [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()];

				parsed_value.map((value, index) => {
					let new_value = value;
					if (value && !(value instanceof Date)) {
						new_value = dayjs(value).toDate();
					}

					if (this.showTime) {
						if (index === 0) {
							this.time.start.hours = padStart(dayjs(value).hour().toString(), 2, '0');
							this.time.start.minutes = padStart(dayjs(value).minute().toString(), 2, '0');
							this.time.start.seconds = padStart(dayjs(value).second().toString(), 2, '0');
						} else {
							if (value) {
								this.time.end.hours = padStart(dayjs(value).hour().toString(), 2, '0');
								this.time.end.minutes = padStart(dayjs(value).minute().toString(), 2, '0');
								this.time.end.seconds = padStart(dayjs(value).second().toString(), 2, '0');
							}
						}
					}

					return new_value;
				});

				return parsed_value;
			},
			set(new_value) {
				try {
					if (JSON.stringify(new_value) !== JSON.stringify(this.modelValue)) {
						let start_date;
						let mapped_new_value = new_value.map((value, index) => {
							if (value instanceof Date) {
								if (index === 0) {
									start_date = dayjs(value)
										.hour(+this.time.start.hours)
										.minute(+this.time.start.minutes)
										.second(+this.time.start.seconds)
										.toDate();
									return start_date;
								}

								return dayjs(value)
									.hour(+this.time.end.hours)
									.minute(+this.time.end.minutes)
									.second(+this.time.end.seconds)
									.toDate();
							}
						});

						// Adjust the dates if they are out of order
						if (mapped_new_value[1] && dayjs(mapped_new_value[1]).isBefore(mapped_new_value[0])) {
							mapped_new_value.reverse();
						}

						// Adjust the start date for limited ranges
						if (
							mapped_new_value[1] &&
							this.maxRange &&
							dayjs(mapped_new_value[1]).diff(mapped_new_value[0], 'days', true) > this.maxRange
						) {
							mapped_new_value[0] = dayjs(mapped_new_value[1])
								.subtract(this.maxRange - 1, 'days')
								.hour(+this.time.start.hours)
								.minute(+this.time.start.minutes)
								.second(+this.time.start.seconds)
								.toDate();

							this.$toast.add({
								severity: 'warn',
								summary: 'Date Range Adjusted to Max Limit',
								detail: `There is a maximum range limit of ${this.maxRange} day(s) for this query`,
								life: 8000,
							});
						}

						this.$emit('update:modelValue', mapped_new_value);
					}
				} catch (err) {
					// DO NOTHING
				}
			},
		},
	},
	methods: {
		isEqual,
		inputHandler(event) {
			if (this.dateFormat !== 'yy-mm-dd') {
				this.input_value = event.target.value;
			}
		},
		keypressHandler(event) {
			if (event.key === 'Enter') {
				this.blurHandler();
			}
		},
		blurHandler() {
			if (this.dateFormat !== 'yy-mm-dd' && this.input_value) {
				let parsed_value = this.input_value.split(' - ');
				try {
					parsed_value = parsed_value.map((value) => {
						return dayjs(value).toDate();
					});
					this.input_value = null;
					this.$emit('update:modelValue', parsed_value);
				} catch (err) {
					// DO NOTHING
				}
			}
		},
		updateTimeValue(event, value, segment, max = 59) {
			let number_pattern = new RegExp('[0-9]|Tab|Backspace|ArrowLeft|ArrowRight|Delete');
			if (!number_pattern.test(event.key) && !event.metaKey && !event.ctrlKey) {
				event.preventDefault();
			}

			let new_value = parseInt(value[segment]);
			if (event.key === 'ArrowUp') {
				if (event.shiftKey) {
					new_value += 10;
				} else {
					new_value++;
				}
				if (new_value > max) new_value = 0;
				value[segment] = padStart(new_value.toString(), 2, '0');
			}
			if (event.key === 'ArrowDown') {
				if (event.shiftKey) {
					new_value -= 10;
				} else {
					new_value--;
				}
				if (new_value < 0) new_value = max;
				value[segment] = padStart(new_value.toString(), 2, '0');
			}
		},
		limitLength(event, value, segment) {
			if (event.target.value.length > 2) {
				value[segment] = event.target.value.slice(-2);
			}
		},
		sanitizeValue(event, value, segment, max = 59) {
			if (value[segment] > max) {
				value[segment] = max;
			}
		},
		setTimeTo(value, when) {
			if (when === 'start') {
				value.hours = '00';
				value.minutes = '00';
				value.seconds = '00';
			}
			if (when === 'now') {
				let now = new Date();
				value.hours = padStart(now.getHours().toString(), 2, '0');
				value.minutes = padStart(now.getMinutes().toString(), 2, '0');
				value.seconds = padStart(now.getSeconds().toString(), 2, '0');
			}
			if (when === 'end') {
				value.hours = '23';
				value.minutes = '59';
				value.seconds = '59';
			}
		},
		updateTimeValues() {
			if (this.localValue[0] && !this.localValue[1]) {
				this.localValue[1] = dayjs(this.localValue[0])
					.hour(+this.time.end.hours)
					.minute(+this.time.end.minutes)
					.second(+this.time.end.seconds)
					.toDate();
			}

			if (this.localValue && this.showTime) {
				const new_value = this.localValue.map((value, index) => {
					if (index === 0) {
						return dayjs(value)
							.hour(+this.time.start.hours)
							.minute(+this.time.start.minutes)
							.second(+this.time.start.seconds)
							.toDate();
					} else {
						return dayjs(value)
							.hour(+this.time.end.hours)
							.minute(+this.time.end.minutes)
							.second(+this.time.end.seconds)
							.toDate();
					}
				});

				this.$emit('update:modelValue', new_value);
			}
		},
		toggleQuickDates(event) {
			this.$refs.quick_dates.toggle(event);
		},
		setDate(value) {
			let new_value = value;
			if (value === 'RESET') {
				new_value = this.default_value;
			}
			this.$emit('update:modelValue', new_value);
		},
	},
};
</script>

<style lang="less">
.p-datepicker.daterangepicker {
	min-width: 400px;
}
</style>

<style scoped lang="less">
.p-inputwrapper.p-calendar {
	height: auto;
	max-width: 400px;
}

.p-datepicker {
	> .p-timepicker {
		display: none;
	}

	.p-datepicker-footer {
		padding: 20px;
		text-align: center;
	}

	.timepicker {
		align-items: center;
		display: flex;

		&.start {
			margin-bottom: 1em;
		}

		> .time-segment {
			max-width: 50px;

			.p-inputtext {
				max-width: 100%;
				text-align: center;
			}
		}

		> .separator {
			text-align: center;
			width: 10px;
		}
	}
}

.p-inputgroup-addon {
	background-color: var(--color-b);
	border-color: var(--color-b);
	color: white;
	cursor: default;
}

.quick-date-option {
	border-top: 1px solid var(--gray-10);
	cursor: default;
	font-size: 0.875em;
	height: 2.5em;
	line-height: 2.5em;
	min-width: 150px;
	padding: 0 0.5em;

	.material-symbols-outlined {
		color: var(--color-b);
	}

	&.active {
		font-weight: bold;
	}

	&:first-child {
		border-top: 0;
	}

	&:hover {
		background-color: var(--color-b-lightest);
	}
}
</style>
