<template>
	<div class="build-step reply-with-bids">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Reply with Bids</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">
			Return the bids that were collected while auctioning the lead. This step MUST be preceded by the "Send To
			Auctions" step.
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Bid Multiplier:</label>
					<div class="controls gap-20">
						<div class="field xs">
							<div class="p-inputgroup">
								<p-input-number v-model="modelValue.bid_multiplier" :minFractionDigits="0" :maxFractionDigits="3" />
								<div class="p-inputgroup-addon">x</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label"
						>Max number of bids to return <span class="sub-value">(Use 0 for no limit)</span>:</label
					>
					<div class="controls gap-20">
						<div class="field xs">
							<div class="p-inputgroup">
								<p-input-number v-model="modelValue.max_bids" />
								<div class="p-inputgroup-addon">bids</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Hide Fields in Response:</label>
					<div class="controls">
						<div class="field">
							<p-chips v-model="modelValue.hide_fields" add-on-blur />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ReplyWithBids',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default: {
				bid_multiplier: 1,
				max_bids: 0,
				hide_fields: [],
			},
		},
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
