<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Performance Dashboard</h1>
			<breadcrumb :model="breadcrumbs" />
		</div>
		<query-form v-model:query="query" :loading="loading" @apply="getPerformanceVelocity" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}</div>
				</div>
				<div class="display-value">
					<div class="label">Compare To:</div>
					<div class="value">{{ displayLabel(query.compare, compare_options) }}</div>
				</div>
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<date-range v-model="form.query.date_range" hide-labels show-time />
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="250px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Compare To:</label>
								<div class="controls">
									<div class="field">
										<p-dropdown
											v-model="query.compare"
											:options="compare_options"
											option-label="label"
											option-value="value"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column></column>
				</row>
			</template>
		</query-form>
		<div class="card padded">
			<h2>Performance</h2>
			<p-data-table :value="revenue_velocity">
				<template #empty>
					<div class="dim">No data to display</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<p-column-group type="header">
					<p-row>
						<p-column header="Product" :rowspan="2" />
						<p-column header="Total" :colspan="3" class="left-bordered" />
						<p-column header="Health Insurance" :colspan="3" class="left-bordered" />
						<p-column header="Medicare" :colspan="3" class="left-bordered" />
					</p-row>
					<p-row>
						<p-column header="Volume" class="left-bordered" />
						<p-column header="Revenue" />
						<p-column header="RPL" />
						<p-column header="Volume" class="left-bordered" />
						<p-column header="Revenue" />
						<p-column header="RPL" />
						<p-column header="Volume" class="left-bordered" />
						<p-column header="Revenue" />
						<p-column header="RPL" />
					</p-row>
				</p-column-group>
				<p-column field="product" header="Product">
					<template #body="row">
						<div class="product-label">
							<strong>{{ row.data.label }}</strong>
						</div>
					</template>
				</p-column>

				<!-- TOTAL -->
				<p-column class="left-bordered">
					<template #body="row">
						<div class="velocity-stats">
							<div class="value">
								<div class="current">{{ row.data.total_volume }}</div>
								<div class="velocity"><icon type="calendar-arrow-left" />{{ row.data.total_volume_velocity }}</div>
							</div>
							<div
								:class="[
									'delta',
									{ up: row.data.total_volume_delta > 0.05, down: row.data.total_volume_delta < -0.05 },
								]"
							>
								<div class="percentage">
									{{ percentage(row.data.total_volume_delta || 0, 2) }}
								</div>
							</div>
						</div>
					</template>
				</p-column>
				<p-column>
					<template #body="row">
						<div class="velocity-stats">
							<div class="value">
								<div class="current">{{ currency(row.data.total_revenue) }}</div>
								<div class="velocity">
									<icon type="calendar-arrow-left" />{{ currency(row.data.total_revenue_velocity) }}
								</div>
							</div>
							<div
								:class="[
									'delta',
									{ up: row.data.total_revenue_delta > 0.05, down: row.data.total_revenue_delta < -0.05 },
								]"
							>
								<div class="percentage">
									{{ percentage(row.data.total_revenue_delta || 0, 2) }}
								</div>
							</div>
						</div>
					</template>
				</p-column>
				<p-column>
					<template #body="row">
						<div class="velocity-stats">
							<div class="value">
								<div class="current">{{ currency(row.data.total_rpl) }}</div>
								<div class="velocity">
									<icon type="calendar-arrow-left" />{{ currency(row.data.total_rpl_velocity) }}
								</div>
							</div>
							<div :class="['delta', { up: row.data.total_rpl_delta > 0.05, down: row.data.total_rpl_delta < -0.05 }]">
								<div class="percentage">
									{{ percentage(row.data.total_rpl_delta || 0, 2) }}
								</div>
							</div>
						</div>
					</template>
				</p-column>
				<!-- END TOTAL -->

				<!-- HEALTH INSURANCE -->
				<p-column class="left-bordered">
					<template #body="row">
						<div class="velocity-stats">
							<div class="value">
								<div class="current">{{ row.data.health_insurance_volume }}</div>
								<div class="velocity">
									<icon type="calendar-arrow-left" />{{ row.data.health_insurance_volume_velocity }}
								</div>
							</div>
							<div
								:class="[
									'delta',
									{
										up: row.data.health_insurance_volume_delta > 0.05,
										down: row.data.health_insurance_volume_delta < -0.05,
									},
								]"
							>
								<div class="percentage">
									{{ percentage(row.data.health_insurance_volume_delta || 0, 2) }}
								</div>
							</div>
						</div>
					</template>
				</p-column>
				<p-column>
					<template #body="row">
						<div class="velocity-stats">
							<div class="value">
								<div class="current">{{ currency(row.data.health_insurance_revenue) }}</div>
								<div class="velocity">
									<icon type="calendar-arrow-left" />{{ currency(row.data.health_insurance_revenue_velocity) }}
								</div>
							</div>
							<div
								:class="[
									'delta',
									{
										up: row.data.health_insurance_revenue_delta > 0.05,
										down: row.data.health_insurance_revenue_delta < -0.05,
									},
								]"
							>
								<div class="percentage">
									{{ percentage(row.data.health_insurance_revenue_delta || 0, 2) }}
								</div>
							</div>
						</div>
					</template>
				</p-column>
				<p-column>
					<template #body="row">
						<div class="velocity-stats">
							<div class="value">
								<div class="current">{{ currency(row.data.health_insurance_rpl) }}</div>
								<div class="velocity">
									<icon type="calendar-arrow-left" />{{ currency(row.data.health_insurance_rpl_velocity) }}
								</div>
							</div>
							<div
								:class="[
									'delta',
									{ up: row.data.health_insurance_rpl_delta > 0.05, down: row.data.health_insurance_rpl_delta < -0.05 },
								]"
							>
								<div class="percentage">
									{{ percentage(row.data.health_insurance_rpl_delta || 0, 2) }}
								</div>
							</div>
						</div>
					</template>
				</p-column>
				<!-- END HEALTH INSURANCE -->

				<!-- MEDICARE -->
				<p-column class="left-bordered">
					<template #body="row">
						<div class="velocity-stats">
							<div class="value">
								<div class="current">{{ row.data.medicare_volume }}</div>
								<div class="velocity"><icon type="calendar-arrow-left" />{{ row.data.medicare_volume_velocity }}</div>
							</div>
							<div
								:class="[
									'delta',
									{ up: row.data.medicare_volume_delta > 0.05, down: row.data.medicare_volume_delta < -0.05 },
								]"
							>
								<div class="percentage">
									{{ percentage(row.data.medicare_volume_delta || 0, 2) }}
								</div>
							</div>
						</div>
					</template>
				</p-column>
				<p-column>
					<template #body="row">
						<div class="velocity-stats">
							<div class="value">
								<div class="current">{{ currency(row.data.medicare_revenue) }}</div>
								<div class="velocity">
									<icon type="calendar-arrow-left" />{{ currency(row.data.medicare_revenue_velocity) }}
								</div>
							</div>
							<div
								:class="[
									'delta',
									{ up: row.data.medicare_revenue_delta > 0.05, down: row.data.medicare_revenue_delta < -0.05 },
								]"
							>
								<div class="percentage">
									{{ percentage(row.data.medicare_revenue_delta || 0, 2) }}
								</div>
							</div>
						</div>
					</template>
				</p-column>
				<p-column>
					<template #body="row">
						<div class="velocity-stats">
							<div class="value">
								<div class="current">{{ currency(row.data.medicare_rpl) }}</div>
								<div class="velocity">
									<icon type="calendar-arrow-left" />{{ currency(row.data.medicare_rpl_velocity) }}
								</div>
							</div>
							<div
								:class="[
									'delta',
									{ up: row.data.medicare_rpl_delta > 0.05, down: row.data.medicare_rpl_delta < -0.05 },
								]"
							>
								<div class="percentage">
									{{ percentage(row.data.medicare_rpl_delta || 0, 2) }}
								</div>
							</div>
						</div>
					</template>
				</p-column>
				<!-- END MEDICARE -->
			</p-data-table>
		</div>
	</div>
</template>

<script>
import { meanBy, merge, sumBy } from 'lodash-es';
import { endOfDay, startOfDay } from 'date-fns';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import pColumnGroup from 'primevue/columngroup';
import { currency, formatDate, displayLabel, percentage } from '@/lib/Filters';
import { getHours } from 'date-fns';
import { reportPerformanceVelocity } from '@GQL';

export default {
	name: 'PerformanceDashboard',
	components: {
		dateRangePicker,
		queryForm,
		pColumnGroup,
	},
	data() {
		return {
			breadcrumbs: [
				{
					label: 'Performance Dashboard',
				},
			],
			loading: false,
			query: {
				date_range: [startOfDay(new Date()), endOfDay(new Date())],
				compare: ['week', 1],
			},
			compare_options: [
				{
					label: 'Previous Quarter',
					value: ['week', 12],
				},
				{
					label: 'Previous Month',
					value: ['week', 4],
				},
				{
					label: 'Previous Week',
					value: ['week', 1],
				},
				{
					label: 'Previous Day',
					value: ['day', 1],
				},
			],
			revenue_velocity: [],
			vertical_options: [
				{
					label: 'All Verticals',
					value: 'all',
				},
				{
					label: 'Health Insurance',
					value: 'health_insurance',
				},
				{
					label: 'Medicare',
					value: 'medicare',
				},
			],
		};
	},
	methods: {
		currency,
		percentage,
		formatDate,
		displayLabel,
		clearQuery() {
			this.loading = false;
		},
		async getPerformanceVelocity() {
			const revenue_velocity_results = await reportPerformanceVelocity(this.query);
			this.revenue_velocity = [
				merge(revenue_velocity_results.all, { label: 'Total' }),
				merge(revenue_velocity_results.data, { label: 'Data Leads' }),
				merge(revenue_velocity_results.call, { label: 'Calls' }),
				merge(revenue_velocity_results.live_transfer, { label: 'Live Transfers' }),
				merge(revenue_velocity_results.ad, { label: 'Ads' }),
			];
		},
	},
	mounted() {
		this.getPerformanceVelocity();
	},
};
</script>

<style scoped lang="less">
:deep(.p-datatable) {
	thead {
		tr {
			border-bottom: 1px solid var(--gray-15);

			th.left-bordered {
				border-left: 1px solid var(--gray-15);
			}
		}
	}

	.left-bordered {
		border-left: 1px dashed var(--gray-15);
	}
}

.card h2 {
	font-size: 1.25rem;
}

.chart-heading {
	gap: 20px;

	h2 {
		margin: 0;
	}
}

.product-label {
	font-size: 1.125rem;
}

.velocity-stats {
	align-items: flex-start;
	color: var(--gray-80);

	.value {
		text-align: right;

		.current {
			font-size: 1.25rem;
			font-weight: bold;
		}

		.velocity {
			align-items: center;
			color: var(--gray-35);
			display: inline-flex;
			margin-top: 5px;

			.mdi-icon {
				margin-right: 5px;
			}
		}
	}

	.delta {
		background-color: var(--gray-25);
		border-radius: 3px;
		padding: 4px 5px;
		text-align: right;

		&.up {
			background-color: var(--green);
		}

		&.down {
			background-color: var(--red);
		}

		.percentage {
			color: white;
			font-size: 0.75rem;
			line-height: 1em;
		}
	}
}
</style>
