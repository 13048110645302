<template>
	<div id="data-leads-report" class="view-content">
		<conversions-by-sub-id-modal ref="sub_id_modal" />
		<div class="page-heading">
			<h1>Conversions by Source</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div id="conversions">
			<query-form v-model:query="query" :loading="loading" @apply="getReport">
				<template #display>
					<div class="display-value">
						<div class="label">Purchase Dates:</div>
						<div class="value">
							{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Vertical:</div>
						<div class="value">
							{{ displaySelected(query.filters.vertical_id, appStore.verticalOptions) }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Product:</div>
						<div class="value">
							{{ displaySelected(query.filters.product, product_type_options) }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button v-tooltip.top="'Refresh'" icon="pi pi-refresh" aria-label="Refresh" class="mr-2" />
				</template>
				<template #form="form">
					<row>
						<column>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field">
											<date-range v-model="form.query.date_range" hide-labels show-time />
										</div>
									</div>
								</div>
							</div>

							<div class="control-group collapse">
								<div class="inner">
									<label class="control-label">Filter By Vertical:</label>
									<div class="controls">
										<div class="field">
											<div
												v-for="vertical of appStore.verticalOptions"
												:key="vertical.value"
												class="p-checkbox-item flex align-items-center"
											>
												<p-checkbox
													v-model="form.query.filters.vertical_id"
													:input-id="vertical.value"
													name="category"
													:value="vertical.value"
												/>
												<label :for="vertical.value">{{ vertical.label }}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</column>
						<gutter size="40px" />
						<column>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Filter By Product:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												id="product-type"
												v-model="form.query.filters.product"
												option-label="label"
												option-value="value"
												:options="product_type_options"
												placeholder="Select product type"
											/>
										</div>
									</div>
								</div>
							</div>
						</column>
						<gutter size="40px" />
						<column> </column>
					</row>
				</template>
			</query-form>
			<gutter size="20px" />
			<div class="card padded">
				<p-data-table ref="dt" :loading="loading" :rows="100" scrollable :value="rows">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column header="Source" sortable sortField="source_id">
						<template #body="row">
							<span @click="openSubIdModal(row.data)" class="clickable" sortable sortField="source_id">
								{{ row.data.source_id }} <icon type="open-in-new" />
							</span>
						</template>
					</p-column>
					<p-column header="Spend" class="column-align-center" sortable sortField="total_spend">
						<template #body="row">
							{{ currency(row.data.total_spend) }}
						</template>
					</p-column>
					<p-column header="Count" class="column-align-center" sortable sortField="total_purchase_count">
						<template #body="row">
							{{ row.data.total_purchase_count }}
						</template>
					</p-column>
					<p-column header="CPA" class="column-align-center" sortable sortField="CPA">
						<template #body="row">
							{{ currency(row.data.CPA) }}
						</template>
					</p-column>
					<p-column header="Sold" class="column-align-right" sortable sortField="Sold">
						<template #body="row">
							{{ row.data.Sold }}
						</template>
					</p-column>
					<p-column header="Returned" class="column-align-right" sortable sortField="Returned">
						<template #body="row">
							{{ row.data.Returned }}
						</template>
					</p-column>
					<p-column header="Contacted" class="column-align-right" sortable sortField="Contacted">
						<template #body="row">
							{{ row.data.Contacted }}
						</template>
					</p-column>
					<p-column header="New" class="column-align-right" sortable sortField="New">
						<template #body="row">
							{{ row.data.New }}
						</template>
					</p-column>
				</p-data-table>
				<!--        <p-paginator :rows="query.pagination.page_size" :total-records="row_count" @page="handlePageChange" />-->
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { useAppStore } from '@/stores/app';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import typeIcon from '@/components/widgets/TypeIcon.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import { endOfDay, startOfDay, parseISO } from 'date-fns';
import { formatDate, displaySelected, currency, verticalName, phoneFormat, capitalize } from '@/lib/Filters';
import { product_type_options } from '@/lib/Options';
import { reportSourceConversions } from '@GQL';
import { cloneDeep } from 'lodash-es';

import ConversionsBySubIdModal from '@/views/Reports/SharedModals/ConversionsBySubIdModal.vue';

export default {
	name: 'MarketplaceConversionsBySource',
	components: {
		dateRangePicker,
		queryForm,
		quickFilter,
		typeIcon,
		verticalIcon,
		ConversionsBySubIdModal,
	},
	data() {
		return {
			loading: false,
			breadcrumb_items: [
				{
					label: 'Reports',
					route: '/reports',
				},
				{
					label: 'Conversions Sources',
					route: '/reports/conversions-source',
				},
			],
			query: {
				date_range: [startOfDay(new Date()), endOfDay(new Date())],
				mpid: this.mpid,
				filters: {
					vertical_id: [],
					product: [],
				},
			},
			rows: [],
			product_type_options,
		};
	},
	computed: {
		mpid() {
			if (this.$route.params && this.$route.params.mpid) {
				return this.$route.params.mpid;
			} else {
				return this.appStore.mpid;
			}
		},
		...mapStores(useAppStore, useMarketplaceStore, useSessionStore),
	},
	async mounted() {
		await this.getReport();
	},
	methods: {
		formatDate,
		currency,
		displaySelected,
		openSubIdModal(row) {
			const transformed_query = this.transformQuery(this.query);
			this.$refs.sub_id_modal.openModal({
				source_id: row.source_id,
				mpid: this.mpid,
				query: transformed_query,
				grouped_row: row,
			});
		},
		transformQuery(query) {
			const transformed_query = cloneDeep(query);
			transformed_query.mpid = this.mpid;
			transformed_query.filters = [[]];

			if (query.filters.vertical_id.length > 0) {
				transformed_query.filters[0].push({
					op: 'in',
					value: query.filters.vertical_id,
					field: 'vertical_id',
				});
			}

			if (query.filters.product.length > 0) {
				transformed_query.filters[0].push({
					op: 'in',
					value: query.filters.product,
					field: 'product',
				});
			}

			if (transformed_query.filters[0].length === 0) {
				delete transformed_query.filters;
			}
			return transformed_query;
		},
		async getReport() {
			// construct the query object to change filters
			try {
				const transformed_query = this.transformQuery(this.query);
				const results = await reportSourceConversions(transformed_query);
				this.rows = results.rows;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get data',
				});
			}
		},
	},
};
</script>
<style lang="less" scoped>
.clickable {
	cursor: pointer;
	font-size: var(--font-size-base);
	margin-bottom: 0;
}
</style>
