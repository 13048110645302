<template>
	<iframe v-if="report_url" ref="metabase" id="reporting-embed" :src="report_url" frameborder="0"></iframe>
</template>

<script lang="ts">
export default {
	name: 'ReportingWrapper',
	data() {
		return {
			report_url: null,
		};
	},
	mounted() {
		this.report_url = 'https://reporting.nextgenleads.app?new_button=true&search=true';
		if (this.$route.query.report) {
			this.report_url = decodeURIComponent(this.$route.query.report);
			if (this.report_url.indexOf('?') > -1) {
				this.report_url += '&new_button=true&search=true';
			} else {
				this.report_url += '?new_button=true&search=true';
			}
		}
		window.addEventListener('message', this.updateUrl);
	},
	beforeUnmount() {
		window.removeEventListener('message', this.updateUrl);
	},
	methods: {
		updateUrl(event) {
			if (event.data.metabase && event.data.metabase.type === 'location') {
				let report = encodeURIComponent(event.data.metabase.location.href);

				if (event.data.metabase.location.pathname !== '/') {
					this.$router.replace({
						query: {
							report,
						},
					});
				} else {
					this.$router.replace({
						query: null,
					});
				}
			}
		},
	},
};
</script>

<style lang="less" scoped>
#reporting-embed {
	height: 100%;
}
</style>
