<template>
	<div id="universal-search">
		<!-- Toolbar Search Input -->
		<div v-if="!mq.phone" class="search-input">
			<div class="p-input-icon-left">
				<div class="icon-wrapper">
					<icon type="magnify" size="24px" />
				</div>
				<p-text
					v-model="search_query"
					class="rounded p-inputtext-sm"
					placeholder="Search Everything"
					@keyup.enter="handleSearchModal"
					@focus="outer_search_focus = true"
					@blur="outer_search_focus = false"
				/>
				<div v-if="search_query && outer_search_focus" class="return-icon-right">
					<icon type="keyboard-return" size="16px" />
				</div>
			</div>
		</div>
		<div v-else class="search-btn">
			<a @click.prevent="handleSearchModal">
				<alt-icon type="search" size="24px" />
			</a>
		</div>

		<!-- Search Modal -->
		<p-dialog
			id="search-modal"
			v-model:visible="search_modal_open"
			:modal="true"
			:dismissable-mask="true"
			@after-hide="resetSearch"
			:pt="{
				root: { class: 'w-12 sm:w-9 md:w-6 xl:w-5 max-w-30rem' },
				content: { class: 'pt-2 pb-2 pl-3 pr-3' },
			}"
		>
			<template #header>
				<div class="search-input">
					<div class="p-input-icon-left">
						<div class="icon-wrapper">
							<icon type="magnify" size="24px" />
						</div>
						<p-text
							v-model="search_query"
							class="rounded p-inputtext-sm"
							placeholder="Enter Search Term"
							autofocus
							@keyup.enter="handleInnerSearch"
							@focus="inner_search_focus = true"
							@blur="inner_search_focus = false"
							@keyup.down="updateSelected($event)"
							@keyup.up="updateSelected($event)"
						/>
						<div v-if="search_query && inner_search_focus && !hasSelection" class="return-icon-right">
							<icon type="keyboard-return" size="16px" aria-describedby="return-key-icon" />
						</div>
					</div>
				</div>
				<div v-if="!mq.phone" class="clear-search">
					<p-button size="small" text @click="resetSearch">Clear</p-button>
				</div>
			</template>
			<!-- Spinner -->
			<div v-if="search_loading" class="search-loading">
				<p-ProgressSpinner />
			</div>
			<!-- Search Results -->
			<div class="results">
				<div
					v-if="!$root.sessionStore.isAccountUser && search_results.accounts.results.length > 0"
					class="result-category"
				>
					<h2>
						{{ search_results.accounts.category }}
						<span class="count">{{ search_results.accounts.results.length }}</span>
					</h2>
					<ul>
						<li
							v-for="(result, resultIndex) in search_results.accounts.results.slice(
								0,
								search_results.accounts.shown_count
							)"
							:key="resultIndex"
						>
							<router-link
								:to="linkHelper(result)"
								@click="handleLinkClick($event)"
								:class="{ 'text-red-500': ['suspended', 'archived'].includes(result.status) }"
							>
								<div class="result">
									<div class="flex align-items-center">
										<icon class="mr-1" type="briefcase" size="20px" />
										<span class="name">{{ result.account_name }} - ({{ result.id }})</span>
										<p-tag
											v-if="result.status === 'suspended'"
											severity="danger"
											class="ml-1"
											v-tooltip.top="'SUSPENDED'"
											value="SUSPENDED"
											style="font-size: 0.5rem"
										/>
										<p-tag
											v-if="result.status === 'archived'"
											severity="danger"
											class="ml-1"
											v-tooltip.top="'ARCHIVED'"
											value="ARCHIVED"
											style="font-size: 0.5rem"
										/>
									</div>
									<span class="details"
										>{{ result.user_name }} - {{ result.account_email }} <br />
										{{ phoneFormat(result.user_phone) }}</span
									>
								</div>
							</router-link>
						</li>
					</ul>
					<div
						v-if="
							search_results.accounts.results.length > 3 &&
							search_results.accounts.shown_count !== search_results.accounts.results.length
						"
						class="see-all-results"
						@click="expandShown('accounts')"
					>
						See All Account Results
						<icon type="chevron-right" size="16px" />
					</div>
				</div>
				<div v-if="search_results.leads.results.length > 0" class="result-category">
					<h2>
						{{ search_results.leads.category }}
						<span class="count">{{ search_results.leads.results.length }}</span>
					</h2>
					<ul>
						<li
							v-for="(result, resultIndex) in search_results.leads.results.slice(0, search_results.leads.shown_count)"
							:key="resultIndex"
						>
							<router-link
								:to="linkHelper(result)"
								@click="handleLinkClick($event)"
								:class="{ 'text-red-500': result.dnc === true }"
							>
								<div class="result">
									<div class="flex align-items-center">
										<icon class="mr-1" type="account" size="20px" />
										<span class="name">{{ result.lead_name }} ({{ result.lead_id }})</span>
										<p-tag
											v-if="result.dnc"
											severity="danger"
											class="ml-1"
											v-tooltip.top="'Do Not Contact'"
											value="DNC"
											style="font-size: 0.5rem"
										/>
									</div>
									<span class="details">
										<div v-if="$root.sessionStore.isAdminUser && result.vendor_lead_id" class="block">
											{{ result.vendor_lead_id }} (vendor_id)
										</div>
										{{ formatDate(result.created_at, 'MMM D, YYYY [at] h:mm:ssA') }}
										- <span class="sub-value">{{ timeAgo(result.created_at) }}</span> <br />
										{{ result.vertical_id }} - {{ result.contact_email }} <br />
										{{ phoneFormat(result.contact_phone) }}
									</span>
								</div>
							</router-link>
						</li>
					</ul>
					<div
						v-if="
							search_results.leads.results.length > 3 &&
							search_results.leads.shown_count !== search_results.leads.results.length
						"
						class="see-all-results"
						@click="expandShown('leads')"
					>
						See All Lead Results
						<icon type="chevron-right" size="16px" />
					</div>
				</div>

				<div v-if="search_results.users.results.length > 0" class="result-category">
					<h2>
						{{ search_results.users.category }}
						<span class="count">{{ search_results.users.results.length }}</span>
					</h2>
					<ul>
						<li
							v-for="(result, resultIndex) in search_results.users.results.slice(0, search_results.users.shown_count)"
							:key="resultIndex"
						>
							<router-link
								:to="linkHelper(result)"
								@click="handleLinkClick($event)"
								:class="{ 'text-red-500': ['suspended', 'archived'].includes(result.status) }"
							>
								<div class="result">
									<div class="flex align-items-center">
										<icon class="mr-1" type="account-tie" size="20px" />
										<span class="name">{{ result.user_name }}</span>
										<p-tag
											v-if="result.status === 'suspended'"
											severity="danger"
											class="ml-1"
											v-tooltip.top="'SUSPENDED'"
											value="SUSPENDED"
											style="font-size: 0.5rem"
										/>
										<p-tag
											v-if="result.status === 'archived'"
											severity="danger"
											class="ml-1"
											v-tooltip.top="'ARCHIVED'"
											value="ARCHIVED"
											style="font-size: 0.5rem"
										/>
									</div>
									<span class="details" v-if="result.account_name"
										>{{ result.account_name }} - {{ result.account_id }}</span
									>
									<span class="details"
										>{{ result.email || result.contact_email }} - {{ phoneFormat(result.contact_phone) }}</span
									>
								</div>
							</router-link>
						</li>
					</ul>
					<div
						v-if="
							search_results.users.results.length > 3 &&
							search_results.users.shown_count !== search_results.users.results.length
						"
						class="see-all-results"
						@click="expandShown('users')"
					>
						See All User Results
						<icon type="chevron-right" size="16px" />
					</div>
					<div></div>
				</div>

				<div class="results-message">
					<p v-if="!has_results" class="m-4 text-center">Enter a search</p>
					<p v-if="has_results && full_results.length === 0" class="m-4 text-center">No results, enter a new search.</p>
				</div>
			</div>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import { useAppStore } from '@/stores/app';
import { useSearchStore } from '@/stores/search';
import icon from '@/components/elements/Icon.vue';
import pButton from 'primevue/button';
import pDialog from 'primevue/dialog';
import pTag from 'primevue/tag';
import pText from 'primevue/inputtext';
import pProgressSpinner from 'primevue/progressspinner';
import { formatDate, phoneFormat, timeAgo } from '@/lib/Filters';
import { useMagicKeys } from '@vueuse/core';

export default {
	name: 'UniversalSearch',
	components: {
		pButton,
		pDialog,
		pTag,
		pText,
		pProgressSpinner,
		icon,
	},
	inject: ['mq'],
	setup() {
		const appStore = useAppStore();
		const searchStore = useSearchStore();
		const keys = useMagicKeys();
		const ctrl_f = keys['Ctrl+F'];
		return { appStore, searchStore, ctrl_f };
	},
	data() {
		return {
			full_results: this.searchStore.fullSearchResults,
			search_results: {
				accounts: {
					category: 'accounts',
					results: this.searchStore.accounts,
					shown_count: 3,
				},
				leads: {
					category: 'leads',
					results: this.searchStore.leads,
					shown_count: 3,
				},
				users: {
					category: 'users',
					results: this.searchStore.users,
					shown_count: 3,
				},
			},
			hasSelection: false,
			categoryIndex: -1,
			resultIndex: -1,
			search_loading: false,
			outer_search_focus: false,
			inner_search_focus: false,
			search_modal_open: false,
			search_query: '',
			lead_results: [],
			account_results: [],
			campaign_results: [],
			navigation_results: [],
			setting_results: [],
			has_results: false,
		};
	},
	methods: {
		formatDate,
		phoneFormat,
		timeAgo,
		expandShown(array_name) {
			this.search_results[array_name].shown_count = this.search_results[array_name].results.length;
		},
		linkHelper(result) {
			if (this.$root.sessionStore.isAdminUser && result.mpid) {
				// links need to have mpid set
				return `/marketplaces/manage/${result.mpid}${result.link}`;
			} else {
				return result.link;
			}
		},
		setSearchResults() {
			this.search_results.accounts.results = this.searchStore.accounts;
			this.search_results.leads.results = this.searchStore.leads;
			this.search_results.users.results = this.searchStore.users;
			this.full_results = this.searchStore.fullSearchResults;
			this.has_results = true;
		},
		handleSearchModal() {
			this.search_modal_open = !this.search_modal_open;
			// If opening, do a search, otherwise close modal
			if (this.search_modal_open) {
				this.handleInnerSearch();
			}
		},
		async handleInnerSearch() {
			if (this.search_query !== '') {
				this.search_loading = true;
				await this.searchStore.newSearch(this.search_query);
				this.setSearchResults();
				this.search_loading = false;
			}
		},
		handleLinkClick(event) {
			// If not holding down the cmd key when clicked, close the search modal
			if (!event.metaKey) {
				this.search_modal_open = false;
			}
		},
		resetSearch() {
			this.search_query = '';
			this.search_results.accounts.results = [];
			this.search_results.accounts.shown_count = 3;
			this.search_results.leads.results = [];
			this.search_results.leads.shown_count = 3;
			this.search_results.users.results = [];
			this.search_results.users.shown_count = 3;
			this.has_results = false;
		},
		updateSelected(event) {
			this.hasSelection = true;
		},
	},
	watch: {
		ctrl_f(new_val) {
			if (new_val) {
				this.handleSearchModal();
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.search-input {
	position: relative;
	.icon-wrapper {
		position: absolute;
	}

	.return-icon-right {
		background: var(--gray-15);
		border-radius: 4px;
		font-size: var(--font-size-sm);
		padding: 0.25rem;
		position: absolute;
		right: 0.75rem;
		top: 50%;
		transform: translateY(-50%);
	}

	input {
		padding: 6px 10px 8px 40px;
	}
}

.search-btn {
	a {
		color: var(--black);
		cursor: pointer;
		font-weight: bold;
		padding: 0.25rem;
	}
}

#search-modal.p-dialog {
	border: 0;
	box-shadow: 0;
	padding: 0;

	.p-dialog-header {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.p-dialog-footer {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;

		.legend {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			margin: 0;
			padding: 0;

			.icon {
				background: var(--gray-15);
				border-radius: 4px;
				margin-right: 0.5rem;
				padding: 0 0.25rem;
			}
		}
	}

	.clear-search {
		margin: 0 0.5rem;
	}

	.search-input,
	.p-input-icon-left,
	.p-inputtext {
		margin-right: 1rem;
		width: 100%;
	}

	.p-dialog-content {
		line-height: 1.5;
		overflow-y: scroll;
		padding: 0;

		.search-loading {
			margin: 1rem 0;
			text-align: center;
		}

		.results {
			.selected {
				background-color: var(--gray-10);
			}

			.see-all-results {
				align-items: center;
				display: flex;
				justify-content: flex-start;
				margin-top: 0.5rem;
				color: var(--color-b);
				font-weight: bold;
				&:hover {
					background-color: var(--gray-05);
					cursor: pointer;
				}
			}

			.recent-search-terms {
				padding: 1rem;

				h2 {
					color: var(--gray-50);
					font-size: 0.9rem;
					font-weight: bold;
					text-transform: uppercase;
				}

				ul {
					list-style: none;
					margin: 0;

					li {
						align-items: center;
						display: flex;
						font-size: 1rem;
						font-weight: bold;
						justify-content: flex-start;
						margin: 0 0 0.5rem;

						i {
							font-size: 1rem;
							margin-right: 0.5rem;
						}

						a {
							color: var(--black);
							padding: 0.25rem;
							text-decoration: none;
							width: 100%;

							&:hover {
								background-color: var(--gray-05);
								cursor: pointer;
							}
						}
					}
				}
			}

			.result-category:has(+ :not(.result-category)) {
				border-bottom: 0;
			}

			.result-category {
				border-bottom: 1px solid var(--gray-10);
				padding: 1rem;
				ul {
					list-style: none;
					margin: 0;

					li {
						align-items: center;
						display: flex;
						font-size: 1rem;
						font-weight: bold;
						justify-content: flex-start;
						margin: 0 0 0.5rem;
						width: 100%;

						.title {
							align-items: center;
							display: flex;
						}

						a {
							color: inherit;
							padding: 0.25rem 0.5rem;
							text-decoration: none;
							width: 100%;

							&:hover {
								cursor: pointer;
								background-color: var(--gray-05);
							}
						}

						.details {
							color: var(--gray-45);
							display: block;
							font-size: 0.85em;
							font-weight: normal;
						}
					}
				}

				h2 {
					color: var(--gray-50);
					font-size: 0.9rem;
					font-weight: bold;
					text-transform: uppercase;

					.count {
						background: var(--gray-50);
						border-radius: 4px;
						color: white;
						font-size: 0.8rem;
						padding: 1px 5px;
					}
				}
			}
		}
	}
}
</style>

<style lang="less">
.marketplace #universal-search input::placeholder {
	color: white;
	opacity: 0.5;
}
</style>
