<template>
	<div id="live-transfer-start" class="view-content">
		<div class="page-heading">
			<h1>Live Transfer Workstation</h1>
			<h2>Create New Lead</h2>
		</div>
		<gutter size="20px" />
		<div class="card">
			<div class="fields">
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Vertical:</label>
						<div class="controls gap-20">
							<div v-for="vertical in $root.appStore.options.verticals" class="field fit">
								<div
									:class="['vertical-button', { selected: lead.vertical_id === vertical.value }]"
									@click="lead.vertical_id = vertical.value"
								>
									<vertical-icon :vertical-id="vertical.value" size="30px" />
									<div class="label">{{ vertical.label }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label req">First Name:</label>
								<p-input-text ref="first_name" v-model="lead.first_name" autofocus />
								<div v-if="v$.lead.first_name.$error" class="validation-error">
									{{ v$.lead.first_name.$errors[0].$message }}
								</div>
							</div>
							<div class="field">
								<label class="control-label req">Last Name:</label>
								<p-input-text v-model="lead.last_name" />
								<div v-if="v$.lead.last_name.$error" class="validation-error">
									{{ v$.lead.last_name.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label req">Phone Number:</label>
								<p-input-mask v-model="lead.phone" mask="(999) 999-9999" unmask />
								<div v-if="v$.lead.phone.$error" class="validation-error">
									{{ v$.lead.phone.$errors[0].$message }}
								</div>
							</div>
							<div class="field">
								<label class="control-label req">Email Address:</label>
								<p-input-text v-model="lead.email" />
								<div v-if="v$.lead.email.$error" class="validation-error">
									{{ v$.lead.email.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label req">State:</label>
								<p-dropdown
									v-model="lead.state"
									:options="state_options"
									option-label="label"
									option-value="value"
									filter
								/>
								<div v-if="v$.lead.state.$error" class="validation-error">
									{{ v$.lead.state.$errors[0].$message }}
								</div>
							</div>
							<div class="field">
								<label class="control-label req">ZIP Code:</label>
								<p-input-mask v-model="lead.zip" mask="99999" />
								<div v-if="v$.lead.zip.$error" class="validation-error">
									{{ v$.lead.zip.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Spoken Language:</label>
						<div class="controls">
							<div class="field">
								<p-select-button
									v-model="lead.spoken_language"
									:options="language_options"
									option-label="label"
									option-value="value"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Date of Birth:</label>
						<div class="controls">
							<div class="field">
								<p-input-mask v-model="formattedDob" mask="99/99/9999" unmask />
								<div v-if="v$.lead.data.dob.$error" class="validation-error">
									{{ v$.lead.data.dob.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Notes:</label>
						<div class="controls">
							<div class="field">
								<p-textarea v-model="lead.data.notes" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="actions">
				<p-button icon="pi pi-check" :loading="saving" label="Save Lead" @click="saveLead()" />
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required, email } from '@vuelidate/validators';
import { insertLTLead } from '@GQL';
import STATES from '@/lib/Data/states.json';
import log from '@/lib/Log';

export default {
	name: 'LiveTransferCreate',
	components: {
		verticalIcon,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			saving: false,
			lead: {
				vertical_id: this.$route.query.vertical_id,
				first_name: '',
				last_name: '',
				phone: this.$route.query.phone,
				state: null,
				zip: null,
				data: {
					dob: null,
					notes: '',
				},
				spoken_language: 'english',
			},
			state_options: STATES,
			language_options: [
				{ label: 'English', value: 'english' },
				{ label: 'Spanish', value: 'spanish' },
				{ label: 'Other', value: 'other' },
			],
		};
	},
	computed: {
		formattedDob: {
			get() {
				return dayjs(this.lead.data.dob).format('MM/DD/YYYY');
			},
			set(new_value) {
				if (new_value.length >= 8) {
					this.lead.data.dob =
						new_value.substring(4) + '-' + new_value.substring(0, 2) + '-' + new_value.substring(2, 4);
				}
			},
		},
	},
	validations() {
		return {
			lead: {
				first_name: {
					required: helpers.withMessage('A first name is required', required),
				},
				last_name: {
					required: helpers.withMessage('A last name is required', required),
				},
				phone: {
					required: helpers.withMessage('A phone number is required', required),
					phone: helpers.withMessage('Please enter a valid phone number', (value) => {
						const phone_pattern = /^\d{10}$/;
						return phone_pattern.test(value);
					}),
				},
				email: {
					required: helpers.withMessage('An email address is required', required),
					email: helpers.withMessage('Please enter a valid email address', email),
				},
				state: {
					required: helpers.withMessage('A state is required', required),
				},
				zip: {
					required: helpers.withMessage('A ZIP code is required', required),
				},
				data: {
					dob: {
						required: helpers.withMessage('A date of birth is required', required),
					},
				},
			},
		};
	},
	mounted() {
		this.$refs.first_name.$el.focus();
	},
	methods: {
		async saveLead() {
			this.saving = true;
			try {
				const is_valid = await this.v$.$validate();
				if (is_valid) {
					const new_lead = await insertLTLead(this.lead);
					this.$router.push({
						name: 'LiveTransferForm',
						params: {
							lead_id: new_lead.id,
							vertical_id: this.lead.vertical_id,
						},
					});
				}
			} catch (err) {
				log
					.error('LTAWorkstation.CreatedPage', {
						error: err,
						data: {
							lead: this.lead,
							vertical_id: this.lead.vertical_id,
							agent_id: this.$root.sessionStore.user.id,
						},
					})
					.catch((err) => {});
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save the lead',
					detail: 'Please contact dev support for help',
					life: 5000,
				});
			} finally {
				this.saving = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.fields {
	padding: 30px;
}

.vertical-button {
	align-items: center;
	border: 1px solid var(--gray-25);
	border-radius: 3px;
	cursor: pointer;
	display: flex;
	gap: 10px;
	height: 60px;
	padding: 0 20px;

	&.selected {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-lighter);
	}
}

.actions {
	border-top: 1px solid var(--gray-10);
	padding: 30px;
}
</style>
