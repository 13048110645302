<template>
	<p-confirm-popup :group="confirm_id" />
	<p-button
		v-tooltip.top="'Generate as CSV Report'"
		icon="pi pi-download"
		aria-label="CSV Report"
		class="mr-2"
		@click.prevent="generateCSVReport($event)"
	/>
</template>

<script lang="ts">
/* This component works with the Jobs service */
import { mapStores } from 'pinia';
import { useSessionStore } from '@/stores/session';
import type { FilterParams } from '@nextgenleads/db';
import { runJob } from '@GQL';
import pConfirmPopup from 'primevue/confirmpopup';
import { uniqueId } from 'lodash-es';

export default {
	name: 'CSVReport',
	components: {
		pConfirmPopup,
	},
	data() {
		return {
			confirm_id: uniqueId(),
		};
	},
	props: {
		query: {
			type: Object,
			required: true,
		},
		product: {
			type: String,
		},
		report: {
			type: String,
			required: true,
		},
		show_purchases: {
			type: Boolean,
		},
		ancestor_ids: {
			type: Array,
		},
	},
	computed: {
		...mapStores(useSessionStore),
		accountId() {
			return (
				this.$route.params.account_id ||
				this.$route.params.parent_account_id ||
				this.$root.sessionStore.user.account_id ||
				('mpid' in this.$route.params ? `${this.$route.params.mpid}-000-000` : `${this.$root.appStore.mpid}-000-000`)
			);
		},
		mpid() {
			if ('params' in this.$route && this.$route.params.mpid) {
				return this.$route.params.mpid;
			} else {
				return this.$root.appStore.mpid;
			}
		},
		isMPReport() {
			return this.accountId.indexOf('000-000') > -1;
		},
	},
	methods: {
		async generateCSVReport(event) {
			/* NOTE: Report type may have different query fields,
			so if another report type is added, double check these columns values match */
			const filters: (FilterParams | string)[] = [
				{
					field: 'created_at',
					op: 'bt',
					value: this.query.filters.created_at,
				},
			];

			if (this.isMPReport) {
				// If this is a Marketplace Report, use the MPID instead of Account ID
				filters.push({
					field: 'mpid',
					op: 'eq',
					value: this.mpid,
				});
			}

			if (!this.isMPReport && this.ancestor_ids && this.ancestor_ids.length > 0) {
				// If this is a Parent Account Purchase Report, use all the Child Account IDs
				filters.push({
					field: 'account_id',
					op: 'in',
					value: this.ancestor_ids,
				});
			} else if (!this.isMPReport) {
				// Get for that specific account
				filters.push({
					field: 'account_id',
					op: 'eq',
					value: this.accountId,
				});
			}

			if (this.product) {
				filters.push({
					field: 'product',
					op: 'eq',
					value: this.product,
				});
			}

			if (this.query.filters.vertical_id && this.query.filters.vertical_id.length > 0) {
				filters.push({
					op: 'in',
					field: 'vertical_id',
					value: this.query.filters.vertical_id,
				});
			}

			if (this.query.filters.source_search) {
				filters.push({
					op: 'eq',
					field: 'source_id',
					value: this.query.filters.source_search,
				});
			}

			if (this.query.filters.state && this.query.filters.state.length > 0) {
				filters.push({
					op: 'in',
					field: 'lead_state',
					value: this.query.filters.state,
				});
			}

			if (this.query.filters.campaign_id) {
				filters.push({
					op: 'eq',
					field: 'campaign_id',
					value: this.query.filters.campaign_id,
				});
			}

			// Transaction Type I.E. Deposit
			if (this.query.filters.type && this.query.filters.type.length > 0) {
				filters.push({
					field: 'type',
					op: 'in',
					value: this.query.filters.type,
				});
			}

			if (this.report === 'purchases') {
				if (this.$root.sessionStore.isAdminUser && this.query.filters.status && this.query.filters.status.length > 0) {
					filters.push({
						op: 'in',
						field: 'status',
						value: this.query.filters.status,
					});
				} else {
					filters.push(`status IN ('complete','return_rejected','return_pending')`);
				}
			}

			if (this.report === 'transactions') {
				if (!this.show_purchases) {
					filters.push(`(sub_type IS NULL OR sub_type != 'Purchase')`);
				}
			}

			const list_params = {
				filters: [filters],
				order: {
					field: 'created_at',
					desc: true,
				},
			};

			this.$confirm.require({
				target: event.currentTarget,
				group: this.confirm_id,
				message: this.message || 'Download data as CSV? This may take a few minutes to generate.',
				acceptClass: this.acceptClass || 'p-button-info',
				acceptIcon: 'pi pi-download',
				acceptLabel: this.acceptLabel || 'Download',
				rejectLabel: this.rejectLabel || 'Cancel',
				accept: async () => {
					try {
						const result = await runJob({
							script_name: 'CreateCSVReport',
							script_type: 'jobs',
							ran_by: this.sessionStore.user.id,
							parameters: {
								user_id: this.sessionStore.user.id,
								table: this.report,
								get_list_params: list_params,
							},
						});
						if (result) {
							this.$toast.add({
								severity: 'info',
								summary: 'Generating CSV Report',
								detail:
									'This may take a few minutes depending on report size. The download link will be sent to your notifications.',
								life: 5000,
							});
						}
					} catch (err) {
						console.error('CSV Report Error', err);
						this.$toast.add({
							severity: 'error',
							summary: 'Error generating CSV Report',
							life: 3000,
						});
					}
				},
			});
		},
	},
};
</script>

<style lang="less">
.p-confirm-popup-message,
.p-confirm-popup-footer {
	font-size: var(--font-size-sm);
}
</style>
