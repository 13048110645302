<template>
	<div id="data-leads-list">
		<query-form v-model:query="query" :loading="loading" @apply="getMarketplaceLeadsReport" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.filters.created_at, 'MMM DD, YYYY') }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Vertical:</div>
					<div class="value">
						{{ displaySelected(query.filters.vertical_id, appStore.verticalOptions) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Source Search:</div>
					<div class="value">{{ query.filters.source_search || 'Any' }}</div>
				</div>
				<div class="display-value">
					<div class="label">State:</div>
					<div class="value">
						{{ displaySelected(query.filters.state, state_options) }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button
					@click="getMarketplaceLeadsReport(true)"
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
				/>
				<CSVReport v-if="data_leads.length > 0" product="data" :query="query" report="purchases" />
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<date-range-picker v-model="form.query.filters.created_at" show-time />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Filter By Vertical:</label>
								<div class="controls">
									<div class="field">
										<div
											v-for="vertical of appStore.verticalOptions"
											:key="vertical.value"
											class="p-checkbox-item flex align-items-center"
										>
											<p-checkbox
												v-model="query.filters.vertical_id"
												:input-id="vertical.value"
												name="category"
												:value="vertical.value"
											/>
											<label :for="vertical.value">{{ vertical.label }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column>
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Query By Source ID:</label>
								<div class="controls">
									<div class="field">
										<p-input-text v-model="query.filters.source_search" class="search-query" type="text" />
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">States:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.state"
											filter
											filter-placeholder="Filter States"
											:options="state_options"
											option-label="label"
											option-value="value"
											placeholder="Select States"
											class="state-select w-full md:w-20rem"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div class="metrics">
			<row>
				<div class="column">
					<metric label="Data Lead Purchases" :value="row_count" />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Cost Per Lead" :value="revenuePerLead" is-currency inverse-metric />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Total Cost" :value="revenue" is-currency inverse-metric />
				</div>
			</row>
		</div>
		<gutter size="20px" />
		<div class="quick-filters">
			<span class="m-2">Quick Filter:</span>
			<div class="toggles">
				<quick-filter
					v-model="query.filters.vertical_id"
					value="health_insurance"
					allow-multiple
					@toggle="handleQuickFilterToggle"
				>
					Health Insurance
				</quick-filter>
				<quick-filter
					v-model="query.filters.vertical_id"
					value="medicare"
					allow-multiple
					@toggle="handleQuickFilterToggle"
				>
					Medicare
				</quick-filter>
			</div>
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table ref="dt" :loading="loading" :rows="100" scrollable :value="filteredLeads">
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column header="Purchased On">
					<template #body="lead">
						<div class="capture-date">
							{{ formatDate(new Date(lead.data.created_at), 'YYYY-MM-DD | h:mm:ssa') }}
						</div>
						<div class="sub-value">{{ timeAgo(lead.data.created_at) }}</div>
					</template>
				</p-column>
				<p-column header="Account Info">
					<template #body="lead">
						<router-link
							v-if="$route.params.mpid"
							:to="
								$root.sessionStore.isAdminUser
									? `/marketplaces/manage/${lead.data.mpid}/accounts/manage/${lead.data.account_id}/campaigns`
									: `/accounts/manage/${lead.data.account_id}/campaigns`
							"
						>
							<strong>{{ lead.data.account_name }}</strong>
						</router-link>
						<router-link v-else :to="`/accounts/manage/${lead.data.account_id}/campaigns`">
							<strong>{{ lead.data.account_name }}</strong>
						</router-link>
						<div class="sub-value">{{ lead.data.campaign_name }}</div>
					</template>
				</p-column>
				<p-column header="Lead Info">
					<template #body="lead">
						<router-link
							:to="
								$root.sessionStore.isAdminUser
									? `/leads/${lead.data.lead_id}`
									: `/marketplaces/manage/${lead.data.mpid}/accounts/manage/${lead.data.account_id}/leads/${lead.data.lead_id}`
							"
						>
							<strong>{{ lead.data.first_name }} {{ lead.data.last_name }}</strong>
						</router-link>
						<div class="sub-value">{{ lead.data.lead_id }}: {{ phoneFormat(lead.data.phone) }}</div>
					</template>
				</p-column>
				<p-column header="Source">
					<template #body="lead">
						<template v-if="$root.sessionStore.isAdminUser">
							{{ lead.data.vendor_name }}: {{ lead.data.data_source_id || lead.data.source_id }}
							<div class="sub-value">{{ lead.data.sub_id }}</div>
						</template>
						<template v-else>
							{{ lead.data.vendor_name }}
							<div class="sub-value">
								{{ lead.data.data_source_hash || lead.data.source_hash }}-{{ lead.data.sub_id_hash }}
							</div>
						</template>
					</template>
				</p-column>
				<p-column header="State" class="column-align-center">
					<template #body="lead">
						{{ lead.data.state }}
					</template>
				</p-column>
				<p-column header="Vertical" class="column-align-center">
					<template #body="lead">
						<vertical-icon :vertical-id="lead.data.vertical_id" size="30px" />
					</template>
				</p-column>
				<p-column header="Price" class="column-align-right">
					<template #body="lead">
						{{ currency(lead.data.price) }}
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="row_count" @page="handlePageChange" />
		</div>
		<AuctionModal ref="auction_modal" />
	</div>
</template>

<script lang="ts">
import { useAppStore } from '@/stores/app';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import { startCase, map } from 'lodash-es';
import { endOfDay, startOfDay, parseISO } from 'date-fns';
import {
	chDate,
	formatDate,
	displaySelected,
	currency,
	verticalName,
	phoneFormat,
	capitalize,
	timeAgo,
} from '@/lib/Filters';
import AuctionModal from '@/views/Leads/panels/modals/AuctionModal.vue';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import typeIcon from '@/components/widgets/TypeIcon.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import STATES from '@/lib/Data/states.json';
import { source_type_options } from '@/lib/Options';
import { reportMarketplacePurchases } from '@GQL';
import CSVReport from '@/components/widgets/CSVReport.vue';

export default {
	name: 'MarketplaceDataLeadPurchasesList',
	components: {
		AuctionModal,
		CSVReport,
		dateRangePicker,
		queryForm,
		quickFilter,
		typeIcon,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
					vertical_id: [],
					source_type: [],
					source_search: '',
					state: [],
				},
				pagination: {
					page_size: 100,
					page: 0,
				},
			},
			row_count: 0,
			revenue: 0,
			quick_filters: {
				vertical_id: [],
			},
			state_options: [...STATES],
			source_type_options,
			data_leads: [],
			selected_purchases: [],
		};
	},
	computed: {
		purchase_path() {
			if (this.marketplaceStore.mpid) {
				const items = this.$route.path.split('/');
				const path = items.filter((item) => !['reports', 'data-leads'].includes(item));
				path.push('purchases');
				return path.join('/');
			} else {
				return '/purchases';
			}
		},
		revenuePerLead() {
			if (this.row_count > 0) {
				return this.revenue / this.row_count;
			}
			return 0;
		},
		filteredLeads() {
			return this.data_leads.filter((lead) => {
				if (this.quick_filters.vertical_id.length) {
					return this.quick_filters.vertical_id.includes(lead.vertical_id);
				}
				return true;
			});
		},
		...mapStores(useAppStore, useMarketplaceStore, useSessionStore),
	},
	async created() {
		await this.getMarketplaceLeadsReport();
	},
	methods: {
		capitalize,
		chDate,
		currency,
		formatDate,
		displaySelected,
		timeAgo,
		map,
		parseISO,
		phoneFormat,
		startCase,
		verticalName,
		clearQuery() {
			this.loading = false;
		},
		handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getMarketplaceLeadsReport();
		},
		handleQuickFilterToggle() {
			this.$nextTick(async () => {
				await this.getMarketplaceLeadsReport();
			});
		},
		async getMarketplaceLeadsReport(refresh) {
			this.loading = true;

			const query_params = {
				date_range: this.query.filters.created_at,
				filters: [],
				page: this.query.pagination.page,
			};

			const filters = [
				{
					op: 'eq',
					field: 'p.product',
					value: 'data',
				},
				{
					op: 'eq',
					field: 'p.mpid',
					value: this.$route.params.mpid || this.$root.appStore.mpid,
				},
			];

			if (this.query.filters.vertical_id.length > 0) {
				filters.push({
					op: 'in',
					field: 'p.vertical_id',
					value: this.query.filters.vertical_id,
				});
			}

			if (this.query.filters.source_search) {
				filters.push({
					op: 'eq',
					field: 'p.source_id',
					value: this.query.filters.source_search,
				});
			}

			if (this.query.filters.state.length > 0) {
				filters.push({
					op: 'in',
					field: 'p.lead_state',
					value: this.query.filters.state,
				});
			}

			if (filters.length > 0) {
				query_params.filters.push(filters);
			}

			try {
				const report = await reportMarketplacePurchases(query_params, refresh);
				this.revenue = report.metrics.revenue;
				this.data_leads = report.rows;
				this.row_count = report.row_count;
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Data Leads',
					life: 3000,
				});
			}
			this.loading = false;
		},
		openAuctionLogModal(lead_id, auction_log_id) {
			this.$refs.auction_modal.open(lead_id, auction_log_id);
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.table-wrapper {
	max-width: 100%;
}

.search-query {
	max-width: 20rem;
}

.created-sub,
.purchased-sub {
	color: var(--gray-50);
	font-style: italic;
	margin-top: 0.5rem;
}

.sub {
	color: var(--gray-50);
	margin-top: 0.5rem;
}

.click-target .mdi-icon {
	color: var(--color-b);
	cursor: pointer;
}
</style>
