<template>
	<div id="tools" class="view-content">
		<div class="page-heading">
			<h1>Tools</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<div class="tool card">
				<div class="details">
					<h2>Bulk Account Reassignment</h2>
					<p class="sub-value">
						Assign multiple accounts to an account manager or change accounts from one account manager to another.
					</p>
				</div>
				<div class="action">
					<router-link :to="`tools/bulk-account-reassignment`">
						<p-button label="Account Reassignment" />
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'Tools',
	data() {
		return {
			breadcrumb_items: [
				{
					label: 'Tools',
				},
			],
		};
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';
.tool {
	max-width: 1000px;
	padding: 1rem;
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	.mobile({
        flex-direction: column;
    });
}
</style>
