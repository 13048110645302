import ToolsPage from '@/views/Tools/Page.vue';
import BulkAccountReassignmentPage from '@/views/Tools/BulkAccountReassignment/Page.vue';

export default [
	{
		path: 'tools',
		name: 'ToolsPage',
		component: ToolsPage,
		meta: {
			permissions: 'tools',
		},
	},
	{
		path: 'tools/bulk-account-reassignment',
		name: 'BulkAccountReassignment',
		component: BulkAccountReassignmentPage,
		meta: {
			permissions: 'tools',
		},
	},
];
