<template>
	<div id="live-transfers-leads-list">
		<query-form v-model:query="query" :loading="loading" @apply="getAdminLiveTransfersReport" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Vertical:</div>
					<div class="value">
						{{ displaySelected(query.filters.vertical_id, appStore.verticalOptions) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Business Unit:</div>
					<div class="value">
						{{ displaySelected(query.filters.business_unit, business_unit_options) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">State:</div>
					<div class="value">
						{{ displaySelected(query.filters.state, state_options) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Source Search:</div>
					<div class="value">{{ query.filters.source_search || 'Any' }}</div>
				</div>
				<div class="display-value">
					<div class="label">LT Agents:</div>
					<div class="value">
						{{ displaySelected(query.filters.agent_id, transfer_agent_options) }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getAdminLiveTransfersReport(true)"
				/>
			</template>
			<template #form="form">
				<row>
					<column max-width="450px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Date Range:</label>
								<div class="controls">
									<div class="field">
										<date-range-picker v-model="form.query.filters.created_at" show-time />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Date Filter Type:</label>
								<div class="controls">
									<div class="field">
										<p-dropdown
											v-model="form.query.date_type"
											:options="date_type_options"
											option-label="label"
											option-value="value"
											placeholder="Select Date Filter Type"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Transfer Agents:</label>
								<div class="controls">
									<div class="field">
										<p-dropdown
											v-model="form.query.filters.agent_id"
											:options="transfer_agent_options"
											option-label="label"
											option-value="value"
											placeholder="Select Transfer Agents"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column auto max-width="350px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Filter By Vertical:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.filters.vertical_id"
											:options="appStore.verticalOptions"
											option-label="label"
											option-value="value"
											placeholder="Select Verticals"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Business Unit:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.business_unit"
											:options="business_unit_options"
											option-label="label"
											option-value="value"
											placeholder="Select Business Unit"
											class="source-type-select w-full md:w-20rem"
										>
										</p-multi-select>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column auto max-width="350px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">States:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.state"
											filter
											filter-placeholder="Filter States"
											:options="state_options"
											option-label="label"
											option-value="value"
											placeholder="Select States"
											class="state-select w-full md:w-20rem"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Query By Source Code:</label>
								<div class="controls">
									<div class="field">
										<p-input-text
											v-model="query.filters.source_search"
											class="search-query"
											type="text"
											placeholder="Use * as a wildcard"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<column></column>
				</row>
			</template>
		</query-form>
		<div class="metrics">
			<row>
				<div class="column">
					<metric label="Live Transfer Count" :value="row_count" />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Revenue Per Transfer" :value="revenuePerLead" is-currency inverse-metric />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Total Revenue" :value="revenue" is-currency inverse-metric />
				</div>
			</row>
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table
				ref="dt"
				:loading="loading"
				:rows="100"
				scrollable
				:value="filteredLeads"
				data-key="id"
				:key="refreshKey"
			>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column header="Transferred On">
					<template #body="lead">
						<template v-if="lead.data.purchase_date">
							<div class="sold-date">
								{{ formatDate(new Date(lead.data.purchase_date), 'YYYY-MM-DD | h:mm:ssa') }}
							</div>
							<div class="sub-value">
								{{ capitalize(timeAgo(lead.data.purchase_date)) }}
							</div>
						</template>
					</template>
				</p-column>
				<p-column header="Captured On">
					<template #body="lead">
						<div class="capture-date">
							{{ formatDate(new Date(lead.data.created_at), 'YYYY-MM-DD | h:mm:ssa') }}
						</div>
						<div class="sub-value">{{ capitalize(timeAgo(lead.data.created_at)) }}</div>
					</template>
				</p-column>
				<p-column header="Lead Info">
					<template #body="lead">
						<router-link :to="`/leads/${lead.data.lead_id}`">
							<strong>{{ lead.data.first_name }} {{ lead.data.last_name }}</strong>
						</router-link>
						<div class="sub-value">{{ lead.data.lead_id }}: {{ phoneFormat(lead.data.phone) }}</div>
					</template>
				</p-column>
				<p-column header="Source" class="compact">
					<template #body="lead">
						{{ lead.data.vendor_id }}: {{ lead.data.source_id }}
						<div class="sub-value">{{ lead.data.sub_id }}</div>
					</template>
				</p-column>
				<p-column header="State" class="column-align-center">
					<template #body="lead">
						{{ lead.data.state }}
					</template>
				</p-column>
				<p-column header="Vertical" class="column-align-center">
					<template #body="lead">
						<vertical-icon :vertical-id="lead.data.vertical_id" size="30px" />
					</template>
				</p-column>
				<p-column header="Transfer Agent" class="column-align-center">
					<template #body="lead">
						{{ lead.data.agent_name || 'Unknown' }}
					</template>
				</p-column>
				<p-column header="Customer" class="compact">
					<template #body="lead">
						<router-link :to="accountLink(lead.data.account_id)">{{ lead.data.account_name }}</router-link>
						<div class="sub-value">
							<router-link :to="campaignLink(lead.data.campaign_id, lead.data.account_id)">{{
								lead.data.campaign_name
							}}</router-link>
						</div>
					</template>
				</p-column>
				<p-column header="Status" class="column-align-center">
					<template #body="lead">
						<span :class="lead.data.status">
							<icon v-tooltip.top="capitalize(lead.data.status)" :type="statusIcon(lead.data.status)" size="24px" />
						</span>
					</template>
				</p-column>
				<p-column header="Revenue" class="column-align-right">
					<template #body="lead">
						<span :class="{ nil: lead.data.revenue === 0 }">{{ currency(lead.data.revenue) }}</span>
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="row_count" @page="handlePageChange" />
		</div>
	</div>
</template>

<script lang="ts">
import { useAppStore } from '@/stores/app';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import { startCase, map, sortBy } from 'lodash-es';
import { endOfDay, startOfDay, parseISO } from 'date-fns';
import { formatDate, displaySelected, currency, verticalName, phoneFormat, capitalize, timeAgo } from '@/lib/Filters';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import typeIcon from '@/components/widgets/TypeIcon.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import STATES from '@/lib/Data/states.json';
import { getUsersList, reportAdminLiveTransfers } from '@GQL';

export default {
	name: 'LiveTransfersList',
	components: {
		dateRangePicker,
		queryForm,
		typeIcon,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			query: {
				date_type: 'p',
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
					vertical_id: [],
					business_unit: [],
					agent_id: [],
					source_search: '',
					state: [],
				},
				pagination: {
					page_size: 100,
					page: 0,
				},
			},
			row_count: 0,
			revenue: 0,
			quick_filters: {
				vertical_id: [],
			},
			leads: [],
			date_type_options: [
				{ label: 'Purchase Created', value: 'p' },
				{ label: 'Lead Created', value: 'l' },
			],
			state_options: [...STATES],
			business_unit_options: [
				{ label: 'Owned and Operated', value: 'OO' },
				{ label: 'Partner Network', value: 'PN' },
			],
			transfer_agent_options: [],
			refreshKey: 0,
		};
	},
	computed: {
		revenuePerLead() {
			if (this.row_count > 0) {
				return this.revenue / this.row_count;
			}
			return 0;
		},
		filteredLeads() {
			return this.leads.filter((lead) => {
				if (this.quick_filters.vertical_id.length) {
					return this.quick_filters.vertical_id.includes(lead.vertical_id);
				}
				return true;
			});
		},
		...mapStores(useAppStore, useSessionStore),
	},
	async created() {
		await this.getLTAgentOptions();
		await this.getAdminLiveTransfersReport();
	},
	methods: {
		capitalize,
		currency,
		formatDate,
		displaySelected,
		timeAgo,
		map,
		parseISO,
		phoneFormat,
		startCase,
		verticalName,
		clearQuery() {
			this.loading = false;
		},
		handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page + 1;
			return this.getAdminLiveTransfersReport();
		},
		async getAdminLiveTransfersReport(refresh) {
			this.loading = true;

			const query_params = {
				date_range: this.query.filters.created_at,
				filters: [],
				date_type: this.query.date_type,
				product: 'live_transfer',
				page: this.query.pagination.page,
			};

			const filters = [];

			if (this.query.filters.vertical_id.length > 0) {
				filters.push({
					op: 'in',
					field: 'l.vertical_id',
					value: this.query.filters.vertical_id,
				});
			}

			if (this.query.filters.business_unit.length > 0) {
				filters.push({
					op: 'in',
					field: 'l.business_unit',
					value: this.query.filters.business_unit,
				});
			}

			if (this.query.filters.source_search) {
				filters.push({
					op: 'eq',
					field: 'l.source_id',
					value: this.query.filters.source_search,
				});
			}

			if (this.query.filters.state.length > 0) {
				filters.push({
					op: 'in',
					field: 'l.state',
					value: this.query.filters.state,
				});
			}

			if (this.query.filters.agent_id.length > 0) {
				filters.push({
					op: 'in',
					field: 'p.agent_id',
					value: this.query.filters.agent_id,
				});
			}

			if (filters.length > 0) {
				query_params.filters.push(filters);
			}

			try {
				const report = await reportAdminLiveTransfers(query_params, refresh);
				this.revenue = report.metrics.revenue;
				this.leads = report.rows;
				this.row_count = report.row_count;
				if (refresh) {
					this.refreshKey += 1;
				}
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Live Transfers',
					life: 3000,
				});
			}
			this.loading = false;
		},
		async getLTAgentOptions() {
			const result = await getUsersList(
				[[`account_id IS NULL AND settings->>'team' = 'call_center' AND status = 'active'`]],
				['users']
			);

			if (result) {
				this.transfer_agent_options = sortBy(
					result.map((user) => {
						return {
							label: `${user.first_name} ${user.last_name}`,
							value: user.id,
						};
					}),
					['label']
				);
			}
		},
		statusIcon(status) {
			let icon;
			switch (status) {
				case 'complete':
					icon = 'phone-outgoing';
					break;
				case 'canceled':
					icon = 'phone-remove';
					break;
				default:
					icon = 'phone-return';
					break;
			}
			return icon;
		},
		accountLink(account_id) {
			const mpid = account_id.split('-')[0].toUpperCase();
			return {
				name: 'AccountsDetail:AccountActivity',
				params: {
					mpid,
					account_id,
				},
			};
		},
		campaignLink(campaign_id, account_id) {
			const mpid = account_id.split('-')[0].toUpperCase();
			return {
				name: 'AccountsDetail:EditCampaign',
				params: {
					mpid,
					account_id,
					campaign_id: campaign_id,
				},
			};
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.table-wrapper {
	max-width: 100%;
}

.search-query {
	max-width: 20rem;
}

.created-sub,
.purchased-sub {
	color: var(--gray-50);
	font-style: italic;
	margin-top: 0.5rem;
}

.sub {
	color: var(--gray-50);
	margin-top: 0.5rem;
}

.click-target .mdi-icon {
	color: var(--color-b);
	cursor: pointer;
}

:deep(td.compact) {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;

	.sub-value {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&:hover {
		max-width: 500px;
	}
}

:deep(.return_pending),
:deep(.return_accepted) {
	color: var(--gray-35);
}

:deep(.complete) {
	color: var(--green);
}

:deep(.canceled) {
	color: var(--red);
}

:deep(.nil) {
	color: #f88a7d;
	font-weight: bold;
}

:deep(.sub-value a) {
	color: var(--gray-50);
}
</style>
