<template>
	<div class="lt-question">
		<div class="lt-question-heading">
			<div class="lt-question-icon">
				<icon type="comment-text-outline" size="24px" />
			</div>
			<div class="lt-question-body">
				<p class="show-when-expanded">
					Depending on the type of plans available, insurance may not have a cost. If there is a cost, are you able to
					afford <strong>{{ budget }}</strong> per month?
				</p>
			</div>
		</div>
		<div class="lt-question-content">
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-10" style="margin-bottom: 0">
						<div class="field fit">
							<p-radio-button input-id="affordability_true" v-model="can_afford_insurance" :value="true" />
						</div>
						<div class="field">
							<label for="affordability_true" class="radio-label"
								>Yes: The lead can afford to pay the amount above</label
							>
						</div>
					</div>
					<div class="controls gap-10">
						<div class="field fit">
							<p-radio-button input-id="affordability_false" v-model="can_afford_insurance" :value="false" />
						</div>
						<div class="field">
							<label for="affordability_false" class="radio-label">
								No: The lead <strong>CANNOT</strong> pay the amount above
							</label>
						</div>
					</div>
					<div v-if="v$.can_afford_insurance.$error" class="validation-error">
						{{ v$.can_afford_insurance.$errors[0].$message }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import get from 'lodash/get';
export default {
	name: 'LTQuestion_Affordability',
	emits: ['update'],
	props: {
		lead: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			affordability_options: [
				{ label: '', value: true },
				{ label: '', value: false },
			],
		};
	},
	computed: {
		budget() {
			let budget = '$200';
			if (get(this.lead, 'data.household_size') > 1) {
				budget = '$250';
			}
			if (get(this.lead, 'data.household_size') > 2) {
				budget = '$300';
			}
			return budget;
		},
		can_afford_insurance: {
			get() {
				return get(this.lead, 'data.can_afford_insurance', null);
			},
			set(new_value) {
				this.$emit('update', {
					data: {
						can_afford_insurance: new_value,
					},
				});
			},
		},
	},
	validations() {
		return {
			can_afford_insurance: {
				required: helpers.withMessage('This question is required', required),
			},
		};
	},
};
</script>

<style lang="less" scoped>
.dashed {
	border: 2px dashed var(--gray-15);
}
</style>
