<template>
	<div class="settings-form">
		<h2>Support Settings</h2>
		<loader :show="loading" />
		<gutter size="20px" />
		<div v-if="$root.sessionStore.isSuperAdmin" class="control-group">
			<div class="inner">
				<label class="control-label req" for="support_am_id">Account Manager ID:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="account.support.account_manager_id"
							:options="admin_users"
							option-group-label="label"
							option-group-children="items"
							option-label="label"
							option-value="value"
							placeholder="Account Manager ID"
							filter
						/>
						<div v-if="v$.account.support.account_manager_id.$error" class="validation-error">
							{{ v$.account.support.account_manager_id.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<div class="controls gap-20">
					<div v-if="!$route.meta.new" class="field">
						<label class="control-label" for="support_status">Support Status:</label>
						<div class="flex flex-column gap-2" style="max-width: 416px">
							<p-dropdown
								id="support_status"
								:disabled="!canEditSupportStatus"
								v-model="account.support.status"
								:options="support_status_options"
								placeholder="Select Support Status"
								option-label="label"
								option-value="value"
							/>
						</div>
					</div>
					<div class="field">
						<label class="control-label" for="support_category">Category:</label>
						<p-input-text id="support_category" v-model="account.support.category" placeholder="Category" />
					</div>
				</div>
			</div>
		</div>

		<p-fieldset v-if="$root.sessionStore.isAdminUser" legend="First Funding">
			<div v-if="hasFunded">
				<p-message :closable="false">
					This account funded on
					<strong>{{ formatDate(new Date(account.support.first_fund_date), 'ddd, MMM DD, YYYY') }}</strong
					>. The account manager at the time of funding was <strong>{{ fundingUser }}</strong>
				</p-message>
			</div>
			<div v-else>
				<p-message> This account has <strong>NOT</strong> funded yet. </p-message>
			</div>
			<template v-if="hasFunded">
				<div>
					<p>Here we want to record the action that lead to the Account funding.</p>
					<div class="control-group">
						<div class="inner">
							<label class="control-label" for="support_am_id">Funding Action:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="account.support.funding_action"
										:options="funding_action_options"
										optionValue="key"
										:disabled="selectedFundingAction && !selectedFundingAction.value.editable"
									>
										<template #value="opt">
											{{ opt.value }}
										</template>
										<template #option="opt" class="flex">
											<div>
												{{ opt.option.key }} <br />
												<div class="sub-value">
													{{ opt.option.value.description }}
												</div>
											</div>
										</template>
									</p-dropdown>

									<div class="sub-value" v-if="selectedFundingAction && !selectedFundingAction.value.editable">
										Certain Funding Actions cannot be modified after they are set. Usually some Auto-set values.
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group" v-if="selectedFundingAction && selectedFundingAction.value.custom_input">
						<div class="inner">
							<label class="control-label" for="support_am_id">Custom Input:</label>
							<div class="controls">
								<div class="field">
									<p-input-text v-model="funding_action_custom" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<gutter size="30px" />

				<div v-if="this.$root.sessionStore.role.id === 'super_admin'">
					<p>Changing who was the first funding manager should be made with considerations.</p>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">First Fund Account Manager:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="account.support.first_fund_manager_id"
										:options="admin_users"
										option-group-label="label"
										option-group-children="items"
										option-label="label"
										option-value="value"
										placeholder="Account Manager ID"
										filter
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</p-fieldset>
		<p-fieldset v-if="$root.sessionStore.isAdminUser" legend="Changing Marketplace">
			<p>
				This change is quite drastic, make sure you are certain you want to make it. If the current account manager
				doesn't have access to the new Marketplace, they won't be able to see the account. The account's ID will be
				changed.
			</p>
			<p-button label="Change Account's Marketplace" @click="show_change_mp_modal = true" />
			<p-dialog
				modal
				v-model:visible="show_change_mp_modal"
				:pt="{
					root: { class: 'w-12 sm:w-12 md:w-6' },
				}"
			>
				<template #header>
					<h2>Change the Marketplace</h2>
				</template>
				<div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req">New Marketplace ID:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="new_marketplace_id"
										:options="mp_options"
										option-label="label"
										option-value="value"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<template #footer>
					<p-button text label="Cancel" @click="resetModal" />
					<p-button :loading="loading" label="Change" @click="makeMPChange" />
				</template>
			</p-dialog>
		</p-fieldset>

		<p-fieldset
			v-if="$root.sessionStore.isAdminUser && account.settings && account.settings.ngl_v3_id"
			legend="Migration"
		>
			<p>
				This account was migrated from V3. It's previous company id was
				<a :href="v3Link(account.settings.ngl_v3_id)" target="_blank">
					<strong>{{ account.settings.ngl_v3_id }}</strong>
				</a>
			</p>
			<gutter size="10px" />
			<div>
				<p class="details">
					Making this request will send test leads through all the imported shippers. The shippers tested are the ones
					that were associated with campaigns. So if a shipper was not already attached to a campaign in V3, then it
					cannot be tested via this method. An Email with the phones and emails of the test leads will be sent to you so
					that you can forward it to the Buyer in order to confirm that they have received the test leads properly.
					<strong> Run this after all shippers are correctly attached to the corresponding campaigns</strong>, see
					section above.
				</p>
				<p-button @click="testShippers" label="Test All Shippers" />

				<gutter size="10px" />
				<p-dialog
					v-model:visible="show_test_modal"
					modal
					:pt="{
						root: { class: 'w-12 sm:w-12 md:w-6' },
					}"
					:dismissable-mask="true"
				>
					<template #header>
						<div class="flex justify-content-start align-items-center" style="gap: 10px">
							<icon type="alert" size="24px" style="color: var(--color-b)" />
							<h2 class="m-0">Tested Shippers</h2>
						</div>
					</template>

					<div>
						<p>From all the campaigns with shippers we have sent a test request.</p>
						<ul class="migration-stats" style="columns: 3; font-size: var(--font-size-sm); color: var(--gray-50)">
							<li class="pb-1">
								Campaigns Found:
								<strong style="color: var(--black)">{{ test_response.campaigns_found }}</strong>
							</li>
							<li class="pb-1">
								Campaigns w/ Shippers:
								<strong style="color: var(--black)" :class="{}">
									{{ test_response.campaigns_w_shippers }}
								</strong>
							</li>
							<li class="pb-1">
								Shippers Found:
								<strong style="color: var(--black)" :class="{}">{{ test_response.shippers_found }}</strong>
							</li>
							<li class="pb-1">
								Shippers Tested:
								<strong style="color: var(--black)">{{ test_response.shippers_tested }}</strong>
							</li>
							<li class="pb-1">
								Shippers w/ Success Response:
								<strong style="color: var(--black)">{{ test_response.shippers_w_success_res }}</strong>
							</li>
						</ul>
					</div>

					<gutter size="20px" />

					<template v-if="test_response.errors.length > 0">
						<strong class="text-red-400">Errors</strong>
						<ul class="list-disc pl-3 mt-3 ml-3">
							<li v-for="error in test_response.errors">{{ error }}</li>
						</ul>
						<gutter size="20px" />
					</template>

					<strong>Shipper Log Ids</strong>
					<p class="sub-value mt-2">These log ids can be used by the Dev team to further Debug</p>
					<highlightjs :code="JSON.stringify(test_response.shipment_logs, null, 2)" />

					<gutter size="20px" />

					<strong>Request Details</strong>
					<p class="sub-value mt-2">
						Below you will find the fake phone and emails used for the shippers we have tested
					</p>
					<highlightjs :code="JSON.stringify(test_response.shipper_test_map, null, 2)" />
				</p-dialog>
			</div>

			<gutter size="20px" />
			<div>
				<p class="details">
					This should be the last step in migration, after all the shippers have been confirmed to work, after all the
					campaigns in V4 have the correct PLs and Shippers attached.
					<strong> This step will pause all campaigns in V3, and transfer the current balance in V3 into V4.</strong>
				</p>
				<p-button @click="migrateBalance" label="Migrate Balance + Pause V3" />
				<p-dialog
					v-model:visible="show_balance_modal"
					modal
					:pt="{
						root: { class: 'w-12 sm:w-12 md:w-6' },
					}"
					:dismissable-mask="true"
				>
					<template #header>
						<div class="flex justify-content-start align-items-center" style="gap: 10px">
							<icon type="alert" size="24px" style="color: var(--color-b)" />
							<h2 class="m-0">Balance Transfer Result</h2>
						</div>
					</template>

					<p class="sub-value">Company ID:</p>
					<p>
						<strong>{{ balance_response.company_id }} (V3)</strong> Balance Transfer to
						<strong>{{ balance_response.account_id }} (v4)</strong>
					</p>
					<p class="sub-value">V3 Balance:</p>
					<p>
						<strong>{{ currency(balance_response.v3_funds) }}</strong>
					</p>
					<p class="sub-value">Transferred to V4:</p>
					<p>
						<strong>{{ currency(balance_response.funds_transferred) }}</strong>
					</p>
					<template v-if="balance_response.errors && balance_response.errors.length > 0">
						<gutter size="20px" />
						<h3 class="text-red-400">Errors</h3>
						<ul class="list-disc pl-3 ml-3">
							<li v-for="error in balance_response.errors">{{ error }}</li>
						</ul>
					</template>
					<strong>Full Response</strong>
					<p class="sub-value mt-2">Below is the results from this request</p>
					<highlightjs :code="JSON.stringify(balance_response, null, 2)" />
				</p-dialog>
			</div>
		</p-fieldset>
		<div class="actions flex">
			<p-button label="Save" icon="pi pi-check" @click="updateAccount()" />
		</div>
	</div>
</template>

<script lang="ts">
import '@/lib/Utils/isDst';
import pChips from 'primevue/chips';
import { support_status_options } from '@/lib/Options';
import { formatDate, currency } from '@/lib/Filters';
import {
	getAccountSupportSettings,
	getMarketplacesAsOptions,
	getInternalUsersAsOptions,
	getSettings,
	updateAccount,
	changeAccountMP,
	migrateBalance,
	testMigratedShippers,
} from '@GQL';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { find, merge } from 'lodash-es';

export default {
	name: 'SupportSettings',
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	components: {
		pChips,
	},
	data() {
		return {
			loading: false,
			support_status_options,
			account: {
				support: {
					account_manager_id: null,
					status: 'new',
					category: '',
					first_fund_manager_id: '',
					first_fund_date: null,
					funding_action: null,
				},
			},
			funding_action_custom: '',
			show_change_mp_modal: false,
			admin_users: [],
			mp_options: [],
			new_marketplace_id: '',
			funding_action_options: {},
			test_response: {},
			show_test_modal: false,
			balance_response: {},
			show_balance_modal: false,
		};
	},
	computed: {
		canEditSupportStatus() {
			return this.$root.sessionStore.hasPermission('Account.Support.Status');
		},
		accountId() {
			return (
				this.$route.params.account_id || this.$route.params.parent_account_id || this.$root.sessionStore.account.id
			);
		},
		selectedFundingAction() {
			const clean_key = this.account.support.funding_action;
			const funding_action = find(this.funding_action_options, { key: clean_key });
			return funding_action;
		},
		fundingUser() {
			const user_id = this.account.support.first_fund_manager_id;
			if (user_id) {
				if (this.admin_users.length === 1) {
					const user = find(this.admin_users[0].items, { value: user_id });
					if (user) {
						return user.label;
					}
				}
			}

			return 'Missing User...';
		},
		hasFunded() {
			return this.account.support.first_fund_date;
		},
	},
	validations() {
		return {
			account: {
				support: {
					account_manager_id: {
						required,
					},
				},
			},
		};
	},
	async mounted() {
		this.loading = true;
		try {
			await this.getFundingActionOptions();
			await this.fetchAccountSettings();
			this.admin_users = await getInternalUsersAsOptions('sales');
			this.mp_options = (await getMarketplacesAsOptions()).filter((opt) => {
				return opt.value !== this.accountId.slice(0, 3);
			});
		} finally {
			this.loading = false;
		}
	},
	methods: {
		formatDate,
		currency,
		v3Link(company_id) {
			const is_prod =
				window.location.origin.indexOf('localhost') === -1 && window.location.origin.indexOf('stage') === -1;
			const base = is_prod ? 'https://admin.nextgenleads.com' : 'https://admin.stage.nextgenleads.com';
			return `${base}/buyers/${company_id}`;
		},
		async testShippers() {
			this.loading = true;
			try {
				const res = await testMigratedShippers(this.accountId);
				this.test_response = res;
				this.show_test_modal = true;
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Error encountered when testing the migrated Shippers',
					life: 8000,
				});
			} finally {
				this.loading = false;
			}
		},
		async migrateBalance() {
			this.loading = true;
			try {
				const res = await migrateBalance(this.accountId);
				this.balance_response = res;
				this.show_balance_modal = true;
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Error encountered when trying to migrate balance from V3',
					life: 8000,
				});
			} finally {
				this.loading = false;
			}
		},
		async getFundingActionOptions() {
			const settings_result = await getSettings(['funding_action_options'], '$NG', true);
			const original_map = settings_result.settings.funding_action_options;
			this.funding_action_options = Object.keys(original_map).map((key) => {
				return {
					key: key,
					value: original_map[key],
				};
			});
		},
		async makeMPChange() {
			this.loading = true;
			try {
				const new_account_id = await changeAccountMP(this.accountId, this.new_marketplace_id);
				this.$toast.add({
					severity: 'success',
					summary: `The account's marketplace has been changed`,
					life: 7000,
				});
				// navigate to the new account id
				// replace this.accountId from the current path, replace current mpid with
				this.show_change_mp_modal = false;
				this.$router.push({
					path: `/marketplaces/manage/${this.new_marketplace_id}/accounts/manage/${new_account_id}/reports/account-activity`,
				});
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'An error occurred when trying to change the marketplace id',
					life: 7000,
				});
			} finally {
				this.loading = false;
			}
		},
		resetModal() {
			this.show_change_mp_modal = false;
			this.new_marketplace_id = '';
		},
		async fetchAccountSettings(refresh?: boolean) {
			try {
				const result = await getAccountSupportSettings(this.accountId, refresh);
				// funding action may need processing in the case where the option has custom logic....
				// check the funding_action, make it into one of the keys...
				const split_funding_action = result.account.support.funding_action
					? result.account.support.funding_action.split(':')
					: '';
				const clean_funding_action = result.account.support.funding_action ? split_funding_action[0] : '';
				if (split_funding_action.length > 1) {
					this.funding_action_custom = split_funding_action[1].trim();
				}

				this.account = merge({}, result.account, {
					support: {
						funding_action: clean_funding_action,
					},
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error getting Account Settings',
					life: 3000,
				});
			}
		},
		async updateAccount() {
			this.loading = true;
			try {
				const is_valid = await this.v$.$validate();

				if (is_valid) {
					let update_data = this.account;
					// if the funding_action has custom input, we need to join the custom bit
					if (this.selectedFundingAction) {
						const funding_action = this.selectedFundingAction.value.custom_input
							? this.account.support.funding_action + ': ' + this.funding_action_custom
							: this.account.support.funding_action;
						update_data = merge(this.account, { support: { funding_action } });
					}

					// delete the first fund date, since that should not update
					delete update_data.support.first_fund_date;

					const account_result = await updateAccount(this.accountId, update_data);
					if (account_result.updateAccount.id) {
						this.$toast.add({
							severity: 'success',
							summary: 'Successfully saved account settings',
							life: 3000,
						});
						await this.fetchAccountSettings(true);
					} else {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to save settings',
							life: 3000,
						});
					}
				}
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.p-fieldset .p-fieldset-content p.details {
	font-size: var(--font-size-sm);
	color: var(--gray-60);
	strong {
		color: var(--black);
	}
}
</style>
