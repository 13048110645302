<template>
	<p-dialog modal v-model:visible="showStateModal" :close-on-escape="false" :closable="false" style="max-width: 750px">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="map-marker-alert-outline" size="24px" style="color: var(--red-400)" />
				<strong>No Location Set</strong>
			</div>
		</template>
		<p>Before continuing please let us know where your business operates</p>
		<gutter size="10px" />
		<div class="control-group">
			<div class="inner">
				<div class="controls gap-20">
					<div class="field">
						<label class="control-label req">Where is your business located?</label>
						<p-dropdown
							:options="state_options"
							v-model="state"
							filter
							option-label="label"
							option-value="value"
							placeholder="Select State"
						/>
						<div v-if="v$.state.$error" class="validation-error">
							{{ v$.state.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex align-items-center justify-content-between">
				<div class="form-error">
					<div v-if="v$.state.$error" class="validation-error">
						{{ v$.state.$errors[0].$message }}
					</div>
				</div>
				<div class="submit">
					<p-button label="Continue" icon="pi pi-check" @click="updateAccountState()" />
				</div>
			</div>
		</template>
	</p-dialog>
</template>

<script lang="ts">
import pDialog from 'primevue/dialog';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { updateAccount } from '@GQL';
import STATES from '@/lib/Data/states.json';

export default {
	name: 'AccountStateModal',
	components: {
		pDialog,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			state_options: [...STATES],
			state: null,
		};
	},
	computed: {
		showStateModal() {
			// Terms accepted, is primary account user, and has no account.contact.state
			return (
				this.$root.sessionStore.user.terms &&
				this.$root.sessionStore.isAccountUser &&
				this.isPrimaryUser &&
				!this.$root.sessionStore.account.contact.state
			);
		},
		isPrimaryUser() {
			return this.$root.sessionStore.account.primary_user_id === this.$root.sessionStore.user.id;
		},
	},
	methods: {
		async updateAccountState() {
			try {
				updateAccount(this.$root.sessionStore.account.id, {
					contact: {
						state: this.state,
					},
				});
				this.$root.sessionStore.account.contact.state = this.state;
				this.$toast.add({
					severity: 'success',
					summary: 'Updated State on User Account',
					life: 3000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to Update State on User Account',
					detail: 'Please reach out to support@nextgenleads.com for help',
				});
			}
		},
	},
	validations() {
		return {
			state: {
				required: helpers.withMessage('Please let us know what state you operate in before continuing', (v) => {
					return v === true;
				}),
			},
		};
	},
};
</script>

<style lang="less" scoped></style>
