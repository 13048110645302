<template>
	<div id="account-form">
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="name">Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text id="name" v-model="account.name" placeholder="A unique human-readable name" />
						<div v-if="v$.account.name.$error" class="validation-error">
							{{ v$.account.name.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isAdmin" class="control-group">
			<div class="inner">
				<label class="control-label">Display Name:</label>
				<div class="controls">
					<div class="field">
						<div class="field">
							<p-input-text
								id="display_name"
								v-model="account.display_name"
								placeholder="Used for auction results in the LTA workstation"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isAdmin" class="control-group">
			<div class="inner">
				<div class="controls">
					<div class="field fit">
						<p-input-switch id="is-parent" v-model="account.is_parent" />
					</div>
					<div class="field">
						<label class="control-label" for="is-parent">This is a Parent Account</label>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isAdmin" class="control-group">
			<div class="inner">
				<label class="control-label" for="parent_company">Parent Company ID:</label>
				<div class="controls">
					<div class="field">
						<p-input-text id="parent_company" v-model="account.parent_account_id" placeholder="Parent Account ID" />
					</div>
				</div>
			</div>
		</div>
		<p-fieldset legend="Account Contact">
			<div class="control-group" v-if="!isCreate">
				<!--        hiding these fields as we will copy the user form data-->
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label req" for="account_phone">Phone Number:</label>
							<p-input-mask
								id="account_phone"
								v-model="account.contact.phone"
								mask="(999) 999-9999"
								placeholder="(999) 999-9999"
								:unmask="true"
							/>
							<div v-if="v$.account.contact.phone.$error" class="validation-error">
								{{ v$.account.contact.phone.$errors[0].$message }}
							</div>
						</div>
						<div class="field">
							<label class="control-label req" for="account_email">Email:</label>
							<p-input-text id="account_email" v-model="account.contact.email" placeholder="Email for the Account" />
							<div v-if="v$.account.contact.email.$error" class="validation-error">
								{{ v$.account.contact.email.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="field">
						<label class="control-label req" for="account_state">State:</label>
						<p-dropdown
							id="account_state"
							filter
							v-model="account.contact.state"
							:options="state_options"
							optionLabel="label"
							optionValue="value"
							placeholder="Select State"
							class="state-select w-full"
						/>
					</div>
				</div>
			</div>
		</p-fieldset>
		<p-fieldset v-if="!isParentAccountAdmin" legend="Support">
			<!-- Primary Vertical needed for Parent Accounts since they are internally created -->
			<div v-if="account.is_parent && isCreate" class="control-group">
				<div class="inner">
					<label class="control-label req">Primary Vertical:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								id="primary_vertical"
								v-model="primary_vertical"
								placeholder="Primary Vertical"
								:options="vertical_options"
								option-label="label"
								option-value="value"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field">
							<label class="control-label req" for="support_am_id">Account Manager ID:</label>
							<p-dropdown
								filter
								id="support_am_id"
								v-model="account.support.account_manager_id"
								placeholder="Account Manager ID"
								:options="account_managers"
								option-group-label="label"
								option-group-children="items"
								option-label="label"
								option-value="value"
							/>
							<div v-if="v$.account.support.account_manager_id.$error" class="validation-error">
								{{ v$.account.support.account_manager_id.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div v-if="!$route.meta.new" class="field">
							<label class="control-label" for="support_status">Support Status:</label>
							<div class="flex flex-column gap-2" style="max-width: 416px">
								<p-dropdown
									id="support_status"
									v-model="account.support.status"
									:options="support_status_options"
									placeholder="Select Support Status"
									option-label="label"
									option-value="value"
								/>
							</div>
						</div>
						<div class="field">
							<label class="control-label" for="support_category">Category:</label>
							<p-input-text id="support_category" v-model="account.support.category" placeholder="Category" />
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<p-fieldset legend="Settings">
			<div class="control-group">
				<div class="inner">
					<label class="control-label req" for="timezone">Timezone:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								id="timezone"
								v-model="account.settings.timezone"
								:options="timezoneOptions"
								option-label="label"
								option-value="value"
								placeholder="Select Timezone"
							/>
							<div v-if="v$.account.settings.timezone.$error" class="validation-error">
								{{ v$.account.settings.timezone.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label" for="notifications">Notifications (Email):</label>
							<p-chips add-on-blur id="notifications" v-model="account.settings.notifications" separator="," />
							<div class="sub-value">Use Enter or comma to separate email addresses</div>
						</div>
						<div class="field">
							<label class="control-label" for="notifications_bcc">Notifications BCC:</label>
							<p-chips add-on-blur id="notifications_bcc" v-model="account.settings.notifications_bcc" separator="," />
							<div class="sub-value">Use Enter or comma to separate email addresses</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="isAdmin" class="control-group">
				<div class="inner">
					<label class="control-label" for="tags">Tags:</label>
					<div class="controls">
						<div class="field">
							<p-chips add-on-blur id="tags" v-model="account.tags" separator="," />
							<div class="sub-value">Use Enter or comma to separate tags</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="isAdmin" class="control-group">
				<div class="inner">
					<label class="control-label req" for="status">Status:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								id="status"
								v-model="account.status"
								:options="account_status_options"
								placeholder="Select Status"
								option-label="label"
								option-value="value"
							/>
						</div>
					</div>
				</div>
				<div class="inner" v-if="account.status === 'away'">
					<label class="control-label" for="status">Date Of Return:</label>
					<div class="controls">
						<div class="field">
							<p-calendar v-model="date_of_return" date-format="D, M d, yy" :manual-input="false" />
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<template v-if="isCreate">
			<p-fieldset legend="Account User">
				<user-form ref="user_form" :title="'Primary User'" :show-actions="false" />
			</p-fieldset>
		</template>
		<div class="flex justify-content-between actions">
			<p-button text label="Cancel" @click="$router.back()" />
			<p-button icon="pi pi-check" label="Save" @click="save" :loading="saving"></p-button>
		</div>
	</div>
</template>

<script lang="ts">
import '@/lib/Utils/isDst';
import { cloneDeep, sortBy } from 'lodash-es';
import pChips from 'primevue/chips';
import pInputMask from 'primevue/inputmask';
import { required, requiredIf, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useSessionStore } from '@/stores/session';
import { useAppStore } from '@/stores/app';
import STATES from '@/lib/Data/states.json';
import TIMEZONES from '@/lib/Data/timezones.json';
import userForm from '@/views/Users/Record/Form.vue';
import { support_status_options, account_status_options, vertical_options } from '@/lib/Options';
import { getAccountById, createAccount, updateAccount, getUsersAsOptions, getRoundRobinAccountManager } from '@GQL';
import log from '@/lib/Log';
import pCalendar from 'primevue/calendar';

export default {
	name: 'CreateAccount',
	components: {
		pChips,
		pInputMask,
		userForm,
		pCalendar,
	},
	props: {
		parentAccountId: {
			type: String,
			default: null,
		},
		isParent: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		return {
			appStore: useAppStore(),
			sessionStore: useSessionStore(),
			marketplaceStore: useMarketplaceStore(),
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			account_status_options,
			support_status_options,
			vertical_options,
			primary_vertical: 'health_insurance',
			state_options: [...STATES],
			account_managers: [],
			date_of_return: new Date(),
			account: {
				id: null,
				mpid: this.$route.params.mpid ? this.$route.params.mpid : this.appStore.mpid,
				name: '', // FORM
				display_name: '', // FORM
				parent_account_id: this.parentAccountId || null, // may be null
				group_ids: [], // dont show in form for now
				is_parent: this.isParent, // do not show in form for now
				support: {
					account_manager_id: '',
					status: 'new',
					category: '',
					date_of_return: null,
				},
				contact: {
					phone: '',
					email: '',
					state: '',
				},
				settings: {
					timezone: null,
					notifications: [],
					notifications_bcc: [],
					notification_bcc_opt_in: [], // do not show in form
					pause_pending_campaigns: true, // do not show
					limit_auction_attempt_participation: {
						// do not show
						enabled: false,
						limit: 1,
					},
					bulk_returns: {
						enabled: false,
						return_rate: 0,
					},
					data_returns: {
						enabled: false,
					},
					usha: {
						agent_id: null,
						enable_validation: false,
					},
				},
				signup_source: {
					/// DO NOT SHOW ANY OF THIS
					source_id: '',
					internal_referral: false,
				},
				signup_data: {
					// DO NOT SHOW ANY OF THIS
				},
				tags: [], // admin only
				internal: false, // admin only
				verified: false, // admin only
				primary_user_id: null, // user drop down, only users of same account...
				status: 'active',
				created_by: this.sessionStore.user.id,
				created_at: new Date(),
			},
			parent_account: null,
			home: { label: 'Home', route: '/' },
			items: [{ label: 'Accounts', route: `/accounts` }],
			saving: false,
		};
	},
	computed: {
		isCreate() {
			return this.$route.meta.new;
		},
		mpid() {
			return this.$route.params.mpid ? this.$route.params.mpid : this.appStore.mpid;
		},
		timezoneOptions() {
			const is_dst = new Date().isDst();

			const timezones = TIMEZONES.map((tz) => {
				const offset = is_dst ? tz.offset_dst : tz.offset;
				return {
					label: `${tz.label} (${offset})`,
					value: tz.id,
					offset,
				};
			});

			return sortBy(timezones, (tz) => {
				return parseInt(tz.offset);
			});
		},
		isAdmin() {
			if (this.appStore.isAdminApp && this.$route.params.mpid) {
				// this means we check admin persions from the marketplace
				return this.marketplaceStore.isAdminUser;
			} else {
				return this.sessionStore.isAdminUser;
			}
		},
		isParentAccountAdmin() {
			return this.$root.sessionStore.user.role_id[this.mpid] === 'parent_account_admin';
		},
	},
	validations() {
		return {
			primary_vertical: {
				requiredIf: helpers.withMessage(
					'A primary vertical should be set if this is a parent account.',
					requiredIf(this.account.is_parent)
				),
			},
			account: {
				name: {
					required: helpers.withMessage('An Account Name is required.', required),
					restricted_chars: helpers.withMessage(
						'Some special characters are not allowed for account name, please use a different name.',
						helpers.regex(/^[^*|\\"\[\]<>{}=#;@]*$/)
					),
				},
				contact: {
					phone: {
						required: helpers.withMessage('Account Contact Phone Number is required.', required),
					},
					email: {
						required: helpers.withMessage('Account Contact Email is required.', required),
					},
					state: {
						required: helpers.withMessage('Account Contact State is required.', required),
					},
				},
				support: {
					account_manager_id: {
						required: helpers.withMessage('Account Manager is required.', required),
					},
				},
				settings: {
					timezone: {
						required: helpers.withMessage('Account Timezone is required.', required),
					},
				},
			},
		};
	},
	async created() {
		this.account_managers = await getUsersAsOptions([
			[`account_id IS NULL AND settings->>'team' = 'sales' AND status = 'active'`],
		]);
	},
	async mounted() {
		// If edit, pull existing account data
		if (!this.$route.meta.new) {
			this.account = await getAccountById(this.$route.params.account_id, true);
			if (this.account.status === 'away') {
				this.date_of_return = new Date(this.account.support.date_of_return);
			}
		}

		if (this.parentAccountId) {
			await this.prepareChildFields();
		}

		// If this is an AM use their own ID as Account Manager
		if (this.isCreate) {
			// If it is a parent account, set primary vertical/AM since Support is hidden
			if (this.isParentAccountAdmin) {
				// Set Primary Vertical to the Parent Account's else keep default of health_insurance
				if (this.$root.sessionStore.account.signup_data.primary_vertical) {
					this.primary_vertical = this.$root.sessionStore.account.signup_data.primary_vertical;
				}
				// Set to the same AM as the parent account
				this.account.support.account_manager_id = this.$root.sessionStore.account.support.account_manager.id;
				// If no AM set or AM is Customer Support, use round robin
				if (
					!this.account.support?.account_manager_id ||
					this.account.support?.account_manager_id === '' ||
					this.account.support?.account_manager_id === '01HN3JKG5XAEQN7KS0EQZDBQ4M'
				) {
					const round_robin_am = await getRoundRobinAccountManager(this.mpid, this.primary_vertical);
					this.account.support.account_manager_id = round_robin_am;
				}
			} else if (this.$root.sessionStore.user.role_id['$NG'] === 'account_manager') {
				// default to the AM if the current user is an AM
				this.account.support.account_manager_id = this.$root.sessionStore.user.id;
			} else {
				// otherwise get from round robin
				const round_robin_am = await getRoundRobinAccountManager(this.mpid, this.primary_vertical);
				this.account.support.account_manager_id = round_robin_am;
			}
		}
	},
	methods: {
		async prepareChildFields() {
			// get the parent account merge in some sign up data
			try {
				this.parent_account = await getAccountById(this.parentAccountId);
				// for now just merge in signup source data and support id
				if (this.parent_account && 'signup_source' in this.parent_account) {
					this.account.signup_source = cloneDeep(this.parent_account.signup_source);
				}
			} catch (err) {
				this.$toast.add({
					summary: 'Unable to auto-load parent fields but account can still be created',
					severity: 'info',
					ttl: 5000,
				});
			}
		},
		async save() {
			this.saving = true;
			// if the status is away, make sure to copy over the date_of_return into support.date_of_return
			if (this.account.status === 'away') {
				this.account.support.date_of_return = this.date_of_return;
			} else {
				delete this.account.support.date_of_return;
			}

			// Insert a new account
			if (this.isCreate) {
				// add the user information to the account, contact.phone and contact.email
				const new_user = cloneDeep(await this.$refs.user_form.user_fields());

				this.account.contact.email = new_user.email;
				this.account.contact.phone = new_user.phone;
				const is_valid = await this.v$.$validate();

				// Add Primary Vertical Info if this is a Parent Account
				if (this.account.is_parent) {
					this.account.signup_data.primary_vertical = this.primary_vertical;
				}

				// Create new account
				const new_account = cloneDeep(this.account);

				if (is_valid) {
					try {
						let result = await createAccount({
							account: new_account,
							user: new_user,
						});
						log.trace('create account', result);
						if (result) {
							this.$toast.add({
								severity: 'success',
								summary: 'Successfully Created Account',
								life: 3000,
							});
							this.$router.back();
						}
					} catch (err) {
						log.error('create account err', err);
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to create Account',
						});
					} finally {
						this.saving = false;
					}
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Validation Error',
						life: 6000,
					});
					log.trace(this.v$);
					this.saving = false;
				}
			} else {
				const is_valid = await this.v$.$validate();
				// Update Account
				if (is_valid) {
					try {
						let result = await updateAccount(this.$route.params.account_id, this.account);
						log.trace('Update account:', result);
						if (result) {
							this.$toast.add({
								severity: 'success',
								summary: 'Successfully Updated Account',
								life: 3000,
							});

							this.$router.back();
						}
					} catch (err) {
						log.error('Account update err', err);
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to update Account',
							life: 3000,
						});
					} finally {
						this.saving = false;
					}
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Validation Error',
						life: 6000,
					});
					log.trace(this.v$);
					this.saving = false;
				}
			}
		},
	},
};
</script>

<style scoped lang="less">
.actions {
	padding-top: 20px;
}
</style>
