import AccountManagerWorkstationWrapper from '@/views/AccountManagerWorkstation/AccountManagerWorkstationWrapper.vue';
import mapNestedRoutes from '@/lib/Utils/mapNestedRoutes';
import PendingFollowUpPage from '@/views/AccountManagerWorkstation/PendingFollowUpsPage.vue';
import ExistingAccountsPage from '@/views/AccountManagerWorkstation/ExistingAccountsPage.vue';
import RecentSignUpsPage from '@/views/AccountManagerWorkstation/RecentSignUpsPage.vue';
import AMWAdminOverviewPage from '@/views/AccountManagerWorkstation/AdminOverviewPage.vue';
import AMWOverviewWrapper from '@/views/AccountManagerWorkstation/AMWOverviewWrapper.vue';
import DormantAccountsPage from '@/views/AccountManagerWorkstation/DormantAccountsPage.vue';

export default [
	{
		path: 'amw',
		name: 'AccountManagerWorkstationOwn',
		component: AccountManagerWorkstationWrapper,
		children: mapNestedRoutes(
			[
				{
					path: '',
					redirect: () => {
						return {
							path: '/amw/pending-follow-ups',
						};
					},
				},
				{
					path: 'pending-follow-ups',
					name: 'PendingFollowUps',
					component: PendingFollowUpPage,
					meta: {
						title: 'Pending Follow-Ups',
						permissions: 'admin_am_reports',
					},
				},
				{
					path: 'existing-accounts',
					name: 'ExistingAccountsPage',
					component: ExistingAccountsPage,
					meta: {
						title: 'Existing Accounts',
						permissions: 'admin_am_reports',
					},
				},
				{
					path: 'recent-signups',
					name: 'RecentSignUpsPage',
					component: RecentSignUpsPage,
					meta: {
						title: 'Recent Sign-Ups',
						permissions: 'admin_am_reports',
					},
				},
				{
					path: 'dormant-accounts',
					name: 'DormantAccounts',
					component: DormantAccountsPage,
					meta: {
						title: 'Dormant Accounts',
						permissions: 'admin_am_reports',
					},
				},
			],
			'AccountManagerWorkstation:',
			{}
		),
	},
	{
		path: 'amw-manager/:account_manager_id',
		name: 'AccountManagerWorkstation',
		component: AccountManagerWorkstationWrapper,
		children: mapNestedRoutes(
			[
				{
					path: '',
					redirect: () => {
						return {
							path: '/amw/pending-follow-ups',
						};
					},
				},
				{
					path: 'pending-follow-ups',
					name: 'PendingFollowUps',
					component: PendingFollowUpPage,
					meta: {
						title: 'Pending Follow-Ups',
						permissions: 'admin_am_reports',
					},
				},
				{
					path: 'existing-accounts',
					name: 'ExistingAccountsPage',
					component: ExistingAccountsPage,
					meta: {
						title: 'Existing Accounts',
						permissions: 'admin_am_reports',
					},
				},
				{
					path: 'recent-signups',
					name: 'RecentSignUpsPage',
					component: RecentSignUpsPage,
					meta: {
						title: 'Recent Sign-Ups',
						permissions: 'admin_am_reports',
					},
				},
				{
					path: 'dormant-accounts',
					name: 'DormantAccounts',
					component: DormantAccountsPage,
					meta: {
						title: 'Dormant Accounts',
						permissions: 'admin_am_reports',
					},
				},
			],
			'AccountManagerWorkstationManager:',
			{}
		),
	},
	{
		path: 'reports/amw-overview',
		name: 'AMWAdminOverviewReport',
		component: AMWAdminOverviewPage,
		meta: {
			title: 'AMW Overview Report',
			permissions: 'admin_am_reports',
		},
	},
	{
		path: 'reports/amw',
		name: 'AMW',
		component: AMWOverviewWrapper,
		children: mapNestedRoutes(
			[
				{
					path: '',
					redirect: () => {
						return {
							path: 'reports/amw/overview',
						};
					},
				},
				{
					path: 'overview',
					name: 'Overview',
					component: AMWAdminOverviewPage,
					meta: {
						title: 'AMW Overview',
						permissions: 'admin_am_reports',
					},
				},
				{
					path: 'dormant-accounts',
					name: 'DormantAccounts',
					component: DormantAccountsPage,
					meta: {
						title: 'Dormant Accounts',
						permissions: 'admin_am_reports',
					},
				},
			],
			'AMW:',
			{}
		),
	},
];
