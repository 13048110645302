<template>
	<div>
		<p-dialog modal v-model:visible="show_modal">
			<loader :show="loading" />
			<template #header>
				<div class="flex align-items-center" style="gap: 10px">
					<icon type="alert" size="24px" />Creating a copy from: <strong>{{ campaign_name }}</strong>
				</div>
			</template>
			<p>Select the Child accounts that should have get a copy of the template</p>

			<div class="control-group">
				<div class="inner">
					<label class="control-label">Child Accounts:</label>
					<div class="controls">
						<div class="field l">
							<p-multi-select
								v-model="account_ids"
								:options="child_account_options"
								option-value="value"
								option-label="label"
								class="w-full"
								filter
								:filterFields="['label', 'value']"
							>
								<template #option="opt" class="flex">
									<div>
										{{ opt.option.label }} <br />
										<div class="sub-value">{{ opt.option.value }} - {{ opt.option.primary_user }}</div>
									</div>
								</template>
							</p-multi-select>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<p-button label="Cancel" text @click="show_modal = false" />
				<p-button label="Create Campaigns" icon="pi pi-check" @click="createCampaigns" />
			</template>
		</p-dialog>
	</div>
</template>

<script>
import { getChildAccountsAsOptions, createDuplicatesForChildrenAccounts } from '@GQL';

export default {
	name: 'CopyTemplateToChildAccount',
	data() {
		return {
			show_modal: false,
			loading: false,
			child_account_options: [],
			account_ids: [],
			template_id: '',
			campaign_name: '',
		};
	},
	computed: {
		parentAccountId() {
			return this.$route.params.parent_account_id || this.$root.sessionStore.user.account_id; // for when user is parent
		},
	},
	async mounted() {
		await this.getChildrenOptions();
	},

	methods: {
		openModal(template_id, campaign_name) {
			this.account_ids = [];
			this.campaign_name = campaign_name;
			this.template_id = template_id;

			this.show_modal = true;
		},
		async getChildrenOptions() {
			// using parent_id get the children accounts
			try {
				this.loading = true;
				this.child_account_options = await getChildAccountsAsOptions(this.parentAccountId);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Children Accounts',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		async createCampaigns() {
			this.loading = true;

			try {
				const result = await createDuplicatesForChildrenAccounts({
					template_id: this.template_id,
					child_accounts: this.account_ids,
				});
				if (result) {
					this.show_modal = false;
					this.$toast.add({
						severity: 'success',
						summary: `All campaigns were created for the Children accounts`,
						life: 5000,
					});
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'One or more campaigns were not created',
						life: 6000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more campaigns were not created',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
