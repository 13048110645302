import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';

export async function reportDormantAccounts(
	params: {
		filters: QueryFilters;
		pagination?: { page: number; per_page: number };
	},
	refresh: boolean
) {
	const query = `
        query ReportDormantAccounts($params: JSONObject!) {
            reportDormantAccounts(params: $params)
        }
    `;

	const no_cache = refresh ? ['reportDormantAccounts'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportDormantAccounts;
}
