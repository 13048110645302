<template>
	<p-dialog
		modal
		v-model:visible="show_modal"
		:closable="true"
		:close-on-esc="true"
		:pt="{
			root: { class: 'w-10 sm:w-10 md:w-9 xl:w-6', style: 'max-width: 750px' },
		}"
	>
		<template #header>
			<div class="flex align-items-center" style="gap: 10px; line-height: 1.5">
				<icon type="content-copy" size="24px" />
				<strong>Duplicate Campaign</strong>
			</div>
		</template>
		<p class="text-center">
			Please choose one of the following options for
			<span class="font-bold" style="color: var(--color-b)">{{ campaign.name }}</span>
		</p>
		<template class="type-options">
			<div
				class="type-option shadow-2 cursor-pointer"
				v-for="type in types"
				:key="type.id"
				@click.prevent="duplicateCampaign(type.id)"
			>
				<icon :type="type.icon" size="24px" />
				<strong>{{ type.name }}</strong>
				<p class="description">{{ type.description }}</p>
			</div>
		</template>
	</p-dialog>
</template>

<script lang="ts">
export default {
	name: 'DuplicateCampaignModal',
	props: {
		campaign: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			show_modal: false,
			selected_type: 'full',
			types: [
				{
					id: 'targeting',
					icon: 'target',
					name: 'Copy Targeting Only',
					description: 'Create a copy of the targeting on this campaign but choose the rest of the settings.',
				},
				{
					id: 'full',
					icon: 'expand-all-outline',
					name: 'Copy Everything',
					description: 'Create a full copy of this campaign with vertical, product, targeting, and all settings.',
				},
			],
		};
	},
	methods: {
		open() {
			this.show_modal = true;
		},
		selectType(type) {
			this.selected_type = type;
		},
		duplicateCampaign(type) {
			this.show_modal = false;
			this.$router.push({
				path: `${this.$route.path}/new`,
				query: {
					duplicate: this.campaign.id,
					type: type,
				},
			});
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';
.type-options {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 1rem 0;
}
.type-option {
	max-width: 280px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 1rem;
	border: 1px solid var(--gray-50);
	border-radius: 3px;
	.description {
		font-size: var(--font-size-sm);
	}
}
</style>
