import { $GQL } from '@GQL/index';
import { orderBy } from 'lodash-es';

export async function getChildAccountsAsOptions(parent_account_id: string, no_cache?: string[]) {
	const query = `
    query GetChildAccountsAsOptions($parent_account_id: ID!) {
        ancestorAccountsList(parent_account_id: $parent_account_id) {
          id
          name
          primary_user {
            first_name
            last_name
          }
        }
      }`;

	const result = (
		await $GQL.request(query, {
			parent_account_id,
			no_cache,
		})
	).ancestorAccountsList;

	const as_options = result.map((row) => {
		return {
			value: row.id,
			label: row.name,
			primary_user: row.primary_user.first_name + ' ' + row.primary_user.last_name,
		};
	});

	return orderBy(as_options, ['label', 'primary_user'], ['asc', 'asc']);
}
