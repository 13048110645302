<template>
	<div id="account-performance-report" class="view-content">
		<line-loader :show="loading" />
		<div class="page-heading">
			<h1>Account Activity</h1>
		</div>
		<div class="page-content">
			<row>
				<column>
					<div class="card padded">
						<row>
							<column>
								<div class="flex align-items-center justify-content-end w-full activity-quick-filters">
									<quick-filter v-model="filters.product" value="data" allow-multiple>Data</quick-filter>
									<quick-filter v-model="filters.product" value="call" allow-multiple>Calls</quick-filter>
									<quick-filter v-model="filters.product" value="live_transfer" allow-multiple
										>Live Transfers</quick-filter
									>
									<quick-filter v-model="filters.product" value="ad" allow-multiple>Ads</quick-filter>
								</div>
							</column>
						</row>
						<gutter size="10px" />
						<row>
							<column>
								<metric label="Account ID" :value="account.id" />
							</column>
							<gutter size="20px" />
							<column>
								<metric label="Balance" :value="currency(account.balance.actual)" />
							</column>
							<gutter size="20px" />
							<column>
								<metric
									label="Last Purchase"
									:value="account.lastPurchase ? capitalize(timeAgo(account.lastPurchase)) : 'No Purchases'"
								>
								</metric>
							</column>
							<gutter size="20px" />
							<column>
								<metric
									label="Total Spend"
									:previous-value="reportData.yesterday.total.spend || 0"
									:value="currentMetric('spend')"
									is-currency
								/>
							</column>
							<gutter size="20px" />
							<column>
								<metric
									label="Last Contact"
									:value="account.lastContact ? capitalize(timeAgo(account.lastContact)) : 'No Contact'"
								>
								</metric>
							</column>
						</row>
					</div>
				</column>
			</row>
			<gutter size="20px" />
			<row>
				<div class="column">
					<SpendByDayChart :filter="filters.product" />
				</div>
			</row>
			<gutter size="20px" />
			<row class="flex-column-reverse md:flex-row">
				<column>
					<div v-if="account" id="account-details" class="card padded">
						<div class="card-heading unpadded flex align-items-center gap-0">
							<icon type="information" class="mr-2" size="18px" />
							<h2>Contact Info</h2>
						</div>
						<div class="line-item" v-if="account.primary_user">
							<div class="value">{{ account.primary_user.first_name }} {{ account.primary_user.last_name }}</div>
							<div class="sub-value">
								{{ phoneFormat(account.primary_user.phone) }}
							</div>
							<div class="sub-value" style="color: var(--color-b)">
								{{ account.primary_user.email }}
							</div>
						</div>
						<div class="line-item">
							<div class="label">Time Zone:</div>
							<div class="value">{{ timezone }}</div>
						</div>
					</div>
					<gutter size="20px" />
					<div id="disposition-details" class="card padded">
						<div class="line-item">
							<div class="label">Disposition:</div>
							<div class="value">
								{{ account.support.disposition || 'None' }}
								<a @click="this.$refs.disposition_note_modal.openModal()">
									<icon v-tooltip.top="'Edit Disposition'" type="square-edit-outline" size="16px" />
								</a>
								<ActionModal
									ref="disposition_note_modal"
									note_type="dispo_change"
									@refresh="handleDispositionRefresh"
									:account_id="account.id"
									:account_name="account.name"
									:account_support_status="account.support.status || ''"
								/>
							</div>
						</div>
						<PendingFollowUpComponent
							:account_id="accountId"
							@refreshDisposition="
								(disposition) => {
									this.account.support.disposition = disposition;
								}
							"
						/>
						<div class="line-item mt-3">
							<div class="label">Last Activity:</div>
							<div class="value">
								{{ account.lastActivity ? formatDate(new Date(account.lastActivity), 'MMM D, YYYY') : 'No Activity' }}
								<div v-if="account.lastActivity" class="sub-value font-normal">
									{{ capitalize(timeAgo(account.lastActivity)) }}
								</div>
							</div>
						</div>
						<div v-if="account.status" class="line-item">
							<div class="label">Status:</div>
							<div class="value">
								{{ capitalize(account.status) }}
								<a @click.prevent="updating_status = !updating_status">
									<icon v-tooltip.top="'Edit'" type="square-edit-outline" size="16px" />
								</a>
							</div>
							<div class="label" v-if="account.status === 'away' && account.support.date_of_return">
								Until: {{ formatDate(new Date(account.support.date_of_return), 'MMM D, YYYY') }}
							</div>
						</div>
						<UpdateAccountStatus
							v-if="account.id && updating_status && $root.sessionStore.isAdminUser"
							:account_id="account.id"
							:status="account.status"
							:date_of_return="account.support.date_of_return"
						/>
					</div>
					<gutter size="20px" />
					<div id="support-details" class="card padded">
						<div class="card-heading unpadded flex align-items-center gap-0">
							<icon type="information" class="mr-2" size="18px" />
							<h2>Support Info</h2>
						</div>
						<div v-if="account.support.status && $root.sessionStore.isAdminUser" class="line-item">
							<div class="label">Support Status:</div>
							<div class="value">
								{{ capitalize(account.support.status) }}
							</div>
						</div>
						<div v-if="account.support.account_manager.first_name" class="line-item">
							<div class="label">Account Manager:</div>
							<div class="value">
								{{ account.support.account_manager.first_name }} {{ account.support.account_manager.last_name }}
							</div>
						</div>
					</div>
					<gutter size="20px" />
					<div id="kpi-details" class="card padded">
						<div class="card-heading unpadded flex align-items-center gap-0">
							<icon type="information" class="mr-2" size="18px" />
							<h2>
								KPI Info
								<a @click="$refs.kpi_modal.show_modal = true">
									<icon v-tooltip.top="'Edit KPI Targets'" type="square-edit-outline" size="18px" />
								</a>
								<KPIModal
									ref="kpi_modal"
									:kpi_targets="account.support.kpi_targets"
									:account_id="accountId"
									@update="updateKPITargets"
								/>
							</h2>
							<p-dialog>
								<template #header>
									<div class="flex align-items-center" style="gap: 10px">
										<icon type="target" size="24px" style="color: var(--color-b)" />
										<strong>KPI Targets</strong>
									</div>
								</template>
							</p-dialog>
						</div>
						<div class="line-item">
							<div class="label">Data CPA Target:</div>
							<div class="value">
								<span v-if="account.support.kpi_targets && account.support.kpi_targets.data_cpa > 0">
									{{ currency(account.support.kpi_targets.data_cpa) }}
								</span>
								<span v-else>Not Set</span>
							</div>
						</div>
						<div class="line-item">
							<div class="label">Call CPA Target:</div>
							<div class="value">
								<span v-if="account.support.kpi_targets && account.support.kpi_targets.call_cpa > 0">
									{{ currency(account.support.kpi_targets.call_cpa) }}
								</span>
								<span v-else>Not Set</span>
							</div>
						</div>
						<div class="line-item">
							<div class="label">Live Transfer CPA Target:</div>
							<div class="value">
								<span v-if="account.support.kpi_targets && account.support.kpi_targets.live_transfer_cpa > 0">
									{{ currency(account.support.kpi_targets.live_transfer_cpa) }}
								</span>
								<span v-else>Not Set</span>
							</div>
						</div>
						<div class="line-item">
							<div class="label">Products Sold Target:</div>
							<div class="value">
								{{
									account.support.kpi_targets && account.support.kpi_targets.products_sold
										? account.support.kpi_targets.products_sold
										: 'Not Set'
								}}
							</div>
						</div>
					</div>
					<gutter size="20px" />
					<div v-if="account.signup_data" id="account-details" class="card padded">
						<div class="card-heading unpadded flex align-items-center gap-0">
							<icon type="information" class="mr-2" size="18px" />
							<h2>Sign Up Info</h2>
						</div>
						<div class="line-item">
							<div class="label">Sign Up Source:</div>
							<div class="value">{{ account.signup_source.source_id || 'None' }}</div>
							<div class="sub-value" v-if="account.signup_source.s1">S1: {{ account.signup_source.s1 }}</div>
						</div>
						<div class="line-item">
							<div class="label">Sign Up Date:</div>
							<div class="value">{{ formatDate(new Date(account.created_at), 'MMM D, YYYY') }}</div>
						</div>
						<div class="line-item" v-if="account.signup_data.primary_vertical">
							<div class="label">Primary Vertical:</div>
							<div class="value">{{ capitalize(account.signup_data.primary_vertical) }}</div>
						</div>
						<div class="line-item" v-if="account.signup_data.agency_size">
							<div class="label">Agency Size:</div>
							<div class="value">{{ account.signup_data.agency_size }}</div>
						</div>
						<div class="line-item" v-if="account.signup_data.products">
							<div class="label">Products:</div>
							<div class="value">{{ account.signup_data.products.join(', ') }}</div>
						</div>
						<div class="line-item" v-if="account.signup_data.referred_from">
							<div class="label">Referred From:</div>
							<div class="value">{{ account.signup_data.referred_from }}</div>
						</div>
					</div>
				</column>

				<gutter size="20px" />

				<column :span="6">
					<TabView lazy>
						<TabPanel header="Campaign Activity">
							<div class="campaign-quick-filters">
								<quick-filter v-model="metric_type" value="dod">Day</quick-filter>
								<quick-filter v-model="metric_type" value="wow">Week</quick-filter>
								<quick-filter v-model="metric_type" value="mom">Month</quick-filter>
							</div>
							<gutter size="10px" />
							<p-data-table ref="dt" :loading="loading" :value="campaignsWithPurchases">
								<template #loading>
									<line-loader :show="loading" />
								</template>
								<template #empty>
									<div class="dim">No campaigns have purchased leads</div>
								</template>
								<p-column header="Campaign Name">
									<template #body="row">
										<div class="name">
											<router-link :to="campaignViewLink(row.data.id)">{{ row.data.name }}</router-link>
										</div>
										<div class="sub-value">
											{{ capitalize(row.data.product_targeting) }} - {{ capitalize(row.data.bid_type) }}
										</div>
									</template>
								</p-column>
								<p-column header="Status">
									<template #body="row">
										{{ capitalize(row.data.status) }}
									</template>
								</p-column>
								<p-column header="Vertical" class="column-align-center">
									<template #body="row">
										<vertical-icon :vertical-id="row.data.vertical_id_targeting" size="30px" />
									</template>
								</p-column>
								<p-column header="Purchases" class="column-align-right">
									<template #body="row">
										{{ row.data['purchases_' + campaignPeriod] || 0 }}
										<div
											:class="[
												'sub-value',
												trendClass(
													row.data['purchases_' + campaignPeriod],
													row.data['purchases_' + campaignPeriodCompare]
												),
											]"
										>
											<icon type="calendar-arrow-left" style="vertical-align: text-top; margin-top: 1px" />
											{{ row.data['purchases_' + campaignPeriodCompare] }}
										</div>
									</template>
								</p-column>
								<p-column header="Spend" class="column-align-right">
									<template #body="row">
										{{ currency(row.data['spend_' + campaignPeriod] || 0) }}
										<div
											:class="[
												'sub-value',
												trendClass(row.data['spend_' + campaignPeriod], row.data['spend_' + campaignPeriodCompare]),
											]"
										>
											<icon type="calendar-arrow-left" style="vertical-align: text-top; margin-top: 1px" />
											{{ currency(row.data['spend_' + campaignPeriodCompare]) }}
										</div>
									</template>
								</p-column>
								<p-column header="CPL" class="column-align-right">
									<template #body="row">
										{{ currency(row.data['spend_' + campaignPeriod] / row.data['purchases_' + campaignPeriod] || 0) }}
										<div
											:class="[
												'sub-value',
												trendClass(
													row.data['spend_' + campaignPeriod] / row.data['purchases_' + campaignPeriod] || 0,
													row.data['spend_' + campaignPeriodCompare] / row.data['purchases_' + campaignPeriodCompare] ||
														0
												),
											]"
										>
											<icon type="calendar-arrow-left" style="vertical-align: text-top; margin-top: 1px" />
											{{
												currency(
													row.data['spend_' + campaignPeriodCompare] / row.data['purchases_' + campaignPeriodCompare] ||
														0
												)
											}}
										</div>
									</template>
								</p-column>
							</p-data-table>
						</TabPanel>
						<TabPanel header="Notes">
							<NoteList ref="note_list" :account_id="accountId" />
						</TabPanel>
						<TabPanel header="Conversions By Source">
							<ConversionsBySource />
						</TabPanel>
						<TabPanel header="Campaign Change History">
							<p-dialog modal v-model:visible="show_campaign_change_modal" :style="{ width: '50vw' }">
								<template #header>
									<div class="flex align-items-center" style="gap: 10px">
										<icon type="alert" size="24px" style="color: var(--color-b)" />Changes for Campaign:
										<strong>{{ selected_campaign_change.campaign_name }}</strong>
									</div>
								</template>
								<code-editor :enabled="false" v-model.parse="selected_campaign_change.snapshot" />
							</p-dialog>
							<div class="padded">
								<div v-if="campaign_activity.length === 0">
									<div class="sub-value">There are no campaign changes.</div>
								</div>
								<div id="note-campaign-changes-container" v-else>
									<div
										v-for="campaign_change in campaign_activity"
										@click="openCampaignChangeModal(campaign_change)"
										class="note"
										:class="{ clickable: !campaign_change.change_by }"
									>
										<div>
											<!-- we know this is a status change-->
											<template v-if="campaign_change.change_by">
												Campaign status for "<i>{{ campaign_change.campaign_name }}</i
												>" was set to <strong>{{ capitalize(campaign_change.status_change) }}</strong>
											</template>
											<template v-else>
												Campaign <strong>data</strong> change of "<i>{{ campaign_change.campaign_name }}</i
												>" created <strong>Version {{ campaign_change.version }}</strong
												>. Click to see raw data.
											</template>
											<gutter size="5px" />
											<div class="note-details">
												<span v-if="campaign_change.change_by"> Changed By {{ campaign_change.change_by }} - </span>
												<span v-else-if="campaign_change.modified_by_name">
													Changed By {{ campaign_change.modified_by_name }} -
												</span>
												{{ formatDate(new Date(campaign_change.timestamp), 'ddd, MMM D, YYYY hh:mm A') }}
											</div>
										</div>
										<gutter size="5px" />
									</div>
								</div>
							</div>
						</TabPanel>
					</TabView>
					<gutter size="20px" />
					<row>
						<column>
							<div class="card padded">
								<div class="card-heading unpadded flex align-items-center justify-content-between">
									<div class="flex align-items-center">
										<icon type="sale" class="mr-2" size="18px" />
										<h2>Attached Offers</h2>
									</div>
								</div>
								<AttachOfferModal :account_id="accountId" />
							</div>
						</column>
					</row>
				</column>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import { cloneDeep, get, groupBy, mapValues, round, sumBy, uniq, forIn, startCase } from 'lodash-es';
import { capitalize, currency, formatDate, timeAgo, phoneFormat } from '@/lib/Filters';
import { reportAccountActivity, reportAccountOverviewChart } from '@GQL';
import ConversionsBySource from '../ConversionsBySource/Page.vue';
import PendingFollowUpComponent from '@/views/FollowUps/List/PendingFollowUp.vue';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import NoteList from '@/views/Notes/List/Component.vue';
import pChart from 'primevue/chart';
import lineChart from '@/components/charts/Line.vue';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import trendClass from '@/lib/Utils/trendClass';
import UpdateAccountStatus from './UpdateAccountStatus.vue';
import DispositionNoteModal from '@/views/Notes/Record/Modal.vue';
import KPIModal from './Modals/KPIModal.vue';
import ActionModal from '@/views/AccountManagerWorkstation/ActionModal.vue';
import AttachOfferModal from '@/views/Offers/Modals/AttachOffer.vue';
import SpendByDayChart from '@/views/Reports/AccountReports/Overview/Charts/AccountSpendByDay.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import timezones from '@/lib/Data/timezones.json';
import log from '@/lib/Log';
import { resolveFocusPosition } from '@tiptap/core';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
	name: 'AdminAccountActivity',
	components: {
		ActionModal,
		AttachOfferModal,
		ConversionsBySource,
		dateRangePicker,
		DispositionNoteModal,
		lineChart,
		KPIModal,
		NoteList,
		PendingFollowUpComponent,
		pChart,
		quickFilter,
		SpendByDayChart,
		UpdateAccountStatus,
		TabView,
		TabPanel,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			query: {
				date_range: [dayjs().subtract(7, 'day').startOf('day').toDate(), dayjs().endOf('day').toDate()],
			},
			filters: {
				vertical_id: [],
				product: [],
			},
			metric_type: 'dod',
			follow_ups: [],
			account: {
				id: null,
				balance: {
					actual: 0,
				},
				contact: {
					email: '',
					phone: '',
				},
				support: {
					disposition: '',
					account_manager: {
						first_name: '',
						last_name: '',
					},
					date_of_return: new Date(),
					kpi_targets: {
						data_cpa: 0,
						call_cpa: 0,
						live_transfer_cpa: 0,
						products_sold: '',
					},
				},
				signup_data: {
					primary_vertical: '',
					phone_validation: {
						line_type: null,
						activity_score: null,
						carrier: null,
					},
				},
				settings: {
					timezone: null,
				},
				signup_source: {
					source_id: '',
				},
				primary_user: {
					first_name: '',
					last_name: '',
					phone: '',
				},
				created_at: new Date(),
				status: '',
			},
			updating_status: false,
			campaigns: [],
			campaign_activity: [],
			campaign_differences: [],
			selected_campaign_change: {},
			show_campaign_change_modal: false,
			report_data: {
				today: [],
				yesterday: [],
				this_week: [],
				last_week: [],
				this_month: [],
				last_month: [],
			},
			notifications: [],
			leads_and_spend: [],
			chart_options: {
				responsive: true,
				plugins: {
					tooltip: {
						callbacks: {
							label: function (tooltip) {
								if (tooltip.dataset.label.indexOf('Spend') > -1) {
									return `${tooltip.dataset.label}: ${currency(tooltip.raw)}`;
								}
								return `${tooltip.dataset.label}: ${tooltip.raw}`;
							},
						},
					},
				},
				scales: {
					y: {
						type: 'linear',
						display: true,
						position: 'left',
						min: 0,
						ticks: {
							callback: function (value) {
								return value;
							},
						},
					},
					y_spend: {
						type: 'linear',
						display: true,
						position: 'right',
						min: 0,
						ticks: {
							callback: function (value) {
								return currency(value);
							},
						},
					},
				},
				layout: {
					padding: 30,
				},
			},
		};
	},
	computed: {
		campaignPeriod() {
			if (this.metric_type === 'dod') {
				return 'today';
			}
			if (this.metric_type === 'wow') {
				return 'this_week';
			}
			return 'this_month';
		},
		campaignsWithPurchases() {
			return this.campaigns.filter((campaign) => {
				if (
					campaign['purchases_' + this.campaignPeriod] > 0 ||
					campaign['purchases_' + this.campaignPeriodCompare] > 0
				) {
					return true;
				}
				return false;
			});
		},
		campaignPeriodCompare() {
			if (this.metric_type === 'dod') {
				return 'yesterday';
			}
			if (this.metric_type === 'wow') {
				return 'last_week';
			}
			return 'last_month';
		},
		accountId() {
			return this.$route.params.account_id;
		},
		timezone() {
			if (this.account.settings && this.account.settings.timezone) {
				const tz = timezones.find((zone) => {
					return zone.id === this.account.settings.timezone;
				});

				let output = '';
				if (tz) {
					output += tz.label;
					const time = dayjs().tz(tz.id).format('h:mm A');

					return (output += ` (${time})`);
				}
			}
			return 'Unknown';
		},
		leadsAndSpendChartData() {
			const labels = uniq(
				this.leads_and_spend.map((row) => {
					return dayjs(row.date).format('ddd, MMM D');
				})
			);
			const datasets = [];

			const grouped_data = groupBy(this.leads_and_spend, (row) => {
				return startCase(row.vertical_id) + ' ' + startCase(row.product);
			});

			forIn(grouped_data, (values, label) => {
				datasets.push({
					label: `${label}: Leads`,
					data: values.map((row) => {
						return +row.leads;
					}),
					fill: false,
					pointStyle: 'circle',
					pointBackgroundColor: '#FFF',
					pointRadius: 6,
					pointBorderWidth: 3,
					pointHoverRadius: 15,
					yAxisID: 'y',
				});
				datasets.push({
					label: `${label}: Spend`,
					data: values.map((row) => {
						return +row.spend;
					}),
					fill: false,
					pointStyle: 'circle',
					pointBackgroundColor: '#FFF',
					pointRadius: 6,
					pointBorderWidth: 3,
					pointHoverRadius: 15,
					yAxisID: 'y_spend',
				});
			});

			return {
				labels,
				datasets,
			};
		},
		reportData() {
			const data = cloneDeep(this.report_data);
			delete data.campaigns;

			// Filter out unselected options
			const filtered_data = mapValues(data, (value, key) => {
				const filtered_values = value.filter((row) => {
					if (this.filters.vertical_id.length > 0 && !this.filters.vertical_id.includes(row.vertical_id)) {
						return false;
					}
					if (this.filters.product.length > 0 && !this.filters.product.includes(row.product)) {
						return false;
					}
					return true;
				});

				// Group by product
				let product_groups = groupBy(filtered_values, 'product');
				return mapValues(product_groups, (values, product) => {
					return values.reduce(
						(a, b) => {
							return {
								volume: a.volume + b.volume,
								spend: a.spend + b.spend,
							};
						},
						{
							volume: 0,
							spend: 0,
						}
					);
				});
			});

			// Add totals to each period
			const data_with_totals = mapValues(filtered_data, (products, period) => {
				const total = {
					volume: 0,
					spend: 0,
				};
				mapValues(products, (product_stats, product) => {
					total.volume += product_stats.volume;
					total.spend += product_stats.spend;

					product_stats.cpl = 0;
					if (product_stats.volume > 0) {
						product_stats.cpl = round(product_stats.spend / product_stats.volume, 2);
					}

					return product_stats;
				});

				total.cpl = 0;
				if (total.volume > 0) {
					total.cpl = round(total.spend / total.volume, 2);
				}

				products.total = total;
				return products;
			});

			return data_with_totals;
		},
	},
	async mounted() {
		await this.getAccountVelocity();
		await this.getOverviewData();
	},
	methods: {
		phoneFormat,
		capitalize,
		timeAgo,
		currency,
		formatDate,
		sumBy,
		trendClass,
		openCampaignChangeModal(cc) {
			if (cc.snapshot) {
				this.selected_campaign_change = cc;
				this.show_campaign_change_modal = true;
			}
		},
		campaignChangeHelper(cc) {
			// if the campaign change is from event log it will have a change_by field, and status_change
			if ('status_change' in cc) {
				return `Campaign status for '${cc.campaign_name}' set to ${capitalize(cc.status_change)}`;
			} else {
				return `Campaign data was changed for '${cc.campaign_name}'`;
				// its a generic change and we must have a modal to show the change object
			}
		},
		currentMetric(metric) {
			return get(this.reportData, `today.total.${metric}`, 0);
		},
		async handleDispositionRefresh(data) {
			if ('disposition' in data) {
				this.account.support.disposition = data.disposition;
				// also refresh the notes
			}
			await this.$refs.note_list.loadNotes(true);
		},
		updateStatus(value) {
			this.account.status = value.status;
			if ('date_of_return' in value) {
				this.account.support.date_of_return = value.date_of_return;
			}
			this.updating_status = false;
		},
		updateKPITargets(values) {
			this.account.support.kpi_targets = values;
		},
		campaignViewLink(campaign_id) {
			return `/marketplaces/manage/${this.$route.params.mpid}/accounts/manage/${this.$route.params.account_id}/campaigns/${campaign_id}`;
		},
		async getAccountVelocity() {
			this.loading = true;
			try {
				const results = await reportAccountActivity({
					account_id: this.accountId,
				});

				this.report_data = results.reportAccountVelocity;
				this.account = results.account;
				this.campaigns = results.reportAccountVelocity.campaigns;
				this.campaign_activity = results.reportCampaignActivity.rows;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get account activity data',
					life: 3000,
				});
			} finally {
				this.loading = false;
			}
		},
		async getOverviewData() {
			try {
				const result = await reportAccountOverviewChart({
					...this.query,
					account_id: this.accountId,
				});
				this.leads_and_spend = result.leads_and_spend;
			} catch (err) {
				log.error(err);
			}
		},
		clearQuery() {
			this.loading = false;
		},
	},
};
</script>

<style lang="less" scoped>
code {
	// border: 0;
	// border-radius: 0;
	display: block;
	padding: 0.25rem;
	margin: 1rem 0;
}

label {
	display: block;
	margin-bottom: 0.5rem;
}

.tabheader {
	font-size: var(--font-size-sm) !important;
	padding: 0.5rem 1rem !important;
}

#account-details,
#support-details,
#kpi-details,
#disposition-details {
	.line-item {
		line-height: 1.35em;
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}

		.label {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
		}

		.value {
			font-weight: bold;
		}
	}

	.line-heading {
		font-size: var(--font-size-base);
		font-weight: bold;
		padding-top: 0.5rem;
	}
}

.metrics-link {
	margin-top: 10px;
}

:deep(.p-tabview .p-tabview-nav li .p-tabview-nav-link) {
	color: var(--gray-35);
	font-weight: bold;
}

:deep(.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link) {
	color: var(--gray-50);
}

:deep(.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link) {
	color: var(--black);
}

:deep(.p-datatable) {
	thead {
		tr {
			border-bottom: 1px solid var(--gray-15);

			th.left-bordered {
				border-left: 1px solid var(--gray-15);
			}
		}
	}

	.left-bordered {
		border-left: 1px dashed var(--gray-15);
	}
}

.stretch > * {
	height: 100%;
}

.card {
	h2 {
		font-size: 1.1rem;
	}
}

.chart-heading {
	gap: 20px;

	h2 {
		margin: 0;
	}
}

.clickable {
	&:hover {
		cursor: pointer;
	}
}

.product-label {
	font-size: 1.125rem;
}

.velocity-charts {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
}

.activity-quick-filters,
.campaign-quick-filters {
	font-size: 0.75em;
}

#note-campaign-changes-container {
	height: 580px;
	overflow-y: scroll;

	.note-details {
		color: var(--gray-35);
		font-size: 0.75rem;
		line-height: 1.25em;
	}
	.note {
		border-bottom: 1px dashed var(--gray-20);
		font-size: 0.875rem;
		line-height: 1.5em;
		margin-bottom: 10px;
		padding: 0 7px 7px 0;
	}
}
</style>
