<template>
	<div class="view-content">
		<query-form v-model:query="query" :loading="loading" @apply="getSourceDetailBreakdownReport" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}</div>
				</div>
				<div class="display-value">
					<div class="label">Products:</div>
					<div class="value">{{ displaySelected(query.product, product_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">States:</div>
					<div class="value">{{ displaySelected(query.state, state_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">Include Call Center:</div>
					<div class="value">{{ query.include_cc ? 'Yes' : 'No' }}</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getSourceDetailBreakdownReport(true)"
				/>
			</template>
			<template #form="form">
				<row>
					<column max-width="450px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Date Range:</label>
								<div class="controls">
									<div class="field">
										<date-range-picker v-model="form.query.date_range" show-time />
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="300px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Products:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.product"
											:options="product_options"
											option-label="label"
											option-value="value"
											placeholder="Select products"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="300px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">States:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.state"
											:options="state_options"
											option-label="label"
											option-value="value"
											placeholder="Select states"
											filter
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">&nbsp;</label>
								<div class="controls">
									<div class="field fit">
										<p-checkbox input-id="include_cc" v-model="form.query.include_cc" binary />
									</div>
									<div class="field"><label for="include_cc">Include CC Purchases</label></div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div class="card padded">
			<p-data-table
				:value="keywords"
				ref="keywords_table"
				data-key="keyword"
				class="report-data-table"
				sort-field="revenue"
				:sort-order="-1"
			>
				<template #empty>
					<div class="dim">No Data</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #header>
					<strong>Keyword Breakdown</strong>
				</template>
				<p-column header="Keywords" sortable field="keywords">
					<template #body="row">
						{{ row.data.keywords }}
					</template>
				</p-column>
				<p-column header="Leads Generated">
					<template #body="row">{{ numberFormat(row.data.leads_generated) }}</template>
				</p-column>
				<p-column header="Leads Sold">
					<template #body="row">{{ numberFormat(row.data.leads_sold) }}</template>
				</p-column>
				<p-column header="Percent Sold">
					<template #body="row">{{ percentage(row.data.percent_sold, 2) }}</template>
				</p-column>
				<p-column header="Revenue" sortable field="revenue">
					<template #body="row">{{ currency(row.data.revenue) }}</template>
				</p-column>
				<p-column header="RPL" sortable field="rpl">
					<template #body="row">{{ currency(row.data.rpl) }}</template>
				</p-column>
			</p-data-table>
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table
				:value="sub_ids"
				ref="sub_id_table"
				data-key="sub_id"
				class="report-data-table"
				sort-field="revenue"
				:sort-order="-1"
			>
				<template #empty>
					<div class="dim">No Data</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #header>
					<strong>Sub ID Breakdown</strong>
				</template>
				<p-column header="Sub ID" sortable field="sub_id">
					<template #body="row">
						{{ row.data.sub_id }}
					</template>
				</p-column>
				<p-column header="Leads Generated">
					<template #body="row">{{ numberFormat(row.data.leads_generated) }}</template>
				</p-column>
				<p-column header="Leads Sold">
					<template #body="row">{{ numberFormat(row.data.leads_sold) }}</template>
				</p-column>
				<p-column header="Percent Sold">
					<template #body="row">{{ percentage(row.data.percent_sold, 2) }}</template>
				</p-column>
				<p-column header="Revenue" sortable field="revenue">
					<template #body="row">{{ currency(row.data.revenue) }}</template>
				</p-column>
				<p-column header="RPL" sortable field="rpl">
					<template #body="row">{{ currency(row.data.rpl) }}</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import { capitalize, currency, displaySelected, formatDate, numberFormat, percentage } from '@/lib/Filters';
import { reportSourceDetailBreakdown } from '@GQL';
import STATES from '@/lib/Data/states.json';

export default {
	name: 'SourceDetailBreakdownReport',
	components: {
		dateRangePicker,
		queryForm,
	},
	data() {
		return {
			loading: false,
			query: {
				date_range: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
				product: [],
				state: [],
				include_cc: true,
			},
			keywords: [],
			sub_ids: [],
			product_options: [
				{
					label: 'Exclusive Data Leads',
					value: 'exclusive_data',
				},
				{
					label: 'Shared Data Leads',
					value: 'shared_data',
				},
				{
					label: 'Calls',
					value: 'exclusive_call',
				},
				{
					label: 'Live Transfers',
					value: 'exclusive_live_transfer',
				},
				{
					label: 'Premium Listings',
					value: 'exclusive_ad',
				},
			],
			state_options: STATES,
		};
	},
	methods: {
		capitalize,
		currency,
		displaySelected,
		formatDate,
		numberFormat,
		percentage,
		clearQuery() {
			this.loading = false;
		},
		async getSourceDetailBreakdownReport(refresh) {
			this.loading = true;

			try {
				const result = await reportSourceDetailBreakdown(this.$route.params.source_id, this.query, refresh);
				this.keywords = result.keywords;
				this.sub_ids = result.sub_ids;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get breakdown data',
					detail: err.message,
					life: 5000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
	async mounted() {
		await this.getSourceDetailBreakdownReport();
	},
};
</script>

<style scoped lang="less">
.rollup-metrics {
	font-weight: bold;
	display: flex;
	gap: 20px;

	.metric {
		flex: 1 0;
	}
}

:deep(.p-datatable) {
	thead {
		tr {
			border-bottom: 1px solid var(--gray-15);

			th.left-bordered {
				border-left: 1px solid var(--gray-15);
			}
		}
	}

	.left-bordered {
		border-left: 1px dashed var(--gray-15);
	}
}

:deep(.nil) {
	color: #f88a7d;
	font-weight: bold;
}
</style>
