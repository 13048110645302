<template>
	<div id="account-performance-report" class="view-content">
		<line-loader :show="loading" />
		<p-accordion v-model:activeIndex="sections_active" multiple>
			<p-accordion-tab>
				<template #header>
					<span class="acc-header">Conversions By Source</span>
				</template>
				<ConversionsBySource />
			</p-accordion-tab>

			<p-accordion-tab>
				<template #header>
					<span class="acc-header">Velocity Performance</span>
				</template>
				<row class="metrics">
					<column :span="3">
						<div class="card padded">
							<row>
								<column>
									<div class="quick-filters">
										<strong>Compare:</strong>
										<gutter size="10px" />
										<quick-filter v-model="metric_type" value="dod">Day</quick-filter>
										<quick-filter v-model="metric_type" value="wow">Week</quick-filter>
										<quick-filter v-model="metric_type" value="mom">Month</quick-filter>
									</div>
								</column>
							</row>
							<gutter size="10px" />
							<row>
								<column>
									<metric
										label="Leads Purchased"
										:value="currentMetric('volume')"
										:previous-value="previousMetric('volume')"
									/>
								</column>
								<gutter size="20px" />
								<column>
									<metric
										label="Total Spend"
										:value="currentMetric('spend')"
										:previous-value="previousMetric('spend')"
										is-currency
									/>
								</column>
								<gutter size="20px" />
								<column>
									<metric label="CPL" :value="currentMetric('cpl')" :previous-value="previousMetric('cpl')" is-currency>
									</metric>
								</column>
							</row>
						</div>
					</column>
				</row>
				<gutter size="20px" />
				<row class="velocity-charts">
					<column>
						<div class="card padded">
							<h2>Volume</h2>
							<p-chart
								ref="volume_chart"
								type="bar"
								:data="chartData('volume')"
								:height="300"
								:options="chartOptions()"
							/>
						</div>
					</column>
					<gutter size="20px" />
					<column>
						<div class="card padded">
							<h2>Spend</h2>
							<p-chart
								ref="spend_chart"
								type="bar"
								:data="chartData('spend')"
								:height="300"
								:options="chartOptions(true)"
							/>
						</div>
					</column>
					<gutter size="20px" />
					<column>
						<div class="card padded">
							<h2>CPL</h2>
							<p-chart
								ref="cpl_chart"
								type="bar"
								:data="chartData('cpl')"
								:height="300"
								:options="chartOptions(true)"
							/>
						</div>
					</column>
				</row>
			</p-accordion-tab>
		</p-accordion>
	</div>
</template>

<script>
import { cloneDeep, get, groupBy, mapValues, merge, round, sumBy } from 'lodash-es';
import { capitalize, currency, formatDate, displayLabel, percentage } from '@/lib/Filters';
import { mapStores } from 'pinia';
import { reportAccountVelocity } from '@GQL';
import { useSessionStore } from '@/stores/session';
import dateRangePicker from '@/components/forms/DateRangePicker.vue';
import pChart from 'primevue/chart';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import trendClass from '@/lib/Utils/trendClass';
import ConversionsBySource from '../ConversionsBySource/Page.vue';
import pAccordion from 'primevue/accordion';
import pAccordionTab from 'primevue/accordiontab';

export default {
	name: 'AdminAccountPerformance',
	components: {
		dateRangePicker,
		pChart,
		quickFilter,
		verticalIcon,
		ConversionsBySource,
		pAccordion,
		pAccordionTab,
	},
	data() {
		return {
			loading: false,
			filters: {
				verticals: [],
				products: [],
			},
			metric_type: 'dod',
			report_data: {
				today: [],
				yesterday: [],
				this_week: [],
				last_week: [],
				this_month: [],
				last_month: [],
			},
			sections_active: [0, 1],
		};
	},
	computed: {
		reportData() {
			const data = cloneDeep(this.report_data);

			// Filter out unselected options
			const filtered_data = mapValues(data, (value, key) => {
				const filtered_values = value.filter((row) => {
					if (this.filters.verticals.length > 0 && !this.filters.verticals.includes(row.vertical_id)) {
						return false;
					}
					if (this.filters.products.length > 0 && !this.filters.products.includes(row.product)) {
						return false;
					}
					return true;
				});

				// Group by product
				let product_groups = groupBy(filtered_values, 'product');
				return mapValues(product_groups, (values, product) => {
					return values.reduce(
						(a, b) => {
							return {
								volume: a.volume + b.volume,
								spend: a.spend + b.spend,
							};
						},
						{
							volume: 0,
							spend: 0,
						}
					);
				});
			});

			// Add totals to each period
			const data_with_totals = mapValues(filtered_data, (products, period) => {
				const total = {
					volume: 0,
					spend: 0,
				};
				mapValues(products, (product_stats, product) => {
					total.volume += product_stats.volume;
					total.spend += product_stats.spend;

					product_stats.cpl = 0;
					if (product_stats.volume > 0) {
						product_stats.cpl = round(product_stats.spend / product_stats.volume, 2);
					}

					return product_stats;
				});

				total.cpl = 0;
				if (total.volume > 0) {
					total.cpl = round(total.spend / total.volume, 2);
				}

				products.total = total;
				return products;
			});

			if (this.$refs.volume_chart) this.$refs.volume_chart.refresh();
			if (this.$refs.spend_chart) this.$refs.spend_chart.refresh();
			if (this.$refs.cpl_chart) this.$refs.cpl_chart.refresh();
			return data_with_totals;
		},
		accountId() {
			return this.$route.params.account_id;
		},
		...mapStores(useSessionStore),
	},
	async mounted() {
		await this.getAccountVelocity();
	},
	methods: {
		capitalize,
		currency,
		formatDate,
		displayLabel,
		sumBy,
		trendClass,
		currentMetric(metric) {
			if (this.metric_type === 'mom') {
				return get(this.reportData, `this_month.total.${metric}`, 0);
			} else if (this.metric_type === 'wow') {
				return get(this.reportData, `this_week.total.${metric}`, 0);
			} else {
				return get(this.reportData, `today.total.${metric}`, 0);
			}
		},
		previousMetric(metric) {
			if (this.metric_type === 'mom') {
				return get(this.reportData, `last_month.total.${metric}`, 0);
			} else if (this.metric_type === 'wow') {
				return get(this.reportData, `last_week.total.${metric}`, 0);
			} else {
				return get(this.reportData, `yesterday.total.${metric}`, 0);
			}
		},
		chartData(metric) {
			const documentStyle = getComputedStyle(document.documentElement);
			let data = cloneDeep(this.reportData);

			const chart_data = {
				labels: ['MoM', 'WoW', 'DoD'],
				datasets: [
					{
						type: 'bar',
						label: 'Data Leads',
						backgroundColor: documentStyle.getPropertyValue('--green'),
						opacity: 0.5,
						data: [
							get(data, `last_month.data.${metric}`, 0),
							get(data, `last_week.data.${metric}`, 0),
							get(data, `yesterday.data.${metric}`, 0),
						],
						stack: 'previous',
						options: {
							plugins: {
								legend: false,
							},
						},
					},
					{
						type: 'bar',
						label: 'Calls',
						backgroundColor: documentStyle.getPropertyValue('--yellow'),
						opacity: 0.5,
						data: [
							get(data, `last_month.call.${metric}`, 0),
							get(data, `last_week.call.${metric}`, 0),
							get(data, `yesterday.call.${metric}`, 0),
						],
						stack: 'previous',
					},
					{
						type: 'bar',
						label: 'Live Transfers',
						backgroundColor: documentStyle.getPropertyValue('--purple'),
						opacity: 0.5,
						data: [
							get(data, `last_month.live_transfer.${metric}`, 0),
							get(data, `last_week.live_transfer.${metric}`, 0),
							get(data, `yesterday.live_transfer.${metric}`, 0),
						],
						stack: 'previous',
					},
					{
						type: 'bar',
						label: 'Ads',
						backgroundColor: documentStyle.getPropertyValue('--red'),
						opacity: 0.5,
						data: [
							get(data, `last_month.ad.${metric}`, 0),
							get(data, `last_week.ad.${metric}`, 0),
							get(data, `yesterday.ad.${metric}`, 0),
						],
						stack: 'previous',
					},
					{
						type: 'bar',
						label: 'Data Leads',
						backgroundColor: documentStyle.getPropertyValue('--green'),
						data: [
							get(data, `this_month.data.${metric}`, 0),
							get(data, `this_week.data.${metric}`, 0),
							get(data, `today.data.${metric}`, 0),
						],
						stack: 'current',
					},
					{
						type: 'bar',
						label: 'Calls',
						backgroundColor: documentStyle.getPropertyValue('--yellow'),
						data: [
							get(data, `this_month.call.${metric}`, 0),
							get(data, `this_week.call.${metric}`, 0),
							get(data, `today.call.${metric}`, 0),
						],
						stack: 'current',
					},
					{
						type: 'bar',
						label: 'Live Transfers',
						backgroundColor: documentStyle.getPropertyValue('--purple'),
						data: [
							get(data, `this_month.live_transfer.${metric}`, 0),
							get(data, `this_week.live_transfer.${metric}`, 0),
							get(data, `today.live_transfer.${metric}`, 0),
						],
						stack: 'current',
					},
					{
						type: 'bar',
						label: 'Ads',
						backgroundColor: documentStyle.getPropertyValue('--red'),
						data: [
							get(data, `this_month.ad.${metric}`, 0),
							get(data, `this_week.ad.${metric}`, 0),
							get(data, `today.ad.${metric}`, 0),
						],
						stack: 'current',
					},
				],
			};
			return chart_data;
		},
		chartOptions(is_currency = false) {
			const options = {
				plugins: {
					legend: {
						display: false,
					},
				},
				x: {
					stacked: true,
				},
				y: {
					stacked: true,
				},
			};

			if (is_currency) {
				merge(options, {
					plugins: {
						tooltip: {
							callbacks: {
								label(context) {
									return `${context.dataset.label}: ${currency(context.dataset.data[context.dataIndex])}`;
								},
							},
						},
					},
					scales: {
						y: {
							ticks: {
								callback(value, index) {
									return currency(value);
								},
							},
						},
					},
				});
			}

			return options;
		},
		async getAccountVelocity() {
			this.loading = true;
			try {
				const results = await reportAccountVelocity({
					account_id: this.accountId,
				});

				this.report_data = results.reportAccountVelocity;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get account velocity data',
					life: 3000,
				});
			} finally {
				this.loading = false;
			}
		},
		clearQuery() {
			this.loading = false;
		},
	},
};
</script>

<style scoped lang="less">
#account-details {
	.line-item {
		line-height: 1.35em;
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}

		.label {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
		}

		.value {
			font-weight: bold;
		}
	}
}

.metrics-link {
	margin-top: 10px;
}

:deep(.p-datatable) {
	thead {
		tr {
			border-bottom: 1px solid var(--gray-15);

			th.left-bordered {
				border-left: 1px solid var(--gray-15);
			}
		}
	}

	.left-bordered {
		border-left: 1px dashed var(--gray-15);
	}
}

.card h2 {
	font-size: 1.25rem;
}

.chart-heading {
	gap: 20px;

	h2 {
		margin: 0;
	}
}

.product-label {
	font-size: 1.125rem;
}

.velocity-charts {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
}

.campaign-quick-filters {
	font-size: 0.75em;
}
.acc-header {
	font-size: 1.125em;
}
</style>
