import { $GQL } from '@GQL/index';
import type { Campaign } from '@nextgenleads/marketplace-driver';

// TODO - figure out how filters, schedule, ping_post, cancellations, forwarding_phone will be used in this campaign....
export async function getCampaignById(id: string): Promise<Campaign> {
	const query = `
        query GetCampaignById($id: ID!) {
            campaign(id: $id) {
                id
				mode
                mpid
                name
                description
                version
                account_id
                vendor_id_targeting
                vertical_id_targeting
				custom_minimum_bid
				minimum_bid
                default_bid
				enable_maximum_bid
				maximum_bid
                product_targeting
                location_targeting_mode
                state_targeting
                zip_targeting
                channel_targeting
                bid_type
                bids {
					name
					type
					match
					method
					amount
					custom_minimum_bid
					minimum_bid
					custom_duration
					duration
					finalize
					status
					dq_message
                }
                disable_second_pricing
                budgets {
					period
					type
					limit
					enabled
				}
				schedule {
					enabled
				}
                shippers {
                	shipper_id
                	custom_settings
                }
				ping_post {
					enabled
					integration_id
				}
				delivery_emails
				forwarding_phone {
					type
					number
					confirmation_route
					script
					script_settings
					concurrent_call_limit {
						enabled
						key
						limit
					}
				}
				duration
				ad {
					enable_premium_listing
					ad_id
				}
                purchase_throttling {
					enabled
					limit
					interval
                }
				promotion_id
                flags {
					add_typ_weight { 
						enabled 
					}
					allow_duplicate_purchases { 
						enabled 
					}
					bypass_modifiers {
						enabled
						modifiers
					}
					bypass_pending_logic { 
						enabled
					}
					debug { 
						enabled
					}
					disable_duplication {
						enabled
					}
					disable_leg_buyouts {
						enabled
					}
					discount_bids {
						enabled
						amount
					}
					frozen_fields {
						enabled
						fields
					}
					lock {
						enabled
						roles
					}
					nullify_purchases { 
						enabled
					}
					campaign_template {
						is_template
						campaign_template_id
					}
					prioritize_campaign {
						enabled
						percentage
					}
					reduce_dominance {
						enabled
						percentage
					}
					smart_delay {
						enabled
					}
					sorting_bid_multiplier {
						enabled
						amount
					}
					bulk_returns {
						enabled
					}
                }
                tags
                status
                created_at
                modified_at
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.campaign;
}
