<template>
	<p-card>
		<template #title>
			<div class="flex justify-content-between gap-2 align-items-center">
				<div class="flex">
					<vertical-icon :vertical-id="lead.vertical_id" si1e="32px" class="mr-3" />
					<div class="flex flex-column gap-1">
						<div v-if="purchase && ['return_accepted', 'canceled'].includes(purchase.status)" class="name">
							UNAVAILABLE
						</div>
						<div v-else class="name">{{ lead.first_name }} {{ lead.last_name }}</div>
						<div class="id">
							{{ lead.id }}
						</div>
					</div>
				</div>
				<p-tag
					v-if="lead && lead.dnc"
					severity="danger"
					class="ml-1"
					v-tooltip.top="'Do Not Contact'"
					value="DNC"
					style="font-size: 0.5rem"
				/>
			</div>
		</template>
		<template #content>
			<ul class="table-list">
				<li>
					<div class="label">Created</div>
					<div v-if="$root.sessionStore.isAdminUser" class="value">
						{{ formatDate(lead.created_at, 'ddd, MMMM D, YYYY [at] h:mm:ssA') || '' }}<br />
						<div class="sub-value">{{ timeAgo(lead.created_at) }}</div>
					</div>
					<div v-else-if="purchase" class="value">
						{{ formatDate(purchase.created_at, 'ddd, MMMM D, YYYY [at] h:mm:ssA') || '' }}<br />
						<div class="sub-value">{{ timeAgo(purchase.created_at) }}</div>
					</div>
				</li>
				<li>
					<div class="label">Vertical</div>
					<div class="value">
						{{ verticalName(lead.vertical_id) }}
					</div>
				</li>
				<li>
					<div class="label">Phone</div>
					<div v-if="purchase && ['return_accepted', 'canceled'].includes(purchase.status)" class="value">
						{{ phoneFormat('******' + lead.phone.substring()) }}
					</div>
					<div v-else class="value">
						{{ phoneFormat(lead.phone) }}
					</div>
				</li>
				<template v-if="$root.sessionStore.isAdminUser">
					<li>
						<div class="label">Revenue</div>
						<div class="value">
							{{ currency(revenue) }}
						</div>
					</li>
					<template v-if="lead.cost">
						<li>
							<div class="label">Cost</div>
							<div class="value">
								{{ currency(lead.cost) }}
							</div>
						</li>
						<li>
							<div class="label">Profit</div>
							<div class="value">
								{{ currency(revenue - lead.cost) }}
							</div>
						</li>
					</template>
				</template>
			</ul>
		</template>
	</p-card>
</template>

<script lang="ts">
import pCard from 'primevue/card';
import pTag from 'primevue/tag';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { currency, formatDate, phoneFormat, timeAgo, verticalName } from '@/lib/Filters';

export default {
	name: 'OverviewPanel',
	components: {
		pCard,
		pTag,
		verticalIcon,
	},
	props: {
		lead: {
			type: Object,
		},
		purchase: {
			type: Object,
		},
		revenue: {
			type: Number,
			default: 0,
		},
	},
	methods: {
		formatDate,
		currency,
		phoneFormat,
		timeAgo,
		verticalName,
	},
};
</script>

<style lang="less" scoped>
.name {
	font-size: var(--font-size-h4);
}

.id {
	color: var(--gray-60);
	font-size: var(--font-size-sm);
	font-weight: normal;
}
</style>
