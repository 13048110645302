<template>
	<div class="content-campaign-flags">
		<!-- TAGS -->
		<div class="campaign-flag">
			<div class="title">Campaign Tags:</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Add tags to label this campaign with for custom functionality...</label>
					<div class="controls">
						<div class="field">
							<p-chips v-model="campaignStore.campaign.tags" add-on-blur />
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ADD TYP WEIGHT -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch input-id="add_typ_weight" v-model="campaignStore.campaign.flags.add_typ_weight.enabled" />
				</div>
				<label for="add_typ_weight">
					<div class="title">Add TYP Weight</div>
					<p>
						Add weight to the campaign's sorting bid if the account has purchased the thank you page ad associated with
						the lead.
					</p>
				</label>
			</div>
		</div>

		<!-- ALLOW DUPLICATE PURCHASES -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch
						input-id="allow_duplicate_purchases"
						v-model="campaignStore.campaign.flags.allow_duplicate_purchases.enabled"
					/>
				</div>
				<label for="allow_duplicate_purchases">
					<div class="title">Allow Duplicate Purchases</div>
					<p>Bypass the duplicate purchase check when qualifying this campaign for leads.</p>
				</label>
			</div>
		</div>

		<!-- BYPASS MODIFIERS -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch input-id="bypass_modifiers" v-model="campaignStore.campaign.flags.bypass_modifiers.enabled" />
				</div>
				<label for="bypass_modifiers">
					<div class="title">Bypass Modifiers</div>
					<p>Bypass specified sorting bid modifiers for this campaign's bid.</p>
				</label>
			</div>
			<div v-if="campaignStore.campaign.flags.bypass_modifiers.enabled" class="settings-row">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Modifers to Bypass:</label>
						<div class="controls">
							<div class="field">
								<p-chips add-on-blur v-model="campaignStore.campaign.flags.bypass_modifiers.modifiers" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- BYPASS PENDING LOGIC -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch
						input-id="bypass_pending_logic"
						v-model="campaignStore.campaign.flags.bypass_pending_logic.enabled"
					/>
				</div>
				<label for="bypass_pending_logic">
					<div class="title">Bypass Pending Status Logic</div>
					<p>Do not set the status of this campaign to "pending" if the balance is too low to purchase a lead.</p>
				</label>
			</div>
		</div>

		<!-- DEBUG -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch input-id="debug" v-model="campaignStore.campaign.flags.debug.enabled" />
				</div>
				<label for="debug">
					<div class="title">Debug Mode</div>
					<p>Check this campaign against all qualifiers in an auction even if one fails.</p>
				</label>
			</div>
		</div>

		<!-- DISABLE DUPLICATION -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch
						input-id="disable_duplication"
						v-model="campaignStore.campaign.flags.disable_duplication.enabled"
					/>
				</div>
				<label for="disable_duplication">
					<div class="title">Disable Duplication</div>
					<p>Do not allow this campaign to be duplicated.</p>
				</label>
			</div>
		</div>

		<!-- DISABLE LEG BUYOUTS (DEPRECATED) -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch
						input-id="disable_leg_buyouts"
						v-model="campaignStore.campaign.flags.disable_leg_buyouts.enabled"
					/>
				</div>
				<label for="disable_leg_buyouts">
					<div class="title">Disable Leg Buyouts</div>
					<p>Do not allow other shared campaigns to buyout the bid for this campaign.</p>
				</label>
			</div>
		</div>

		<!-- DISCOUNT BIDS -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch input-id="discount_bids" v-model="campaignStore.campaign.flags.discount_bids.enabled" />
				</div>
				<label for="discount_bids">
					<div class="title">Discount Bids</div>
					<p>Discount the price of leads purchased by this campaign relative to the bid.</p>
				</label>
			</div>
			<div v-if="campaignStore.campaign.flags.discount_bids.enabled" class="settings-row">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Multiplier:</label>
						<div class="controls">
							<div class="field xs">
								<p-input-percentage v-model="campaignStore.campaign.flags.discount_bids.multiplier" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- FREEZE FIELDS -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch input-id="lock_campaign" v-model="campaignStore.campaign.flags.frozen_fields.enabled" />
				</div>
				<label for="freeze_fields">
					<div class="title">Freeze Fields</div>
					<p>Freeze fields for this campaign so they cannot be edited. Fields available to freeze: name.</p>
				</label>
			</div>
			<div v-if="campaignStore.campaign.flags.frozen_fields.enabled" class="settings-row">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Fields:</label>
						<div class="controls">
							<div class="field">
								<p-chips v-model="campaignStore.campaign.flags.frozen_fields.fields" separator="," />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- LOCK CAMPAIGN -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch input-id="lock_campaign" v-model="campaignStore.campaign.flags.lock.enabled" />
				</div>
				<label for="lock_campaign">
					<div class="title">Lock Campaign</div>
					<p>Lock this campaign so account users cannot edit it.</p>
				</label>
			</div>
		</div>

		<!-- NULLIFY PURCHASES -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch
						input-id="nullify_purchases"
						v-model="campaignStore.campaign.flags.nullify_purchases.enabled"
					/>
				</div>
				<label for="nullify_purchases">
					<div class="title">Nullify Purchases</div>
					<p>Set the purchase price to $0 for all purchases by this campaign.</p>
				</label>
			</div>
		</div>

		<!-- PRIORITIZE CAMPAIGN -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch
						input-id="prioritize_campaign"
						v-model="campaignStore.campaign.flags.prioritize_campaign.enabled"
					/>
				</div>
				<label for="prioritize_campaign">
					<div class="title">Prioritize Campaign</div>
					<p>Prioritize this campaign in auctions by buffing the sorting bid a set percentage of the time.</p>
				</label>
			</div>
			<div v-if="campaignStore.campaign.flags.prioritize_campaign.enabled" class="settings-row">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Chance:</label>
						<div class="controls">
							<div class="field xs">
								<p-input-percentage v-model="campaignStore.campaign.flags.prioritize_campaign.percentage" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- REDUCE DOMINANCE -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch input-id="reduce_dominance" v-model="campaignStore.campaign.flags.reduce_dominance.enabled" />
				</div>
				<label for="reduce_dominance">
					<div class="title">Reduce Dominance</div>
					<p>Reduce the dominance of this campaign by nerfing the sorting bid for a percentage of auctions.</p>
				</label>
			</div>
			<div v-if="campaignStore.campaign.flags.reduce_dominance.enabled" class="settings-row">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Chance:</label>
						<div class="controls">
							<div class="field xs">
								<p-input-percentage v-model="campaignStore.campaign.flags.reduce_dominance.percentage" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- SMART DELAY -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch input-id="smart_delay" v-model="campaignStore.campaign.flags.smart_delay.enabled" />
				</div>
				<label for="smart_delay">
					<div class="title">Smart Delay</div>
					<p>Delay shipment of a data lead if a call from the thank you page is in progress.</p>
				</label>
			</div>
		</div>

		<!-- BULK RETURNS -->
		<div class="campaign-flag" :class="{ disabled: !campaignStore.allow_bulk_returns }">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch
						:disabled="!campaignStore.allow_bulk_returns"
						input-id="bulk_returns"
						v-model="campaignStore.campaign.flags.bulk_returns.enabled"
					/>
				</div>
				<label for="bulk_returns" :class="{ disabled: !campaignStore.allow_bulk_returns }">
					<div class="title">Bulk Returns</div>
					<p class="disabled" v-if="!campaignStore.allow_bulk_returns">
						<strong>Currently this is disabled, it must first be enabled at the account setting level.</strong>
					</p>
					<p>Allow for purchases by this campaign to qualify in the Bulk Return that occurs at the end of the month</p>
				</label>
			</div>
		</div>

		<!-- SECONDARY PRICING DISABLED -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch input-id="disable_second_pricing" v-model="campaignStore.campaign.disable_second_pricing" />
				</div>
				<label for="disable_second_pricing">
					<div class="title">Second Pricing Disabled</div>
					<p>With this on, the purchase price will <strong>NOT</strong> be second priced.</p>
				</label>
			</div>
		</div>

		<!-- SORTING BID MULTIPLIER -->
		<div class="campaign-flag">
			<div class="select-row">
				<div class="toggle">
					<p-input-switch
						input-id="sorting_bid_multiplier"
						v-model="campaignStore.campaign.flags.sorting_bid_multiplier.enabled"
					/>
				</div>
				<label for="sorting_bid_multiplier">
					<div class="title">Sorting Bid Multiplier</div>
					<p>Multiply the sorting bid by a specified number to either buff or nerf the bid.</p>
				</label>
			</div>
			<div v-if="campaignStore.campaign.flags.sorting_bid_multiplier.enabled" class="settings-row">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Multiplier:</label>
						<div class="controls">
							<div class="field xs">
								<div class="p-inputgroup">
									<p-input-number
										mode="decimal"
										:max-fraction-digits="3"
										:min-fraction-digits="1"
										v-model="campaignStore.campaign.flags.sorting_bid_multiplier.amount"
									/>
									<div class="p-inputgroup-addon">x</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { useCampaignStore } from '@/stores/campaign';
import { mapStores } from 'pinia';

export default {
	name: 'CampaignFlagsContent',
	computed: {
		...mapStores(useCampaignStore),
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';
.disabled {
	color: var(--gray-50) !important;
}

.campaign-flag {
	background-color: white;
	border: 1px solid var(--gray-15);
	border-radius: 3px;
	margin-bottom: 1em;
	padding: 1em;

	&:last-of-type {
		margin-bottom: 0;
	}

	.title {
		font-weight: bold;
		margin: 0.25em 0;
	}

	label {
		font-weight: normal;

		p {
			line-height: 1.35em;
			margin-bottom: 0;
		}
	}

	.select-row {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	.toggle {
		margin-right: 1em;
	}

	.settings-row {
		border-top: 1px solid var(--gray-10);
		margin-top: 1em;
		padding-top: 1em;
	}
}
</style>
