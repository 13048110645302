<template>
	<div class="select-vertical">
		<h2>Select a Vertical</h2>
		<div class="vertical-options">
			<template v-for="vertical in verticalOptions" :key="vertical.id">
				<div
					:class="[{ selected: selected_vertical === vertical.id }]"
					class="option shadow-2"
					@click="selectVertical(vertical.id)"
				>
					<div class="card-header" :style="{ 'background-image': `url(${vertical.image_url})` }"></div>
					<div class="content">
						<div class="name">{{ vertical.name }}</div>
						<div class="description">{{ vertical.description }}</div>
						<p-button v-if="selected_vertical === vertical.id" class="selected w-full" label="SELECTED ✓" />
						<p-button v-else label="SELECT" class="w-full" outlined />
					</div>
				</div>
			</template>
			<div v-if="verticalOptions.length === 0" class="option shadow-2">
				<div class="no-vertical-msg">
					There are no available verticals to target, please contact an administrator to update campaign settings.
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { title } from '@/lib/Filters';
import { mapWritableState, mapStores } from 'pinia';
import { useCampaignStore } from '@/stores/campaign';
import { useMarketplaceStore } from '@/stores/marketplace';

export default {
	name: 'SelectVertical',
	emits: ['select'],
	data() {
		return {
			selected_vertical: null,
			verticals: [
				{
					id: 'health_insurance',
					name: 'Health Insurance',
					description: 'Individuals or families up to 64 years old looking for health insurance plans.',
					image_url: '/assets/img/backgrounds/card-background-health.jpg',
				},
				{
					id: 'medicare',
					name: 'Medicare',
					description: 'Individuals 64.5 years and over that are looking for Medicare insurance plans.',
					image_url: '/assets/img/backgrounds/card-background-medicare.jpg',
				},
			],
		};
	},
	computed: {
		isTemplate() {
			return this.$route.meta.template_mode;
		},
		isNew() {
			return this.$route.meta.new;
		},
		verticalOptions() {
			// const vendor_settings = (this.$root.appStore.settings.vendors || this.marketplaceStore.settings.vendors)[
			// 	this.campaign.vendor_id_targeting
			// ];
			const vertical_settings = this.$root.appStore.settings.verticals || this.marketplaceStore.settings.verticals;

			return this.verticals.filter((vertical) => {
				return (
					// vendor_settings.verticals[vertical.id].enabled &&
					vertical_settings[vertical.id].enabled
				);
			});
		},
		...mapWritableState(useCampaignStore, ['campaign']),
		...mapStores(useMarketplaceStore),
	},
	async mounted() {
		if (this.isNew) {
			if (this.verticalOptions.length > 0) {
				this.selectVertical(this.verticalOptions[0].id);
				if (this.$route.query.duplicate) {
					this.selectVertical(this.campaign.vertical_id_targeting);
				}
			} else {
				this.selected_vertical = null;
			}
		} else {
			this.selected_vertical = this.campaign.vertical_id_targeting;
		}
	},
	methods: {
		selectVertical(value) {
			this.selected_vertical = value;
			this.$emit('select');
		},
		next() {
			if (this.selected_vertical) {
				this.campaign.vertical_id_targeting = this.selected_vertical;

				if (!this.$route.query.duplicate) {
					this.campaign.name = `${this.isTemplate ? '[TEMPLATE] ' : ''}${title(this.campaign.vertical_id_targeting)}`;
				}

				// SET TEMPLATE FLAG
				if (this.isTemplate) {
					this.campaign.flags.campaign_template = {
						is_template: true,
					};
				}
				return 'select_product';
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'No Vertical Set',
					detail: 'Please select a vertical.',
					life: 3000,
				});
				return null;
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

h2 {
	font-size: var(--font-size-h4);
}

.select-vertical {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.vertical-options {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 1rem 0;

	.mobile({
		justify-content: center;
	});

	.option {
		background: var(--white);
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 20px;
		max-width: 320px;
		min-height: 320px;
		text-align: center;
		transition: all 150ms ease-out;

		.card-header {
			background-position: 50% 50%;
			background-size: cover;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			height: 200px;
			width: 100%;
		}

		.content {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			justify-content: space-between;
			padding: 40px;
		}

		.name {
			font-size: 1.125rem;
			font-weight: bold;
			margin: 0 0 1rem;
		}

		.description {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
			line-height: 1.5;
			margin-bottom: 1rem;
		}

		&.selected {
			box-shadow: 0 0 0 3px var(--color-b) !important;
		}
	}
}

.no-vertical-msg {
	align-items: center;
	border: 3px dashed var(--gray-15);
	color: var(--gray-35);
	display: flex;
	height: 100%;
	line-height: 1.35em;
	padding: 40px;
}
</style>
