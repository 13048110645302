<template>
	<RecordNoteModal ref="note_modal" @refresh="loadNotes(true)" :account_id="this.account_id" />
	<div class="flex align-items-center justify-content-between">
		<div class="flex justify-content-between align-items-center">
			<div class="flex justify-content-start align-items-center hotkeys gap-2">
				<div class="filter-note inline-block">
					Search:
					<div class="kbd">Ctrl</div>
					+
					<div class="kbd">S</div>
				</div>
				<div class="new-note inline-block">
					New Note:
					<div class="kbd">Ctrl</div>
					+
					<div class="kbd">N</div>
				</div>
			</div>
		</div>
		<div>
			<p-button rounded text @click="toggleSearchModal" v-tooltip.top="'Search Notes'">
				<template #icon><alt-icon type="manage-search" size="24px" /></template>
			</p-button>
			<p-button rounded text @click="addNote" v-tooltip.top="'Add Note'">
				<template #icon><alt-icon type="add-notes" size="24px" /></template>
			</p-button>
		</div>
	</div>
	<div v-if="has_search" class="search-details p-3 pl-0 pt-1">
		<div class="flex justify-content-between">
			<div>
				Searched notes for: <span class="font-italic">'{{ note_search_term }}'</span>
			</div>
			<a @click.prevent="resetSearch">Clear Search</a>
		</div>
	</div>
	<div v-if="notes.length > 0" class="quick-filters mb-2">
		<span class="sub-value">Filter by:</span>
		<div class="toggles">
			<quick-filter v-model="quick_filters" value="action_taken" allow-multiple> Action Taken </quick-filter>
			<quick-filter v-model="quick_filters" value="status_change" allow-multiple> Status Change </quick-filter>
		</div>
	</div>
	<div id="note-container">
		<line-loader :show="loading" />
		<p-data-view id="notes" :value="filtered_notes" :key="componentKey">
			<template #empty>
				<p class="dim">No Notes Found</p>
			</template>
			<template #list="slotProps">
				<div v-for="note in slotProps.items" class="note flex justify-content-between">
					<template v-if="note.body && !note.is_call">
						<div>
							<label
								v-if="note.data && note.data.disposition"
								:class="{
									'text-blue-700': ['VTV', 'Voice-To-Voice'].includes(note.data.disposition),
									'text-cyan-700': ['Text', 'Texted'].includes(note.data.disposition),
									'text-orange-700': ['Email', 'Emailed'].includes(note.data.disposition),
								}"
								>Action Taken: {{ note.data.disposition }}</label
							>
							<label v-if="note.data && ['outbound_call', 'inbound_call'].includes(note.type)" class="text-cyan-700">
								{{ title(note.type) }} Notes
							</label>
							<div v-html="note.body"></div>
							<template v-if="note.data && note.data.email_template_id">
								<br />Template Used: {{ getEmailTemplateName(note.data.email_template_id) }}
							</template>
							<template v-if="note.data && note.data.offer_id">
								<br />Offer Attached: {{ getOfferName(note.data.offer_id) }}
							</template>
							<gutter size="5px" />
							<div class="note-details">
								Created By {{ note.created_by_user.first_name }} {{ note.created_by_user.last_name }}<br />
								{{ formatDate(note.created_at, 'LT ddd, MMM D, YYYY') }}
							</div>
						</div>
						<gutter size="5px" />
						<div class="tools flex justify-content-between gap-1 mr-1">
							<div v-if="$root.sessionStore.user.id === note.created_by_user.id">
								<p-button text @click="editNote(note)" severity="secondary" class="block hover:text-green-500 p-1">
									<icon class="m-0" v-tooltip.top="'Edit Note'" type="square-edit-outline" size="20px" />
								</p-button>
							</div>
							<div v-if="$root.sessionStore.isAdminUser">
								<p-button text @click="deleteNote(note.id)" severity="secondary" class="block hover:text-red-500 p-1">
									<icon class="m-0" v-tooltip.top="'Delete'" type="trash-can-outline" size="20px" />
								</p-button>
							</div>
						</div>
					</template>
					<template v-else-if="'log_type' in note">
						<div>
							<label class="text-cyan-700">{{ title(note.log_type) }}</label>
							Between <strong>{{ note.account_manager_name }}</strong> and <strong>{{ note.user_name }}</strong>
							<br />
							<p v-if="'duration' in note && note.duration !== null">
								Call lasted <strong>{{ formatCallDuration(note.duration) }} </strong>
							</p>
							<div class="note-details">
								{{ formatDate(note.created_at, 'LT ddd, MMM D, YYYY') }}
							</div>
						</div>
						<gutter size="5px" />
						<div class="tools flex justify-content-between gap-1 mr-1">
							<p-button
								text
								@click="
									addNoteFromCallLog({
										is_call: true,
										call_data: {
											type: note.log_type,
											user_name: note.user_name,
											account_manager_name: note.account_manager_name,
											date_of_call: note.created_at,
										},
									})
								"
								severity="secondary"
								class="block hover:text-green-500 p-1"
							>
								<icon class="m-0" v-tooltip.top="'Add Note to Call'" type="note-plus-outline" size="20px" />
							</p-button>
						</div>
					</template>
					<template v-else>
						<div v-if="note.changed_by">
							{{ title(note.event_type) }} to: <strong>{{ title(note.new_data) }}</strong>
							<gutter size="5px" />
							<div class="note-details">
								<span v-if="note.changed_by && note.changed_by.length === 26">
									Change By {{ note.type }}: {{ note.change_by_name }}
								</span>
								<span v-else> Change By {{ note.type }}: {{ note.changed_by }} </span>
								<br />
								{{ formatDate(note.created_at, 'LT ddd, MMM D, YYYY') }}
							</div>
						</div>
					</template>
				</div>
			</template>
		</p-data-view>
		<p-confirm-dialog />
	</div>
	<p-dialog v-model:visible="show_edit_modal" class="modal" :modal="true" :dismissable-mask="true">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<alt-icon type="sticky-note-2" size="24px" style="color: var(--color-b)" />
				<strong>Edit Note</strong>
			</div>
		</template>
		<div class="flex-flex-column">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field">
							<wysiwyg v-model="local_note.body" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Update Note" @click="updateNote" />
			</div>
		</template>
	</p-dialog>
	<p-dialog v-model:visible="show_edit_modal" class="modal" :modal="true" :dismissable-mask="true">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<alt-icon type="sticky-note-2" size="24px" style="color: var(--color-b)" />
				<strong>Edit Note</strong>
			</div>
		</template>
		<div class="flex-flex-column">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field">
							<wysiwyg v-model="local_note.body" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Update Note" @click="updateNote" />
			</div>
		</template>
	</p-dialog>
	<p-dialog v-model:visible="show_search_modal" class="modal" :modal="true" :dismissable-mask="true">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<alt-icon type="filter" size="24px" style="color: var(--color-b)" />
				<strong>Search Notes</strong>
			</div>
		</template>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Search Notes:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="note_search_term" @keyup.enter="handleSearchNotes" autofocus />
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Search Notes" @click="handleSearchNotes" />
			</div>
		</template>
	</p-dialog>
</template>

<script lang="ts">
import { getNoteList, deleteNote, updateNote, getEmailTemplatesAsOptions, getOffersAsOptions } from '@GQL';
import { title, formatDate, formatCallDuration } from '@/lib/Filters';
import RecordNoteModal from '../Record/Modal.vue';
import pConfirmDialog from 'primevue/confirmdialog';
import pDataView from 'primevue/dataview';
import { cloneDeep, orderBy, find } from 'lodash-es';
import pTextArea from 'primevue/textarea';
import { useMagicKeys } from '@vueuse/core';
import Fuse from 'fuse.js';
import quickFilter from '@/components/widgets/QuickFilter.vue';

export default {
	name: 'NoteListComponent',
	components: {
		pConfirmDialog,
		pDataView,
		pTextArea,
		RecordNoteModal,
		quickFilter,
	},
	setup() {
		const keys = useMagicKeys();
		const ctrl_n = keys['Ctrl+N'];
		const ctrl_s = keys['Ctrl+S'];
		return { ctrl_n, ctrl_s };
	},
	setup() {
		const keys = useMagicKeys();
		const ctrl_n = keys['Ctrl+N'];
		const ctrl_s = keys['Ctrl+S'];
		return { ctrl_n, ctrl_s };
	},
	props: {
		account_id: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			query: {
				filters: [],
				order: {
					field: 'created_at',
					desc: true,
				},
			},
			loading: false,
			notes: [],
			email_templates: [],
			offers: [],
			show_edit_modal: false,
			local_note: {},
			has_search: false,
			has_filtered_notes: false,
			searched_notes: [],
			filtered_notes: [],
			note_search_term: '',
			show_search_modal: false,
			componentKey: 0,
			quick_filters: [],
		};
	},
	async mounted() {
		this.email_templates = await getEmailTemplatesAsOptions();
		this.offers = await getOffersAsOptions();
		return this.loadNotes(true);
	},
	computed: {
		filtered_notes() {
			if (this.quick_filters.length > 0) {
				const filterCondition = (note) => {
					if (this.quick_filters.includes('status_change') && this.quick_filters.length === 1) {
						return ['support_status_change', 'account_manager_change'].includes(note.event_type);
					}
					if (this.quick_filters.includes('action_taken') && this.quick_filters.length === 1) {
						return note.type === 'amw_action';
					}
					return (
						['support_status_change', 'account_manager_change'].includes(note.event_type) || note.type === 'amw_action'
					);
				};
				return this.has_search === true
					? this.searched_notes.filter(filterCondition)
					: this.notes.filter(filterCondition);
			} else {
				return this.has_search ? this.searched_notes : this.notes;
			}
		},
	},
	methods: {
		formatCallDuration,
		getOfferName(offer_id) {
			const found = find(this.offers, { value: offer_id });
			if (found) {
				return found.label;
			} else {
				return 'Offer Name Not Found';
			}
		},
		getEmailTemplateName(template_id) {
			const found = find(this.email_templates, { value: template_id });
			if (found) {
				return found.label;
			} else {
				return 'Email Template Name Not Found';
			}
		},
		handlePageChange(data) {
			this.current_page = data.page;
		},
		formatDate,
		title,
		deleteNote(id) {
			this.$confirm.require({
				header: 'Delete Note',
				message: 'Are you sure you want to delete this note?',
				icon: 'pi pi-exclamation-triangle',
				acceptClass: 'p-button-danger',
				acceptLabel: 'Delete',
				rejectLabel: 'Cancel',
				accept: async () => {
					this.loading = true;
					try {
						const res = await deleteNote(id);
					} catch (err) {
					} finally {
						this.loading = false;
						await this.loadNotes(true);
					}
				},
			});
		},
		editNote(note) {
			this.show_edit_modal = true;
			this.local_note = cloneDeep(note);
		},
		toggleSearchModal() {
			this.show_search_modal = !this.show_search_modal;
		},
		async handleSearchNotes() {
			const fuseOptions = {
				keys: ['body'],
			};
			let notes = cloneDeep(this.notes);
			const fuse = new Fuse(notes, fuseOptions);
			this.searched_notes = fuse.search(this.note_search_term).map((result) => result.item);
			this.has_search = true;
			this.componentKey += 1;
			this.show_search_modal = false;
		},
		resetSearch() {
			this.note_search_term = '';
			this.has_search = false;
		},
		async updateNote() {
			try {
				const result = await updateNote(this.local_note.id, {
					body: this.local_note.body,
				});
				if (result) {
					this.$toast.add({
						severity: 'success',
						summary: 'Updated note',
						life: 3000,
					});
					await this.loadNotes(true);
				}
				this.local_note = {};
				this.show_edit_modal = false;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to update note',
					life: 6000,
				});
			}
		},
		addNote() {
			this.$refs.note_modal.openModal();
		},
		addNoteFromCallLog(data) {
			this.$refs.note_modal.openModal(data);
		},
		async loadNotes(refresh) {
			this.loading = true;
			const filters = [[`account_id = '${this.account_id}'`]];
			try {
				this.query.filters = filters;
				let results = await getNoteList(this.query, this.account_id, refresh);

				const support_changes = results.getAccountChangesForNotes.map((row) => {
					// the date must be an actual date like rows
					row.created_at = new Date(row.created_at);
					return row;
				});

				// process the call logs
				const call_logs = results.getAccountManagerPhoneLogs.map((row) => {
					row.created_at = new Date(row.created_at);
					row.account_manager_name = row.account_manager.first_name + ' ' + row.account_manager.last_name;
					return row;
				});

				const notes = results.noteList.rows.map((row) => {
					row.created_at = new Date(row.created_at);
					return row;
				});

				this.notes = orderBy([...notes, ...support_changes, ...call_logs], ['created_at'], ['desc']);
				this.total_rows = this.notes.length;
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get notes',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
	watch: {
		ctrl_s(new_val) {
			if (new_val) {
				this.toggleSearchModal();
			}
		},
		ctrl_n(new_val) {
			if (new_val) {
				this.addNote();
			}
		},
	},
};
</script>

<style scoped lang="less">
.line-loader {
	top: 68px;
}

.card {
	h2 {
		font-size: 1.1rem;
	}
}

:deep(ul) {
	list-style: disc;
	padding-left: 1rem;
}
:deep(ol) {
	list-style: decimal;
	padding-left: 1rem;
}

.note-details {
	color: var(--gray-35);
	font-size: 0.75rem;
	line-height: 1.25em;
}
.note {
	border-bottom: 1px dashed var(--gray-20);
	font-size: 0.875rem;
	line-height: 1.5em;
	margin-bottom: 10px;
	padding-bottom: 7px;
}
#note-container {
	height: 580px;
	overflow-y: scroll;
}
.kbd {
	background-color: var(--gray-10);
	border-radius: 5px;
	padding: 5px;
	display: inline-block;
	font-size: 0.75rem;
}
.search-details,
.hotkeys {
	color: var(--gray-50);
	font-size: 0.75rem;
}
</style>
