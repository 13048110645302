<template>
	<div id="settings" class="view-content">
		<line-loader :show="loading" />
		<div class="page-heading">
			<h1>Bulk Account Reassignment</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="global-settings page-content">
			<div class="card padded" style="width: 50vw">
				<h3>Instructions</h3>
				<p>
					Enter the details for the re-assignment below, then click the "Dry Run" button to get a list of companies that
					will be updated. Click the "Confirm" button to process the re-assignments
				</p>
				<p-fieldset legend="Accounts for Reassignment">
					<div class="control-group">
						<div class="inner">
							<label class="control-label req" for="name">Query Type:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										id="query_type"
										v-model="query_type"
										:options="query_type_options"
										option-label="label"
										option-value="value"
										placeholder="Select Query Type"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="control-group" v-if="query_type === 'account_manager'">
						<div class="inner">
							<label class="control-label req" for="account_manager">Account Manager:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										id="query_type"
										v-model="filter_value"
										filter
										:options="account_manager_options"
										option-group-label="label"
										option-group-children="items"
										option-label="label"
										option-value="value"
										placeholder="Select the Current Account Manager"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="control-group" v-if="query_type === 'account_ids'">
						<p>
							Paste account ids, the formatting doesn't matter, the parser will automatically extract the valid IDs,
							e.g. NGL-000-001, GRF-002-232 etc
						</p>
						<div class="inner">
							<label class="control-label req" for="account_ids">Account IDs:</label>
							<div class="controls">
								<div class="field">
									<p-text-area v-model="filter_value" />
								</div>
							</div>
						</div>
					</div>
				</p-fieldset>

				<p-fieldset legend="Reassignment Logic">
					<p>Select one or more new account managers and the accounts will be distributed to them equally</p>

					<div class="control-group">
						<div class="inner">
							<label class="control-label req" for="new_users">New Account Manager(s):</label>
							<div class="controls">
								<div class="field">
									<p-multi-select
										v-model="new_users"
										display="chip"
										filter
										:options="account_manager_options"
										option-group-label="label"
										option-group-children="items"
										option-label="label"
										option-value="value"
										placeholder=""
									/>
								</div>
							</div>
						</div>
					</div>
				</p-fieldset>

				<gutter size="20px" />
				<row class="justify-content-end">
					<p-button label="Dry Run" @click="sendRequest(true)" />
					<gutter size="10px" />
					<p-button label="Confirm" @click="sendRequest(false)" />
				</row>
			</div>
		</div>

		<p-dialog v-model:visible="show_result_modal" :modal="true" :dismissble-mask="true" style="width: 680px">
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="cash" size="24px" style="color: var(--color-b)" />
					<strong>{{ is_response_dry ? 'Dry Run' : 'Final' }} Results</strong>
				</div>
			</template>
			<div>
				<p>The accounts were distributed like so:</p>
				<ul>
					<li v-for="user_res in response_arr" :key="user_res.new_am">
						{{ user_res.new_am }} - <strong>{{ user_res.count }}</strong>
					</li>
				</ul>

				<p v-if="is_response_dry">
					The account distribution is subject to change due to the randomizing of the new users
				</p>
			</div>
		</p-dialog>
	</div>
</template>
<script>
import { bulkAccountReassignment, getInternalUsersAsOptions } from '@GQL';
import pTextArea from 'primevue/textarea';

export default {
	name: 'BulkAccountReassignmentPage',
	components: {
		pTextArea,
	},

	data() {
		return {
			loading: false,
			breadcrumb_items: [
				{
					label: 'Tools',
					route: '/tools',
				},
				{
					label: 'Bulk Account Reassignment',
				},
			],
			query_type: '',
			query_type_options: [
				{ value: 'account_ids', label: 'Account IDs' },
				{ value: 'account_manager', label: 'Account Manager' },
			],
			filter_value: null,
			account_manager_options: [],
			new_users: [],
			response_arr: [],
			is_response_dry: false,
			show_result_modal: false,
		};
	},
	computed: {
		flatAccountManagerOptions() {
			const arr = [];
			this.account_manager_options.forEach((group) => {
				group.items.forEach((user) => {
					arr.push(user);
				});
			});
			return arr;
		},
	},
	methods: {
		async sendRequest(dry_run) {
			this.loading = true;
			try {
				const filters = [];

				if (this.query_type === 'account_manager') {
					// then we just create
					filters.push([
						{
							field: `support->>'account_manager_id'`,
							op: 'eq',
							value: this.filter_value,
						},
					]);
				} else if (this.query_type === 'account_ids') {
					// we turn this in to an id in filter from the parsed text
					const matches = this.filter_value.match(/\b[A-Z]{3}-\d+-\d+\b/g);

					filters.push([
						{
							field: 'id',
							op: 'in',
							value: matches,
						},
					]);
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Query type is needed',
						life: 6000,
					});
					return;
				}

				const res = await bulkAccountReassignment({ filters, new_users: this.new_users, dry_run });
				this.response_arr = Object.keys(res.user_distribution).map((user_id) => {
					return {
						new_am: this.mapUserId(user_id),
						count: res.user_distribution[user_id],
					};
				});
				this.is_response_dry = dry_run;
				this.show_result_modal = true;
			} catch (err) {
				console.error(err, 'Error');
				this.$toast.add({
					severity: 'error',
					summary: 'Request failed, try again or contact Dev Team',
					life: 10000,
				});
			} finally {
				this.loading = false;
			}
		},
		mapUserId(user_id) {
			// return the item
			const res = this.flatAccountManagerOptions.filter((row) => {
				return row.value === user_id;
			});
			return res[0].label || 'UNKNOWN USER';
		},
	},
	async mounted() {
		this.loading = true;
		try {
			this.account_manager_options = await getInternalUsersAsOptions('sales');
		} finally {
			this.loading = false;
		}
	},
};
</script>
