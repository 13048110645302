import MetabaseReporting from '@/views/Reporting.vue';
import VelocityReports from '@/views/Reports/Velocity/Page.vue';
import DataLeadVelocityReport from '@/views/Reports/Velocity/DataLeadVelocity/Page.vue';
import CallVelocityReport from '@/views/Reports/Velocity/CallVelocity/Page.vue';
import LiveTransferVelocityReport from '@/views/Reports/Velocity/LiveTransferVelocity/Page.vue';
import CustomerVelocityReport from '@/views/Reports/Velocity/CustomerVelocity/Page.vue';
import CustomersReport from '@/views/Reports/Customers/Page.vue';
import AdminDashboard from '@/views/Reports/AdminDashboard/Page.vue';
import LeadsReport from '@/views/Reports/Leads/Page.vue';
import DataLeadsReport from '@/views/Reports/Leads/Data/Page.vue';
import CallLeadsReport from '@/views/Reports/Leads/Calls/Page.vue';
import LiveTransferLeadsReport from '@/views/Reports/Leads/LiveTransfers/Page.vue';
import SourcesReport from '@/views/Reports/Sources/Page.vue';
import SourceDetailReport from '@/views/Reports/Sources/SourceDetail/Page.vue';
import SourceDetailByHourReport from '@/views/Reports/Sources/SourceDetail/Hour.vue';
import SourceDetailByDayReport from '@/views/Reports/Sources/SourceDetail/Day.vue';
import SourceDetailBreakdownReport from '@/views/Reports/Sources/SourceDetail/Breakdown.vue';
import SourceDetailBuyersReport from '@/views/Reports/Sources/SourceDetail/Buyers.vue';
import SourceDetailLeadsReport from '@/views/Reports/Sources/SourceDetail/Leads.vue';
import amw_routes from './am-workstation';
import { authenticateReporting } from '@GQL';

export default [
	...amw_routes,
	{
		path: '',
		name: 'Home',
		// REDIRECTS SHOULD HAPPEN IN router/index.ts to use roles and store values
		// redirect: () => {
		// 	return {
		// 		path: '/reports/velocity/data',
		// 	};
		// },
	},
	{
		path: 'reports/auth',
		name: 'ReportingAuth',
		beforeEnter: (to, from) => {
			authenticateReporting().then((auth_res) => {
				window.location.href = `${auth_res.url}/auth/sso?token=${auth_res.token}&return_to=${to.query.return_to}`;
			});
		},
	},
	{
		path: 'reports/metabase',
		name: 'MetabaseReporting',
		component: MetabaseReporting,
		meta: {
			title: 'Reporting',
			permissions: 'reporting',
		},
	},
	{
		path: 'reports/admin-dashboard',
		name: 'AdminDashboard',
		component: AdminDashboard,
		meta: {
			title: 'Admin Dashboard',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/velocity',
		name: 'VelocityReports',
		component: VelocityReports,
		meta: {
			title: 'Velocity Reports',
			permissions: 'admin_reports',
		},
		children: [
			{
				path: 'data',
				name: 'DataLeadVelocityReport',
				component: DataLeadVelocityReport,
				meta: {
					title: 'Data Lead Velocity',
					permissions: 'admin_reports',
				},
			},
			{
				path: 'call',
				name: 'CallVelocityReport',
				component: CallVelocityReport,
				meta: {
					title: 'Call Velocity',
					permissions: 'admin_reports',
				},
			},
			{
				path: 'live-transfer',
				name: 'LiveTransferVelocityReport',
				component: LiveTransferVelocityReport,
				meta: {
					title: 'Live Transfer Velocity',
					permissions: 'admin_reports',
				},
			},
			{
				path: 'customer',
				name: 'CustomerVelocityReport',
				component: CustomerVelocityReport,
				meta: {
					title: 'Customer Velocity',
					permissions: 'admin_reports',
				},
			},
		],
	},
	{
		path: 'reports/customers',
		name: 'CustomersReport',
		component: CustomersReport,
		meta: {
			title: 'Customers Report',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/leads',
		name: 'LeadsReport',
		component: LeadsReport,
		meta: {
			title: 'Leads Report',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/data-leads',
		name: 'DataLeadsReport',
		component: DataLeadsReport,
		meta: {
			title: 'Data Leads Report',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/call-leads',
		name: 'CallLeadsReport',
		component: CallLeadsReport,
		meta: {
			title: 'Calls Report',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/live-transfer-leads',
		name: 'LiveTransferLeadsReport',
		component: LiveTransferLeadsReport,
		meta: {
			title: 'Live Transfers Report',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/sources',
		name: 'SourcesReport',
		component: SourcesReport,
		meta: {
			title: 'Sources Report',
			permissions: 'admin_reports',
		},
	},
	{
		path: 'reports/sources/:source_id',
		name: 'SourceDetailReport',
		component: SourceDetailReport,
		meta: {
			title: 'Sources Report',
			permissions: 'admin_reports',
		},
		children: [
			{
				path: 'hour',
				name: 'SourceDetailByHour',
				component: SourceDetailByHourReport,
				meta: {
					title: 'Source Detail by Hour',
					permissions: 'admin_reports',
				},
			},
			{
				path: 'day',
				name: 'SourceDetailByDay',
				component: SourceDetailByDayReport,
				meta: {
					title: 'Source Detail by Day',
					permissions: 'admin_reports',
				},
			},
			{
				path: 'breakdown',
				name: 'SourceDetailBreakdown',
				component: SourceDetailBreakdownReport,
				meta: {
					title: 'Source Detail Breakdown',
					permissions: 'admin_reports',
				},
			},
			{
				path: 'buyers',
				name: 'SourceDetailBuyers',
				component: SourceDetailBuyersReport,
				meta: {
					title: 'Source Detail Buyers',
					permissions: 'admin_reports',
				},
			},
			{
				path: 'leads',
				name: 'SourceDetailLeads',
				component: SourceDetailLeadsReport,
				meta: {
					title: 'Source Detail Leads',
					permissions: 'admin_reports',
				},
			},
		],
	},
];
