<template>
	<div id="toolbar" :class="{ 'menu-collapsed': menuCollapsed }">
		<div class="toolbar-inner">
			<div v-if="mq.phone" class="mobile-menu-container">
				<a class="mobile-menu-toggle" @click="$emit('mobile-menu-toggle')">
					<i class="pi pi-bars"></i>
				</a>
				<div v-if="mq.tabletMinus" class="brand-logo">
					<a href="/"><img src="/assets/img/logos/ngl-logo.svg" alt="NextGen Leads Logo" /></a>
				</div>
			</div>
			<div class="left">
				<div v-if="!mq.phone && $route.params.mpid" class="menu-container">
					<a class="menu-toggle" @click="goToMarketplaces()">
						<icon type="arrow-left" size="20px" />
					</a>
				</div>
				<div class="search">
					<UniversalSearch v-if="$root.sessionStore.hasPermission('Search')" />
				</div>
				<div class="account-name">{{ $root.sessionStore.account.name }}</div>
			</div>
			<div class="right">
				<BalanceWidget v-if="!$root.appStore.isAdminApp" />
				<Notifications ref="notificationwidget" v-if="!mq.phone" />
				<ProfileMenu />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import BalanceWidget from '@/components/widgets/Funds/BalanceWidget.vue';
import ProfileMenu from '@/components/ui/ProfileMenu.vue';
import Notifications from '@/components/widgets/Notifications/Menu.vue';
import UniversalSearch from '@/components/ui/UniversalSearch.vue';
import { useAppStore } from '@/stores/app';
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Toolbar',
	components: {
		BalanceWidget,
		Notifications,
		ProfileMenu,
		UniversalSearch,
	},
	inject: ['mq'],
	props: {
		menuCollapsed: Boolean,
	},
	emits: ['mobile-menu-toggle', 'menu-collapse-toggle'],
	data() {
		return {};
	},
	methods: {
		goToMarketplaces() {
			this.$router.push({ path: '/marketplaces' });
		},
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

#toolbar {
	background: var(--white);
	border-bottom: 1px solid var(--gray-10);
	flex: 0 0 62px;
	padding: 0 30px;
	width: 100%;
	z-index: 999;

	.mobile({
		padding-right: 0;
	});
}

.toolbar-inner {
	align-items: center;
	display: flex;
	flex: none;
	flex-direction: row;
	justify-content: space-between;
	padding: 10px;
}

.menu-container {
	justify-content: center;
	display: flex;
	height: 100%;
	margin-left: -30px;
	padding: 0 10px;
	position: relative;

	a {
		color: var(--black);
		cursor: pointer;

		&:hover {
			color: var(--green);
		}

		i {
			transition: 0.3s;

			&.menu-collapsed {
				transform: rotate(180deg);
			}
		}
	}

	.mobile ({
		display: none;
	});;
}

.mobile-menu-container {
	align-items: center;
	display: flex;
	height: 100%;
	padding: 0 0.5rem 0 0;
	margin-right: auto;

	.mobile-menu-toggle {
		align-items: center;
		color: var(--gray-65);
		height: 100%;
		margin-right: 1rem;
		text-decoration: none;

		&:hover {
			color: var(--green);
			cursor: pointer;
		}

		i {
			font-size: 18px;
		}
	}

	.brand-logo img {
		max-height: 24px;
		width: 100%;
	}
}

.search {
	display: flex;
	flex: 1;
	justify-content: flex-start;
	margin-left: auto;

	.mobile({
		justify-content: flex-end;
		margin-right: 0.5rem;
	});
}

.left,
.right {
	align-items: center;
	display: flex;
	gap: 5px;
}

.right {
	justify-content: flex-end;
}

.account-name {
	color: var(--white);
	font-size: 1.125em;
	font-weight: bold;
	margin-left: 1em;
}

.verification {
	color: var(--red-600);
	font-weight: bold;
	font-style: italic;
	font-size: 1.1em;
	&:hover {
		cursor: pointer;
	}
}

.vertical-selector {
	.tablet-down({
		.p-dropdown {
			max-width: 130px;
		}
	});
}

body.marketplace #toolbar {
	background-color: var(--color-a);

	:deep(.search-input) {
		.p-inputtext {
			background-color: rgb(255 255 255 / 25%);
			border: 0;
		}
	}
}
</style>
