import { $GQL } from '@GQL/index';

export async function amwBulkAction(
	account_ids: string[],
	email_template_id: string,
	note_body: string,
	note_type: string,
	disposition: string,
	custom_em_body: string
) {
	const mut = `
        mutation AMWBulkAction($account_ids: [String]!, $email_template_id: String, $note_body: String!, $note_type: String, $disposition: String!, $custom_em_body: String) {
            batchedAMWAction (
                account_ids: $account_ids, 
                email_template_id: $email_template_id, 
                note_body: $note_body, 
                note_type: $note_type, 
                disposition: $disposition,
                custom_em_body: $custom_em_body
            )
        }
  `;

	const result = await $GQL.request(mut, {
		account_ids,
		email_template_id,
		note_body,
		note_type,
		disposition,
		custom_em_body,
	});
	return result.batchedAMWAction;
}
