import ad_templates_routes from './ad-templates';
import auction_service_routes from './auction-service';
import auth_service_routes from './auth-service';
import call_routes from './calls';
import data_requests_routes from './data-requests';
import email_service_routes from './email-service';
import job_service_routes from './job-service';
import leadRoutes from './leads';
import lead_service_routes from './lead-service';
import lt_workstation_routes from './lt-workstation';
import marketplaces_routes from './marketplaces';
import notification_routes from './notifications';
import promotions_routes from './promotions';
import reports_routes from './admin-reports';
import settings_routes from './settings';
import shipper_service_routes from './shipper-service';
import socket_routes from './socket-service';
import source_service_routes from './source-service';
import DNCListPage from '@/views/DNCs/List/Page.vue';
import PrivacyRequestListPage from '@/views/PrivacyRequests/List/Page.vue';
import MigratePage from '@/views/Accounts/Record/MigratePage.vue';
import MassMigrateAccountsPage from '@/views/Accounts/Record/MassMigratePage.vue';
import tools_routes from './tools';

export default [
	...ad_templates_routes,
	...auction_service_routes,
	...auth_service_routes,
	...call_routes,
	...data_requests_routes,
	...email_service_routes,
	...job_service_routes,
	...leadRoutes('admin'),
	...lead_service_routes,
	...lt_workstation_routes,
	...marketplaces_routes,
	...notification_routes,
	...reports_routes,
	...settings_routes,
	...shipper_service_routes,
	...socket_routes,
	...source_service_routes,
	...promotions_routes,
	...tools_routes,
	{
		path: 'dncs',
		name: 'DNCListPage',
		component: DNCListPage,
		meta: {
			title: 'DNCs',
			permissions: 'dncs',
		},
	},
	{
		path: 'privacy-requests',
		name: 'PrivacyRequestListPage',
		component: PrivacyRequestListPage,
		meta: {
			title: 'Privacy Requests',
			permissions: 'privacy_requests',
		},
	},
	{
		path: 'migrate-account',
		name: 'MigrateAccount',
		component: MigratePage,
		meta: {
			permissions: 'accounts',
		},
	},
	{
		path: 'mass-migrate-accounts',
		name: 'MassMigrateAccount',
		component: MassMigrateAccountsPage,
		meta: {
			permissions: 'accounts',
		},
	},
];
