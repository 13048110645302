<template>
	<div id="lead-toolbar">
		<div class="tools">
			<template v-if="$root.sessionStore.isAdminUser">
				<p-button v-if="lead.dnc === false" @click="openDNCModal" label="Mark as DNC">
					<template #icon>
						<div class="icon-wrapper">
							<icon type="phone-alert" size="18px" />
						</div>
					</template>
				</p-button>
				<p-button @click="handleJSONModal" label="Raw">
					<template #icon>
						<div class="icon-wrapper">
							<icon type="code-json" size="18px" />
						</div>
					</template>
				</p-button>
			</template>
			<ReturnLead
				v-if="returnable"
				:purchase="purchase"
				:conversion="conversion"
				@updatePurchase="$emit('updatePurchase')"
			/>
		</div>
		<DNCModal ref="dnc_modal" :phone="lead.phone" @refresh="emitRefresh" />
		<p-dialog
			v-if="$root.sessionStore.isAdminUser"
			id="lead-json-modal"
			v-model:visible="raw_modal_open"
			:modal="true"
			style="min-width: 50vw"
		>
			<template #header>
				<div class="flex align-items-center w-full">
					<icon type="code-json" size="1.5rem" style="margin-right: 1rem" />
					<span class="font-bold"> Raw JSON</span>
				</div>
			</template>
			<code-editor v-model.parse="lead_data" />
		</p-dialog>
	</div>
</template>

<script lang="ts">
import ReturnLead from './panels/modals/ReturnLead.vue';
import { cloneDeep } from 'lodash-es';
import DNCModal from '@/views/DNCs/Record/RecordModal.vue';
import dayjs from 'dayjs';

export default {
	name: 'LeadToolbar',
	components: {
		ReturnLead,
		DNCModal,
	},
	props: {
		lead: {
			type: Object,
			required: true,
		},
		purchase: {
			type: Object,
		},
		conversion: {
			type: Object,
		},
	},
	emits: ['updatePurchase', 'refresh'],
	data() {
		return {
			raw_modal_open: false,
			lead_data: cloneDeep(this.lead),
		};
	},
	computed: {
		returnable() {
			return (
				this.$root.sessionStore.isAccountUser &&
				this.purchase &&
				this.purchase.status === 'complete' &&
				this.purchase.product !== 'call' &&
				this.purchase.product !== 'ad' &&
				dayjs(this.purchase.created_at).add(14, 'days').isAfter(new Date())
			);
		},
	},
	methods: {
		openDNCModal() {
			// open modal, hard dnc, soft or account only....
			const param = this.purchase && this.purchase.account_id ? this.purchase.account_id : null;
			this.$refs.dnc_modal.openModal(param);
		},
		handleJSONModal() {
			this.raw_modal_open = !this.raw_modal_open;
		},
		emitRefresh() {
			this.$emit('refresh');
		},
	},
};
</script>

<style lang="less" scoped>
.icon-wrapper {
	height: auto;
	margin-right: 8px;
	padding: 0;
	width: auto;
}

.tools {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.5rem;
}
</style>
