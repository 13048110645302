import { Logtail } from '@logtail/browser';
import * as loglevel from 'loglevel';

interface LogtailWithTrace extends Logtail {
	trace?: Logtail['debug'] | (() => void);
	debug: Logtail['debug'] | (() => void);
}

let log: LogtailWithTrace | typeof loglevel;
if (!import.meta.env.PROD) {
	loglevel.setLevel('trace');
	log = loglevel;
} else {
	const logtail: LogtailWithTrace = new Logtail('YmACB7usbiUdrQwPRq7tPWKw');
	log = logtail;
	log.debug = function () {};
	log.trace = log.debug;
}

export const Log = log;
export default log;
